import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import { Row, Col, Divider, Steps, notification, Spin } from "antd";
import { withRouter } from "react-router-dom";
import { token } from "../../Helper/Session";
const { Step } = Steps;

class Tracking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shipping_request: [],
      isLoadingRequestList: false,
    };
  }

  //   gotToAddSR = () => {
  //     this.props.history.push("/add-shipping-request");
  //   };

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const tracking_id = queryParams.get("tracking_id");

    this.setState({ isLoadingRequestList: true });
    fetch(`${BASE_URL}${API.SHIPPING_REQUEST_TRACKING_DETAIL}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        tracking_id: tracking_id ? tracking_id : "",
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isLoadingRequestList: false });
        if (json.status == true) {
          this.setState({
            shipping_request: json.data,
          });
          notification.success({
            message: json.success_msg,
          });
        } else {
          notification.error({
            message: json.error_msg,
          });
        }
      });
  }

  render() {
    const { shipping_request, isLoadingRequestList } = this.state;

    return (
      <Headers
        bodyContain={
          <>
            <Row>
              <Col md={19}>
                <h4>Shipping Request</h4>
              </Col>
            </Row>
            <div className="site-layout-background">
              <div style={{ padding: "20px" }}>
                <Spin spinning={false}>
                  <Row>
                    <Col md={24}>
                      <>
                        <Steps current={3}>
                          <Step
                            title="Placed"
                            description="This is a description."
                          />
                          <Step
                            title="Step 2"
                            description="This is a description."
                          />
                          <Step
                            title="Step 3"
                            description="This is a description."
                          />
                          <Step
                            title="Step 3"
                            description="This is a description."
                          />
                        </Steps>

                        <Divider />

                        <Steps
                          current={2}
                          // onChange={onChange}
                          direction="vertical"
                        >
                          <Step
                            title="Step 1"
                            description="This is a description."
                          />
                          <Step
                            title="Step 2"
                            description="This is a description."
                          />
                          <Step
                            title="Step 3"
                            description="This is a description."
                          />
                        </Steps>
                      </>
                    </Col>
                  </Row>
                </Spin>
              </div>
            </div>
          </>
        }
      />
    );
  }
}
export default withRouter(Tracking);
