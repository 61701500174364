import React, { useEffect } from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import {
    Row,
    Col,
    Table,
    Button,
    notification,
    Spin,
    Card,
    Input,
    message,
    DatePicker,
    Select,
    Tag,
    Breadcrumb,
    Drawer,
    Space,
    Divider,
    Modal,
    Upload
} from "antd";
import { withRouter } from "react-router-dom";
import { token } from "../../Helper/Session";
import Form from "../../Helper/Form";
import { ExportOutlined, AppstoreAddOutlined, UploadOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { sessionData } from "../../Helper/Session";
const { Search } = Input;

class EditProcessShippingInstruction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingRequestList: true,
            isSearching: false,
            isConfirmRecProductsLoading: false,
            process_shipping_id: atob(props.id),
            shipmentProcessing_data: [],
            shipment_id: 0,
            instruction_id: 0,
            currency: [],
            pod_uploaded_id: 0, /*last uploaded pod id*/
            isShippingSaving: false
        };
    }
    formRef = React.createRef();
    componentDidMount() {
        this.fetchCurrency();
        // this.formRef.current.setFieldsValue({
        //     'processing_type' : 'Packaging'
        // })
        // console.log(this.formRef.current);
        fetch(`${BASE_URL}${API.SHIPMENT_PROCESSING_DETAIL}`, {
            method: "POST",
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({ process_shipping_id: this.state.process_shipping_id }),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        shipmentProcessing_data: json.data,
                        shipment_id: json.data.shipment_id,
                        instruction_id: json.data.instruction_id
                    });
                    this.formRef.current.setFieldsValue({
                        processing_type: json.data.processing_type,
                        item_description: json.data.item_description,
                        service_provider: json.data.service_provider,
                        provider_order_reference: json.data.provider_order_reference,
                        tracking_no: json.data.tracking_no,
                        tracking_url: json.data.tracking_url,
                        status: json.data.status,
                        billing_information: json.data.billing_information,
                        supplier_cost: json.data.supplier_cost,
                        supplier_currency: json.data.supplier_currency,
                        supplier_invoice_reference: json.data.supplier_invoice_reference,
                        sisl_invoice_amount: json.data.sisl_invoice_amount,
                        processing_date: json.data.processing_date ? moment(json.data.processing_date) : '',
                        sisl_invoice_currency: json.data.sisl_invoice_currency,
                        sisl_invoice_reference: json.data.sisl_invoice_reference,
                        sisl_so_number: json.data.sisl_so_number,
                        sisl_invoice_ref: json.data.sisl_invoice_ref,
                    });
                }
                this.setState({
                    isLoading: false,
                });
            });
    }
    fetchCurrency = () => {
        fetch(`${BASE_URL}${API.CURRENCY}`, {
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    currency: json.data,
                });
            });
    };
    saveShipmentProcessing = (value) => {
        this.setState({ isShippingSaving: true });
        value.shipment_id = this.state.shipment_id;
        value.id = this.state.shipmentProcessing_data.id;
        value.processing_date = moment(value.processing_date).format("YYYY-MM-DD");
        fetch(`${BASE_URL}${API.SAVE_SHIPMENT_PROCESSING}`, {
            method: "POST",
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(value),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    notification.success({ message: json.success_msg, placement: "top" });
                    this.props.history.push('/shipping-request-list/?shipping_id=' + this.state.shipment_id + "&instruction_id=" + this.state.instruction_id);
                } else {
                    notification.error({ message: json.error_msg, placement: "top" });
                }
                this.setState({ isShippingSaving: false });
            });
    }
    render() {
        const {
            currency,
            instruction_id,
            shipmentProcessing_data,
            process_shipping_id,
            shipment_id,
            isShippingSaving
        } = this.state;
        const { Option } = Select;
        // const defaultFileList = [
        //     {
        //         uid: shipmentProcessing_data.id,
        //         name: shipmentProcessing_data.pod,
        //         url: shipmentProcessing_data.file_url,
        //     },
        // ];
        const props = {
            action: `${BASE_URL}${API.UPLOAD_DOCS}`,
            multiple: false,
            maxCount: 1,
            headers: {
                Accept: "application/form-data",
                Authorization: "Bearer " + token,
            },
            // fileList: defaultFileList,
            onChange(info) {
                // console.log(info);
                if (info.file.status !== "uploading") {
                    // console.log(info.file, info.fileList);
                    if (info.file.response.status == true && info.file.status == "done") {
                        message.success(`${info.file.name} file uploaded successfully`);
                    } else if (info.file.response.status == false || info.file.status == "error") {
                        message.error(info.file.response.error_msg + " or " + info.file.name + "file upload failed.");
                    }
                }
            },
        };
        return (
            <Headers
                bodyContain={
                    <>
                        <div className="site-layout-background form-design">
                            <Row>
                                <Col md={12}><h4>Shipment Processing Id - {instruction_id + "-" + process_shipping_id}</h4></Col>
                            </Row>
                            <div style={{ marginTop: "10px" }}>
                                <Form
                                    name="formShip"
                                    layout="vertical"
                                    onFinish={this.saveShipmentProcessing}
                                    scrollToFirstError
                                    ref={this.formRef}
                                >
                                    <Row>

                                        <Col md={6}>
                                            <Form.Item
                                                name="processing_type"
                                                label="Processing Type"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter Processing Type!",
                                                    },
                                                ]}
                                            >
                                                <Select size="small">
                                                    <Option value="Packaging">Packaging</Option>
                                                    <Option value="Freight">Freight</Option>
                                                    <Option value="Duties / Import Taxes">Duties / Import Taxes</Option>
                                                    <Option value="Other charges">Other charges</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Item
                                                name="item_description"
                                                label="Item description"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter Item description!",
                                                    },
                                                ]}
                                            >
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Item
                                                name="service_provider"
                                                label="Service Provider"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter Service Provider!",
                                                    },
                                                ]}
                                            >
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Item
                                                name="provider_order_reference"
                                                label="Provider order reference"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter Provider order reference!",
                                                    },
                                                ]}
                                            >
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Item
                                                name="tracking_no"
                                                label="Tracking"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter tracking!",
                                                    },
                                                ]}
                                            >
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Item
                                                name="tracking_url"
                                                label="Tracking URL"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter tracking url!",
                                                    },
                                                ]}
                                            >
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Item
                                                name="status"
                                                label="Status"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please select status!",
                                                    },
                                                ]}
                                            >
                                                <Select size="small">
                                                    <Option value="InProgress">Processing in Progress</Option>
                                                    <Option value="Completed">Processing Completed</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col md={6} style={{ marginBottom: "10px" }}>
                                            <Form.Item
                                                name="pod"
                                                label="POD"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please select POD!",
                                                    },
                                                ]}
                                            >
                                                <Upload
                                                    showUploadList={{ showRemoveIcon: false }}
                                                    size="small"
                                                    {...props}
                                                    data={{
                                                        instruction_id: instruction_id,
                                                        shipment_id: shipment_id,
                                                        processing_id: process_shipping_id,
                                                        doc_type: "pod",
                                                    }}

                                                >
                                                    <Button icon={<UploadOutlined />}>
                                                        Click to Upload
                                                    </Button>
                                                </Upload>
                                            </Form.Item>
                                            <a href={shipmentProcessing_data.file_url}>{shipmentProcessing_data.pod}</a>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Item
                                                name="processing_date"
                                                label="Processing Date"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter processing date!",
                                                    },
                                                ]}
                                            >
                                                <DatePicker style={{ width: "100%" }}
                                                    // onChange={this.onChange}
                                                    format="DD-MM-YYYY" />
                                            </Form.Item>
                                        </Col>

                                        <Divider orientation="left">SISL Information</Divider>
                                        <Col md={6}>
                                            <Form.Item
                                                name="billing_information"
                                                label="SISL Billing Information"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter SISL billing information!",
                                                    },
                                                ]}
                                            >
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Item
                                                name="sisl_so_number"
                                                label="SISL SO number"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter SISL SO number!",
                                                    },
                                                ]}
                                            >
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Item
                                                name="sisl_invoice_reference"
                                                label="SISL Invoice Reference"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter SISL Invoice Reference!",
                                                    },
                                                ]}
                                            >
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Item
                                                name="sisl_invoice_currency"
                                                label="SISL Invoice currency"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter SISL Invoice currency!",
                                                    },
                                                ]}
                                            >
                                                <Select size="small" showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                    {currency.map((opt) => {
                                                        return <Option value={opt.id}>{opt.currency_name}</Option>;
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Item
                                                name="sisl_invoice_amount"
                                                label="SISL Invoice amount"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter SISL Invoice amount!",
                                                    },
                                                ]}
                                            >
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Divider orientation="left">Supplier Information</Divider>
                                        <Col md={6}>
                                            <Form.Item
                                                name="supplier_currency"
                                                label="Supplier Currency"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter Supplier Currency!",
                                                    },
                                                ]}
                                            >
                                                <Select size="small" showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                    {currency.map((opt) => {
                                                        return <Option value={opt.id}>{opt.currency_name}</Option>;
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Item
                                                name="supplier_cost"
                                                label="Supplier cost"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter Supplier cost!",
                                                    },
                                                ]}
                                            >
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Item
                                                name="supplier_invoice_reference"
                                                label="Supplier invoice reference"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please select status!",
                                                    },
                                                ]}
                                            >
                                                <Input size="small" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit" size="medium" loading={isShippingSaving}>
                                                    Save Shipment Processing ID
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </>
                }
            />
        );
    }
}
export default withRouter(EditProcessShippingInstruction);
