import React, { PureComponent } from "react";
import { Form as Component } from "antd";

class Form extends PureComponent {
  formRef = React.createRef();

  render() {
    return <Component {...this.props} ref={this.formRef} />;
  }
  setFieldsValue(v) {
    this.formRef.current.setFieldsValue(v);
  }
  getForm() {
    return this.formRef.current;
  }
}

Form = Component;
export default Form;