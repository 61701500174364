import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
  Divider,
  notification,
} from "antd";
import { token } from "../../Helper/Session";
import { withRouter } from "react-router-dom";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      role_opt: [],
    };
  }
  componentDidMount() {
    fetch(`${BASE_URL}${API.ROLE_LIST}`, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            role_opt: json.data,
          });
        }
      });
  }

  render() {
    const { Option } = Select;
    const { role_opt } = this.state;
    const onFinish = (values) => {
      // console.log(values);
      const sub_data = {
        first_name: values.first_name,
        last_name: values.last_name,
        mobile: values.mobile,
        password: values.password,
        user_role: values.user_role,
        email: values.email,
      };
      fetch(`${BASE_URL}${API.ADDUSER}`, {
        method: "POST",
        headers: {
          Accept: "application/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        },
        body: JSON.stringify(sub_data),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.status === true) {
            notification.success({ message: json.success_msg });
            this.props.history.push('/all-users');
          } else {
            notification.error({ message: json.error_msg });
          }
        });
    };
    return (
      <Headers
        bodyContain={
          <>
            <div className="site-layout-background form-design">
              <h4>Add User</h4>
              <Divider />
              <Form
                name="register"
                layout="vertical"
                onFinish={onFinish}
                scrollToFirstError
              >
                <Row>
                  <Col md={12}>
                    <Form.Item
                      name="first_name"
                      label="First Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input user first name!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item
                      name="last_name"
                      label="Last Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input user last name!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item
                      name="email"
                      label="E-mail"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please input your E-mail!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item
                      name="mobile"
                      label="Contact Number"
                      rules={[
                        {
                          required: true,
                          message: "Please input your contact number!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password />
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item
                      name="confirm"
                      label="Confirm Password"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The two passwords that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Form.Item
                      name="user_role"
                      label="User Role"
                      rules={[
                        {
                          required: true,
                          message: "Please select user role!",
                        },
                      ]}
                    >
                      <Select placeholder="Select user role">
                        {role_opt.map((opt, i) => {
                          return <Option value={opt.id}>{opt.name}</Option>;
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" size="large">
                        Register
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </>
        }
      />
    );
  }
}
export default withRouter(Users);
