import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import {
  Row,
  Col,
  Button,
  Input,
  Select,
  Divider,
  Spin,
  Space,
  notification,
  Table,
  DatePicker
} from "antd";
import {
  MinusCircleTwoTone,
  PlusCircleTwoTone,
  EditOutlined,
} from "@ant-design/icons";
import Form from "../../Helper/Form";
import { token, sessionData } from "../../Helper/Session";
import { withRouter } from "react-router-dom";
import moment from "moment";

class EditShippingRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingShippingRequestDetail: true,
      slected_address_user_id: "",
      customer_user: [],
      address: [],
      isToAddrLoad: false,
      isFromAddrLoad: false,
      isBtnLoad: false,
      items: [],
      shipping_request_id: props.id,
      customer_entity: []
    };
  }
  formRef = React.createRef();
  form = React.createRef();
  itemForm = React.createRef();
  componentDidMount() {
    fetch(`${BASE_URL}${API.USERLISTING}?user_role=3`, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            customer_user: json.data,
          });
        }
      });

    var selectedCAShipPro = localStorage.getItem('selectedCAShipPro');
    selectedCAShipPro = JSON.parse(selectedCAShipPro);
    if (selectedCAShipPro != null) {
      if (!selectedCAShipPro.selectedShipProCustomerAccount && !selectedCAShipPro.selectedShipProCustomerAccount) {
        notification.error({ message: 'Error', description: 'Customer account is not selected! Please select to proceed.', placement: "top" });
      } else {
        this.setState({
          selectedShipProCustomerAccount: selectedCAShipPro.selectedShipProCustomerAccount,
        }, () => {
          this.getMajorAddress(this.state.selectedShipProCustomerAccount.id);
          this.fetchCustomerEntity();
        });
      }
    } else {
      notification.error({ message: 'Error', description: 'Customer account is not selected! Please select to proceed.', placement: "top" });
    }

    this.setState({ isLoadingShippingRequest: true });
    fetch(`${BASE_URL}${API.SHIPPING_REQUEST_DETAIL}`, {
      method: "post",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        shipping_request_id: this.state.shipping_request_id,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isLoadingShippingRequestDetail: false });
        if (json.status === true) {
          this.setState({
            request: json.data,
            shipping_id: json.data.id,
            items: json.data.items,
          });
          this.formRef.current.setFieldsValue({
            client_reference_id: this.state.request.client_reference_id,
            customer_entity: this.state.request.customer_entity,
            to_company_name: this.state.request.to_company_name,
            to_contact_name: this.state.request.to_contact_name,
            to_contact_number: this.state.request.to_contact_number,
            to_email: this.state.request.to_email,
            to_street: this.state.request.to_street,
            to_city: this.state.request.to_city,
            to_postal: this.state.request.to_postal,
            to_state: this.state.request.to_state,
            to_country: this.state.request.to_country,
            from_company_name: this.state.request.from_company_name,
            from_contact_name: this.state.request.from_contact_name,
            from_contact_number: this.state.request.from_contact_number,
            from_email: this.state.request.from_email,
            from_street: this.state.request.from_street,
            from_city: this.state.request.from_city,
            from_postal: this.state.request.from_postal,
            from_state: this.state.request.from_state,
            from_country: this.state.request.from_country,
            ship_to: this.state.request.to_company_name,
            ship_from: this.state.request.from_company_name,
            is_shipment_tobe_added_sisl: this.state.request.is_shipment_tobe_added_sisl,
            is_shipment_already_held_sisl: this.state.request.is_shipment_already_held_sisl,
            collection_address: this.state.request.collection_address,
            awb_collection_date: this.state.request.awb_collection_date ? moment(this.state.request.awb_collection_date) : '',
          });
        } else {
          notification.error({ message: json.error_msg, placement: "top" });
        }
      });
  }

  fetchCustomerEntity = () => {
    fetch(`${BASE_URL}${API.CUSTOMER_ENTIIY_FOR_CUSTOMER_ACCOUNT}?customer_account=` + this.state.selectedShipProCustomerAccount.id, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            customer_entity: json.data,
          });
        } else {
          this.setState({
            customer_entity: [],
          });
        }
      });
  }

  onFinish = (values) => {
    this.setState({ isBtnLoad: true });
    values.user_id = sessionData.user_id;
    values.request_id = this.state.shipping_request_id;
    values.awb_collection_date = moment(values.awb_collection_date).format("YYYY-MM-DD");
    fetch(`${BASE_URL}${API.UPDATE_SHIPPING_REQUEST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "applicationm/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isBtnLoad: false });
        if (json.status === true) {
          notification.success({ message: json.success_msg });
          // this.props.history.push("/shipping-request-list");
        } else {
          notification.error({ message: json.error_msg });
        }
      });
  };

  getMajorAddress = (value) => {
    fetch(`${BASE_URL}${API.MAJOR_ADDRESS_LIST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ customer_account_id: value }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            slected_address_user_id: value,
            address: json.data,
          });
        } else {
          this.setState({
            address: [],
          });
        }
      });

    fetch(`${BASE_URL}${API.ITEM_LIST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ user_id: value }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            items: json.data,
          });
        } else {
          this.setState({
            items: [],
          });
        }
      });
  };

  handleShipToAddress = (value) => {
    this.setState({ isToAddrLoad: true });
    fetch(`${BASE_URL}${API.MAJOR_ADDRESS_LIST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        user_id: this.state.slected_address_user_id,
        id: value,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isToAddrLoad: false });
        this.formRef.current.setFieldsValue({
          to_company_name: json.data.company_name,
          to_contact_name: json.data.contact_name,
          to_contact_number: json.data.mobile,
          to_email: json.data.email,
          to_mobile: json.data.mobile,
          to_street: json.data.street,
          to_city: json.data.city,
          to_state: json.data.state,
          to_country: json.data.country,
          to_postal: json.data.postal,
        });
      });
  };

  handleShipFromAddress = (value) => {
    this.setState({ isFromAddrLoad: true });
    fetch(`${BASE_URL}${API.MAJOR_ADDRESS_LIST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        user_id: this.state.slected_address_user_id,
        id: value,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isFromAddrLoad: false });
        this.formRef.current.setFieldsValue({
          from_company_name: json.data.company_name,
          from_contact_name: json.data.contact_name,
          from_contact_number: json.data.mobile,
          from_email: json.data.email,
          from_mobile: json.data.mobile,
          from_street: json.data.street,
          from_city: json.data.city,
          from_state: json.data.state,
          from_country: json.data.country,
          from_postal: json.data.postal,
        });
      });
  };
  render() {
    const {
      customer_entity,
      address,
      isToAddrLoad,
      isFromAddrLoad,
      items,
      isBtnLoad,
      isLoadingShippingRequestDetail,
    } = this.state;
    const { Option } = Select;
    var data = [];
    items.map((itm, i) => {
      data.push({
        key: i + 1,
        name: itm.name,
        qty: itm.qty,
        weight: itm.weight,
        length: itm.length,
        width: itm.width,
        height: itm.height,
        value: itm.value,
        content: itm.content,
        description: itm.description,
        action: (
          <Button
            type="danger"
            style={{ width: "50px" }}
            icon={<EditOutlined />}
          />
        ),
      });
    });
    const columns = [
      {
        title: "SNo",
        dataIndex: "key",
      },
      {
        title: "Item Name",
        dataIndex: "name",
      },
      {
        title: "Quantity",
        dataIndex: "qty",
      },
      {
        title: "Weight(kg)",
        dataIndex: "weight",
      },
      {
        title: "Length(mm)",
        dataIndex: "length",
      },
      {
        title: "Width(mm)",
        dataIndex: "width",
      },
      {
        title: "Height(mm)",
        dataIndex: "height",
      },
      {
        title: "Action",
        dataIndex: "action",
      },
    ];
    return (
      <Headers
        bodyContain={
          <>
            <div className="site-layout-background form-design">
              <h4>Edit "Shipment Instruction"</h4>
              <Divider />
              <Form
                ref={this.formRef}
                name="register"
                layout="vertical"
                onFinish={this.onFinish}
                scrollToFirstError
              >
                <Spin spinning={isLoadingShippingRequestDetail}>
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        name="client_reference_id"
                        label="Client Ref ID/ Client AWB No"
                        tooltip="Enter any specific ID required for internal reference OR leave blank"
                        rules={[
                          {
                            message: "Please enter Client Ref ID/ Client AWB No!",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <div className="help">Enter any specific ID required for internal reference OR leave blank</div>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="customer_entity"
                        label="Client billing entity"
                        rules={[
                          {
                            required: true,
                            message: "Please select client billing entity!",
                          },
                        ]}
                      >
                        <Select placeholder="Select Client Billing Entity required for billing">
                          {customer_entity.map((opt) => {
                            return <Option value={opt.id}>{opt.company_name}</Option>;
                          })}
                        </Select>
                        {/* <span className="help">Select Client Billing Entity required for billing</span> */}
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="awb_collection_date"
                        label="Instruction Date"
                        rules={[
                          {
                            required: false,
                            message: "Please enter Instruction Date!",
                          },
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }}
                          // onChange={this.onChange}
                          format="DD-MM-YYYY" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        name="collection_address"
                        label="Ship From' Location"
                        rules={[
                          {
                            required: true,
                            message: "Please select Ship From' Location",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Ship From' Location"
                          onChange={this.handleShipFromAddress}
                        >
                          {address.map((opt) => {
                            return (
                              <Option value={opt.id}>
                                {opt.company_name + (opt.contact_name ? " - " + opt.contact_name : '')}
                              </Option>
                            );
                          })}
                          <Option value="0">New Address</Option>
                        </Select>

                      </Form.Item>
                      <div className="help">Enter the collection address details or select from the drop down options</div>
                    </Col>

                    <Col md={12}>
                      <Form.Item
                        name="from_company_name"
                        label="Company Name"
                        rules={[
                          {
                            required: false,
                            message: "Please enter collection company name!",
                          },
                        ]}
                      >
                        <Input size="" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Spin spinning={isFromAddrLoad}>
                    <Row className="ship_address">

                      <Col md={6}>
                        <Form.Item
                          name="from_contact_name"
                          label="Contact Name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter collection contact name!",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="from_contact_number"
                          label="Contact Number"
                          rules={[
                            {
                              required: true,
                              message: "Please enter collection contact number!",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="from_email"
                          label="Email"
                          rules={[
                            {
                              required: false,
                              message: "Please enter collection email!",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="from_street"
                          label="Street"
                          rules={[
                            {
                              required: true,
                              message: "Please enter collection street address!",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="from_city"
                          label="City"
                          rules={[
                            {
                              required: true,
                              message: "Please enter collection city",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="from_postal"
                          label="Post code"
                          rules={[
                            {
                              required: false,
                              message: "Please enter collection postal address!",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="from_state"
                          label="State"
                          rules={[
                            {
                              required: true,
                              message: "Please enter collection state",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>

                      <Col md={6}>
                        <Form.Item
                          name="from_country"
                          label="Country"
                          rules={[
                            {
                              required: true,
                              message: "Please enter collection Country",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Spin>
                  <Divider />
                  <Row>

                    <Col md={12}>
                      <Form.Item
                        name="to_company_name"
                        label="Ship To' Location"
                        rules={[
                          {
                            required: false,
                            message: "Please enter delivery company name!",
                          },
                        ]}
                      >
                        <Input size="" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Spin spinning={isToAddrLoad}>
                    <Row className="ship_address">

                      <Col md={6}>
                        <Form.Item
                          name="to_contact_name"
                          label="Delivery: Contact Name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter delivery contact name!",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="to_contact_number"
                          label="Contact number"
                          rules={[
                            {
                              required: true,
                              message: "Please enter delivery contact number!",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="to_email"
                          label="Email"
                          rules={[
                            {
                              required: false,
                              message: "Please enter delivery email!",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="to_street"
                          label="Street"
                          rules={[
                            {
                              required: true,
                              message: "Please enter delivery street address!",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>

                      <Col md={6}>
                        <Form.Item
                          name="to_city"
                          label="City"
                          rules={[
                            {
                              required: true,
                              message: "Please enter delivery city",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="to_postal"
                          label="Post code"
                          rules={[
                            {
                              required: true,
                              message: "Please enter delivery postal",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>

                      <Col md={6}>
                        <Form.Item
                          name="to_state"
                          label="State"
                          rules={[
                            {
                              required: true,
                              message: "Please enter delivery state",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>

                      <Col md={6}>
                        <Form.Item
                          name="to_country"
                          label="Country"
                          rules={[
                            {
                              required: true,
                              message: "Please enter delivery Country",
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Spin>
                  <Divider />
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        name="is_shipment_already_held_sisl"
                        label="Shipping items from stock already held by SISL at warehouse"
                        rules={[
                          {
                            required: true,
                            message: "Please select option!",
                          },
                        ]}
                      >
                        <Select onChange={this.fetchProducts}>
                          <Option value="NO">No</Option>
                          <Option value="YES">Yes</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="is_shipment_tobe_added_sisl"
                        label="Shipped item to be added to stock held at SISL Warehouse"
                        rules={[
                          {
                            required: true,
                            message: "Please select option!",
                          },
                        ]}
                      >
                        <Select>
                          <Option value="NO">No</Option>
                          <Option value="YES">Yes</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Divider /> */}
                  <Row>
                    <Col md={6}>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="medium"
                          loading={isBtnLoad}
                          style={{ width: "100%" }}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item>
                        <Button
                          type="default"
                          size="medium"
                          style={{ width: "100%" }}
                          onClick={() => this.props.history.push("/shipping-request/view/" + this.state.shipping_request_id)}
                        >
                          Cancel
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Divider />
                  <Divider orientation="left" plain>
                    Line Items
                  </Divider>
                  <Table
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                  /> */}
                </Spin>
              </Form>
            </div>
          </>
        }
      />
    );
  }
}
export default withRouter(EditShippingRequest);
