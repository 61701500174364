import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import { Row, Col, Divider, Card, notification, Spin } from "antd";
import { withRouter } from "react-router-dom";
import { token, sessionData } from "../../Helper/Session";

class MyAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      my_address: [],
      isLoadingRequestList: false,
    };
  }

  //   gotToAddSR = () => {
  //     this.props.history.push("/add-shipping-request");
  //   };

  componentDidMount() {
    fetch(`${BASE_URL}${API.MAJOR_ADDRESS_LIST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        user_id: sessionData.user_id,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isLoadingRequestList: false });
        if (json.status == true) {
          this.setState({
            my_address: json.data,
          });
        } else {
          notification.error({
            message: json.error_msg,
          });
        }
      });
  }

  render() {
    const { my_address } = this.state;
    return (
      <Headers
        bodyContain={
          <>
            <Row>
              <Col md={19}>
                <h3 className="title">My Addresses</h3>
              </Col>
            </Row>
            <div className="site-layout-background">
              <div style={{ padding: "20px" }}>
                <Row>
                  {my_address.map((request, i) => {
                    return (
                      <Col offset={6} md={12}>
                        <Card className="address">
                          <p className="text-center"></p>
                          <Row>
                            <Col sm={24}>
                              <p>
                                {request.contact_name +
                                  ", " +
                                  request.company_name}
                              </p>
                            </Col>

                            <Col sm={24}>
                              <p>
                                {request.street +
                                  ", " +
                                  request.city +
                                  ", " +
                                  request.state +
                                  ", " +
                                  request.country +
                                  " " +
                                  (request.postal
                                    ? " - " + request.postal
                                    : "")}
                              </p>
                            </Col>
                            <Col sm={24}>
                              <p>
                                {(request.contact_number
                                  ? request.contact_number + "/ "
                                  : "") +
                                  "" +
                                  request.email}
                              </p>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
          </>
        }
      />
    );
  }
}
export default withRouter(MyAddress);
