import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Divider,
  notification,
  Select,
  Spin,
} from "antd";
import { token } from "../../Helper/Session";
import { withRouter } from "react-router-dom";

class OmsSoDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      so_id: props.id,
      so_detail: [],
      customer_account: [],
      shipment: [],
      isLoading: true,
    };
    // if (!props.so_id) {
    //   this.props.history.push("/shipping-so");
    // }
  }

  componentDidMount() {
    this.fetch_so_detail();

    fetch(`${BASE_URL}${API.CUSTOMER_ACCOUNT_LIST}`, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            customer_account: json.data,
          });
        } else {
          notification.error({ message: json.error_msg });
        }
      });
  }

  fetch_so_detail = () => {
    const dta = {
      so_id: this.state.so_id,
    };
    fetch(`${BASE_URL}${API.OMS_SO_DETAIL}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(dta),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          isLoading: false,
        });
        if (json.status === true) {
          this.setState({
            so_detail: json.data,
          });
        } else {
          notification.error({ message: json.error_msg });
        }
      });
  };

  handleCustomerAccount = (value) => {
    const dta = {
      customer_account: value,
    };
    fetch(`${BASE_URL}${API.SHIPMENT_BY_CUSTOMER_ACCOUNT}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(dta),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            shipment: json.data,
          });
        } else {
          notification.error({ message: json.error_msg });
        }
      });
  };

  onFinish = (value) => {
    fetch(`${BASE_URL}${API.MAP_SHIPMENT}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        so_number: this.state.so_detail.so_number,
        ref_shipment_id: value.ref_shipment_id,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          notification.success({ message: json.success_msg });
          this.props.history.push("/shipping-request-list");
        } else {
          notification.error({ message: json.error_msg });
        }
      });
  };
  render() {
    const { Option } = Select;
    const { so_detail, customer_account, shipment, isLoading } = this.state;
    return (
      <Headers
        bodyContain={
          <>
            <div className="site-layout-background form-design">
              <h4>Map Shipping SO's</h4>
              <Divider />
              <Spin spinning={isLoading}>
                <Form
                  name="register"
                  layout="vertical"
                  onFinish={this.onFinish}
                  scrollToFirstError
                >
                  <Row>
                    <Col md={12}>
                      <div className="mapping_detail">
                        <label>SO Number</label>
                        <p>{so_detail.so_number}</p>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mapping_detail">
                        <label>PO Number</label>
                        <p>{so_detail.po_number}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="mapping_detail">
                        <label>Item description</label>
                        <p>{so_detail.item_desc}</p>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <Form.Item
                        name="customer_account"
                        label="Select Customer account"
                        rules={[
                          {
                            required: true,
                            message: "Please select customer account.",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "100%" }}
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          placeholder="Select customer account"
                          onChange={this.handleCustomerAccount.bind(this)}
                        >
                          <option value="">Select customer account</option>
                          {customer_account.map((ca, i) => {
                            return <option value={ca.id}>{ca.nickname}</option>;
                          })}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col md={12}>
                      <Form.Item
                        name="ref_shipment_id"
                        label="Select shipment"
                        //   rules={[
                        //     {
                        //       required: false,
                        //       message: "Please select shipment id's!",
                        //     },
                        //   ]}
                      >
                        <Select
                          allowClear={true}
                          mode="multiple"
                          // value={selectedValues}
                          autoClearSearchValue={true}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0 ||
                            option.props.value
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {shipment.map((ca) => {
                            return (
                              <Option
                                key={ca.ref_shipment_id}
                                value={ca.ref_shipment_id}
                              >
                                {ca.shipment_id}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <Form.Item>
                        <Button type="primary" htmlType="submit" size="large">
                          Register
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Spin>
            </div>
          </>
        }
      />
    );
  }
}
export default withRouter(OmsSoDetail);
