import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import { message, Upload } from "antd";
import {
  AppstoreAddOutlined,
  MinusCircleTwoTone,
  EditOutlined,
  PlusOutlined
} from "@ant-design/icons";

import moment from "moment";

import {
  Row,
  Col,
  Divider,
  notification,
  Table,
  Spin,
  Tag,
  Modal,
  Button,
  Input,
  DatePicker,
  Dropdown,
  Space,
  Menu,
  Badge,
  Card,
  Steps,
  Drawer,
  Avatar,
  Select
} from "antd";
import Form from "../../Helper/Form";
import { token, sessionData } from "../../Helper/Session";
import { withRouter } from "react-router-dom";

const { TextArea } = Input;
// console.log(sessionData);

const { Step } = Steps;
class ViewShippingRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shipping_request_id: this.props.id,
      isToAddrLoad: false,
      isFromAddrLoad: false,
      isBtnLoad: false,
      isLoadingShippingRequest: false,
      request: [],
      visibleModal: false,
      items: [],
      isShipmentDelivered: false,
      shipping_id: "",
      status_id: 0, //shipping_status_id
      item_id: 0,
      shipping_status_data: [],
      file: "",
      file_url: "",
      isUpdateFailedStatus: false,
      user_id: sessionData.user_id,
      collection_logs: [],
      isDeliveryFailedStatus: false,
      drawerAddItem: false,
      qty_visible: "none",
      skus: [],
      selectedShipProCustomerAccount: []
    };
  }

  formRef = React.createRef();
  formRefDel = React.createRef();
  formRefLab = React.createRef();
  componentDidMount() {
    var selectedCAShipPro = localStorage.getItem('selectedCAShipPro');
    selectedCAShipPro = JSON.parse(selectedCAShipPro);
    if (selectedCAShipPro != null) {
      this.setState({
        selectedShipProCustomerAccount: selectedCAShipPro.selectedShipProCustomerAccount,
      })
    }
    // console.log(sessionData);
    this.setState({ isLoadingShippingRequest: true });
    fetch(`${BASE_URL}${API.SHIPPING_REQUEST_DETAIL}`, {
      method: "post",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        shipping_request_id: this.state.shipping_request_id,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isLoadingShippingRequest: false });
        if (json.status === true) {
          this.setState({
            request: json.data,
            request_shipping_status: json.data.status,
            shipping_id: json.data.id,
            items: json.data.items,
          });
          notification.success({ message: json.success_msg, placement: "top" });
        } else {
          notification.error({ message: json.error_msg, placement: "top" });
        }
      });
  }
  handleItemsChange = (sku_id) => {
    // alert(sku_id);
    var sku = this.state.skus.filter(function (el) {
      return el.id == sku_id;
    });
    var sku_detail = sku[0];
    if (sku_id == "customized_box") {
      this.formRef.current.setFieldsValue({
        item_description: "",
        available_qty: 1000,
      });
      this.setState({
        qty_visible: "none"
      })
    } else {
      this.setState({
        qty_visible: "block"
      })
      this.formRef.current.setFieldsValue({
        item_description: sku_detail.product_name,
        available_qty: parseInt(sku_detail.available),
        packaging: "No"
      });
    }

  };

  onFinish = (value) => {
    value.request_id = this.state.request.id;
    value.shipment_id = (this.state.items.length + 1);
    fetch(`${BASE_URL}${API.SAVE_LINE_ITEM}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(value),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status == true) {
          message.success(json.success_msg);
          this.componentDidMount();
        } else {
          message.error(json.error_msg);
        }
        this.setState({
          drawerAddItem: false
        })
      });
  }

  handleUpdateStatus = (shipping_request_id, status_id) => {
    this.setState({
      status_id: status_id
    });
    fetch(`${BASE_URL}${API.UPDATE_SHIPPING_STATUS}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        shipping_request_id: shipping_request_id,
        status: status_id,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status == true) {
          this.setState({
            request_shipping_status: status_id,
          });
          message.success(json.success_msg);
        } else {
          message.error(json.error_msg);
        }
      });
  };
  // handleUpdateStatus = (item_id, status_id) => {
  //   // alert(item_id + " " + status_id);
  //   if (status_id == 2) {
  //     this.setState({
  //       status_id: status_id,
  //       item_id: item_id,
  //       visibleModal: true,
  //     });
  //     this.get_items_status(status_id, item_id);
  //   } else if (status_id == 6) {
  //     this.setState({
  //       item_id: item_id,
  //       status_id: status_id,
  //       isShipmentDelivered: true,
  //     });
  //     this.get_items_status(status_id, item_id);
  //   } else if (status_id == 4) {
  //     this.setState({
  //       item_id: item_id,
  //       status_id: status_id,
  //     });
  //     fetch(`${BASE_URL}${API.UPDATE_SHIPPING_STATUS}`, {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/form-data",
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + token,
  //       },
  //       body: JSON.stringify({
  //         shipping_id: this.state.shipping_id,
  //         status: status_id,
  //         item_id: item_id,
  //       }),
  //     })
  //       .then((res) => res.json())
  //       .then((json) => {
  //         if (json.status == true) {
  //           this.setState({
  //             request_shipping_status: status_id,
  //           });
  //           message.success(json.success_msg);
  //         } else {
  //           message.error(json.error_msg);
  //         }
  //       });
  //   }
  // };

  saveLabelCreation = (value) => {
    fetch(`${BASE_URL}${API.UPDATE_SHIPPING_STATUS}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        shipping_id: this.state.shipping_id,
        label_creation_date: value.creation_date,
        carrier: value.carrier,
        tracking: value.tracking_no,
        pickup_date: value.pickup_date,
        pickup_time: value.pickup_time,
        url: value.tracking_url,
        status: this.state.status_id,
        // label: value.shipping_label["file"]["name"],
        item_id: this.state.item_id,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({ visibleModal: false, request_shipping_status: 1 });
          notification.success({ message: json.success_msg, placement: "top" });
        } else {
          notification.error({ message: json.error_msg, placement: "top" });
        }
      });
  };

  saveDelivery = (value) => {
    fetch(`${BASE_URL}${API.UPDATE_SHIPPING_STATUS}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        shipping_id: this.state.shipping_id,
        delivery_date: value.delivery_date,
        status: this.state.status_id,
        // pod: value.pod["file"]["name"],
        item_id: this.state.item_id,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            isShipmentDelivered: false,
            request_shipping_status: 6,
          });
          notification.success({ message: json.success_msg, placement: "top" });
        } else {
          notification.error({ message: json.error_msg, placement: "top" });
        }
      });
  };

  get_items_status = (status, line_item_id) => {
    // alert(status);
    this.setState({
      file: "",
      file_url: "",
    });
    fetch(`${BASE_URL}${API.SHIPPING_STATUS_DETAIL}`, {
      method: "post",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        shipping_id: this.state.shipping_request_id,
        line_item_id: line_item_id,
        status: status,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            shipping_status_data: json.data,
          });
          if (status == 2) {
            this.formRefLab.current.setFieldsValue({
              carrier: this.state.shipping_status_data.carrier,
              creation_date: moment(
                this.state.shipping_status_data.label_creation_date
              ),
              tracking_no: this.state.shipping_status_data.tracking,
              tracking_url: this.state.shipping_status_data.url,
              pickup_date: moment(this.state.shipping_status_data.pickup_date),
              pickup_time: this.state.shipping_status_data.pickup_time,
            });
            this.setState({
              file: json.data.label,
              file_url: json.data.label_url,
            });
          } else if (status == 6) {
            this.formRefDel.current.setFieldsValue({
              delivery_date: moment(
                this.state.shipping_status_data.delivery_date
              ),
            });
            this.setState({
              file: json.data.pod,
              file_url: json.data.pod_url,
            });
          }
        }
      });
  };

  handleUpdateFailedStatus = (item_id, status_id) => {
    this.setState({
      item_id: item_id,
      status_id: status_id,
      collection_logs: [],
    });
    if (status_id == 3) {
      this.setState({ isUpdateFailedStatus: true });
      this.getCollectionFailLogs(item_id);
    } else if (status_id == 5) {
      this.setState({ isDeliveryFailedStatus: true });
      this.getDeliveryFailLogs(item_id);
    }
  };

  saveCollectionFailLogs = (value) => {
    fetch(`${BASE_URL}${API.UPDATE_COLLECTION_LOGS}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        status_id: this.state.status_id,
        request_id: this.state.shipping_request_id,
        item_id: this.state.item_id,
        failure_date: value.failure_date,
        failure_time: value.failure_time,
        reschedule_date: value.reschedule_date,
        reschedule_time: value.reschedule_time,
        reason: value.reason,
        user_id: this.state.user_id,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            isShipmentDelivered: false,
            request_shipping_status: 3,
          });
          notification.success({ message: json.success_msg, placement: "top" });
          this.getCollectionFailLogs(this.state.item_id);
        } else {
          notification.error({ message: json.error_msg, placement: "top" });
        }
      });
  };

  saveDeliveryFailLogs = (value) => {
    fetch(`${BASE_URL}${API.UPDATE_DELIVERY_LOGS}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        status_id: this.state.status_id,
        request_id: this.state.shipping_request_id,
        item_id: this.state.item_id,
        failure_date: value.failure_date,
        failure_time: value.failure_time,
        reschedule_date: value.reschedule_date,
        reschedule_time: value.reschedule_time,
        reason: value.reason,
        user_id: this.state.user_id,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            isShipmentDelivered: false,
            request_shipping_status: 5,
          });
          notification.success({ message: json.success_msg, placement: "top" });
          this.getDeliveryFailLogs(this.state.item_id);
        } else {
          notification.error({ message: json.error_msg, placement: "top" });
        }
      });
  };

  getCollectionFailLogs = (item_id) => {
    fetch(`${BASE_URL}${API.GET_COLLECTION_LOGS}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        item_id: item_id,
        user_id: this.state.user_id,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            collection_logs: json.data,
          });
        }
      });
  };

  getDeliveryFailLogs = (item_id) => {
    fetch(`${BASE_URL}${API.GET_DELIVERY_LOGS}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        item_id: item_id,
        user_id: this.state.user_id,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            delivery_logs: json.data,
          });
        }
      });
  };

  openAddItem = () => {
    this.setState({ drawerAddItem: true, qty_visible: "none" });
    var warehouse_id = this.state.request.collection_address.split("W")[1];
    if (warehouse_id) {
      this.handleChangeSKU(warehouse_id);
    }
  }

  handleChangeSKU = (warehouse_id) => {
    this.setState({ skus: [] });
    const dta = {
      // sku: value,
      warehouse_id: warehouse_id,
      customer_account_id: this.state.selectedShipProCustomerAccount.id
    }
    fetch(`${BASE_URL}${API.GET_SKU}`, {
      method: 'post',
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(dta)
    }).then((res) => res.json()).then((json) => {
      if (json.status === true) {
        const skus = [];
        json.data.forEach((r) => {
          skus.push({
            id: r['sku'],
            product_name: r['product_name'] + "-" + r['sku'],
            available: r.available
          });
        });
        this.setState({ skus });
      }
    });
  }


  render() {
    const { Option } = Select;
    const {
      request,
      request_shipping_status,
      items,
      isLoadingShippingRequest,
      drawerAddItem,
      isShipmentDelivered,
      shipping_id,
      item_id,
      isUpdateFailedStatus,
      collection_logs,
      isDeliveryFailedStatus,
      delivery_logs,
      skus,
    } = this.state;
    const skus_options = skus.map(d => <Option key={d.id} value={d.id}>{d.product_name}</Option>);
    const columns = [
      {
        title: "SNo",
        dataIndex: "key",
      },
      {
        title: "Item Description",
        dataIndex: "name",
      },
      {
        title: "Unit Quantity",
        dataIndex: "qty",
      },
      {
        title: "Packaging",
        dataIndex: "packaging",
      },
      {
        title: "Other Information",
        dataIndex: "other_info",
      },
      // {
      //   title: "Width(cm)",
      //   dataIndex: "width",
      // },
      // {
      //   title: "Height(cm)",
      //   dataIndex: "height",
      // },
      // {
      //   title: "Action",
      //   dataIndex: "action",
      // },
    ];

    if (sessionData.role_id == 3) {
      columns.splice(6, 1, {
        title: "POD/Tracking URL",
        dataIndex: "download",
      });
    }

    var data = [];
    items.map((itm, i) => {
      data.push({
        key: i + 1,
        name: itm.item_description,
        qty: itm.qty,
        packaging: itm.packaging,
        // length: itm.length,
        // width: itm.width,
        // height: itm.height,
        // value: itm.value,
        other_info: itm.other_info,
        // description: itm.description,
        // url: itm.url,
        // status: itm.status,
        // pod: itm.pod,
        // collection_fail: itm.collection_fail,
        // delivery_fail: itm.delivery_fail,
        // download: (
        //   <>
        //     {itm.status >= 2 && (
        //       <p style={{ margin: 1 }}>
        //         <a target="_blank" href={itm.url}>
        //           tracking url
        //         </a>
        //       </p>
        //     )}
        //     {itm.status == 3 && (
        //       <div style={{ margin: 1 }}>
        //         <a className="btn btn-success" target="_blank" href={itm.pod}>
        //           Download POD
        //         </a>
        //       </div>
        //     )}
        //   </>
        // ),
        action: (
          <>
            {sessionData.role_id == 1 && (
              <Space wrap>
                <Dropdown.Button
                  overlay={
                    <Menu
                      items={[
                        {
                          key: "2",
                          label: (
                            <Button
                              type="primary"
                              className="actionButton"
                              onClick={() => this.handleUpdateStatus(itm.id, 2)}
                            >
                              Shipping label created
                            </Button>
                          ),
                        },
                        {
                          key: "3",
                          label: (
                            <Button
                              type="danger"
                              className="actionButton"
                              onClick={() =>
                                this.handleUpdateFailedStatus(itm.id, 3)
                              }
                            >
                              Collection fail
                            </Button>
                          ),
                        },
                        {
                          key: "4",
                          label: (
                            <Button
                              type="secondary"
                              className="actionButton"
                              onClick={() => this.handleUpdateStatus(itm.id, 4)}
                            >
                              Shipment In-Transit
                            </Button>
                          ),
                        },
                        {
                          key: "5",
                          label: (
                            <Button
                              type="orange"
                              className="actionButton"
                              onClick={() =>
                                this.handleUpdateFailedStatus(itm.id, 5)
                              }
                            >
                              Delivery
                            </Button>
                          ),
                        },
                        {
                          key: "6",
                          label: (
                            <Button
                              type="warning"
                              className="actionButton"
                              onClick={() => this.handleUpdateStatus(itm.id, 6)}
                            >
                              Shipment delivered
                            </Button>
                          ),
                        },
                      ]}
                    />
                  }
                  type="dashed"
                  placement="left"
                >
                  Action
                </Dropdown.Button>
              </Space>
            )}
          </>
        ),
      });
    });
    const fileList = [
      {
        uid: "1",
        name: this.state.file,
        status: this.state.file ? "done" : "error",
        url: this.state.file_url,
      },
    ];
    // console.log(fileList);
    const props = {
      action: `${BASE_URL}${API.UPLOAD_DOCS}`,
      multiple: false,
      maxCount: 1,
      headers: {
        Accept: "application/form-data",
        Authorization: "Bearer " + token,
      },
      fileList: fileList[0].status == "done" ? fileList : [],
      onChange(info) {
        // console.log(info);
        if (info.file.status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        if (info.file.status == "done") {
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status == "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
      // onRemove(v) {
      //   console.log(v);
      // },
    };
    return (
      <Headers
        bodyContain={
          <>
            <div className="site-layout-background form-design">
              <h4>Shipment Instruction ID : {request.request_id} </h4>
              <Steps
                size="small"
                current={parseInt(request_shipping_status - 1)}
                status={"process"}
              >
                <Step
                  title="Instruction Received"
                // description="This mean shipping for this request is placed."
                />

                <Step
                  title="Shipment In Progress"
                // description="Your shipping request is In-Transit."
                />
                <Step title="Shipment Completed"
                // description="Shipment is delivered."
                />
              </Steps>

              <br />
              {request_shipping_status == 1 && (
                <Tag color="#002140">Instruction Received</Tag>
              )}
              {request_shipping_status == 2 && (
                <Tag color="#e2ca10">Shipment In Progress</Tag>
              )}
              {request_shipping_status == 3 && (
                <Tag color="#019b04">Shipment Completed</Tag>
              )}
              {(sessionData.role_id == 1 || sessionData.role_id == 5 || sessionData.role_id == 2) && (
                <Space wrap style={{ float: "right" }}>
                  <Dropdown.Button
                    overlay={
                      <Menu
                        items={[
                          {
                            key: "1",
                            label: (
                              <Button
                                type=""
                                className="actionButton"
                                onClick={() => this.handleUpdateStatus(this.state.shipping_request_id, 1)}
                              >
                                Instruction Received
                              </Button>
                            ),
                          },
                          {
                            key: "2",
                            label: (
                              <Button
                                type="warning"
                                className="actionButton"
                                onClick={() =>
                                  this.handleUpdateStatus(this.state.shipping_request_id, 2)
                                }
                              >
                                Shipment In Progress
                              </Button>
                            ),
                          },
                          {
                            key: "3",
                            label: (
                              <Button
                                type="success"
                                className="actionButton"
                                onClick={() => this.handleUpdateStatus(this.state.shipping_request_id, 3)}
                              >
                                Shipment Completed
                              </Button>
                            ),
                          },
                          {
                            key: "4",
                            label: (
                              <Button
                                type="danger"
                                className="actionButton"
                                icon={<EditOutlined />}
                                onClick={() => this.props.history.push('/shipping-request/edit/' + this.state.shipping_request_id)}
                              >
                                Edit Shipment
                              </Button>
                            ),
                          }
                        ]}
                      />
                    }
                    type="secondary"
                    placement="right"
                    size="medium"
                  >
                    Update Instruction
                  </Dropdown.Button>
                </Space>
              )}
              <Divider />
              <div className="shipping_detail">
                <Spin spinning={isLoadingShippingRequest}>
                  <Row>
                    {/* <Col md={12}>
                      <h5>Shipping Request Id</h5>
                      <p>{request.request_id}</p>
                    </Col> */}
                    <Col md={6}>
                      <p style={{ fontWeight: "600" }}>Client Ref ID/ Client AWB No</p>
                      <p>{request.client_reference_id}</p>
                    </Col>
                    <Col md={6}>
                      <p style={{ fontWeight: "600" }}>Customer Account</p>
                      <p>{request.customer_account_name}</p>
                    </Col>
                    <Col md={6}>
                      <p style={{ fontWeight: "600" }}>Created By</p>
                      <p>{request.first_name + " " + request.last_name}</p>
                    </Col>
                    <Col md={6}>
                      <p style={{ fontWeight: "600" }}>Instruction date</p>
                      <p>{(request.awb_collection_date) ? moment(request.awb_collection_date).format("DD-MM-YYYY") : ''}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Badge.Ribbon
                        text={
                          request.is_reverse == "yes"
                            ? "Shipping from Address"
                            : "Shipping to Address"
                        }
                        color={request.is_reverse == "yes" ? "green" : ""}
                      >
                        <Card className="address">
                          <p className="text-center"></p>
                          <Row>
                            <Col sm={24}>
                              <p>
                                {request.to_contact_name +
                                  ", " +
                                  request.to_company_name}
                              </p>
                            </Col>
                            <Col sm={24}>
                              <p>
                                {request.to_street +
                                  ", " +
                                  request.to_city +
                                  ", " +
                                  request.to_state +
                                  ", " +
                                  request.to_country +
                                  " " +
                                  (request.to_postal
                                    ? " - " + request.to_postal
                                    : "")}
                              </p>
                            </Col>
                            <Col sm={24}>
                              <p>
                                {(request.to_contact_number
                                  ? request.to_contact_number + "/ "
                                  : "") +
                                  "" +
                                  request.to_email}
                              </p>
                            </Col>
                          </Row>
                        </Card>
                      </Badge.Ribbon>
                    </Col>

                    <Col md={12}>
                      <Badge.Ribbon
                        text={
                          request.is_reverse == "yes"
                            ? "Shipping to Address"
                            : "Shipping from Address"
                        }
                        color={request.is_reverse == "yes" ? "" : "green"}
                      >
                        <Card md={6} className="address">
                          <p className="text-center"></p>
                          <Row>
                            <Col sm={24}>
                              <p>
                                {request.from_contact_name +
                                  ", " +
                                  request.from_company_name}
                              </p>
                            </Col>

                            <Col sm={24}>
                              <p>
                                {request.from_street +
                                  ", " +
                                  request.from_city +
                                  ", " +
                                  request.from_state +
                                  ", " +
                                  request.from_country +
                                  " " +
                                  (request.from_postal
                                    ? " - " + request.from_postal
                                    : "")}
                              </p>
                            </Col>
                            <Col sm={24}>
                              <p>
                                {(request.from_contact_number
                                  ? request.from_contact_number + "/ "
                                  : "") +
                                  "" +
                                  request.from_email}
                              </p>
                            </Col>
                          </Row>
                        </Card>
                      </Badge.Ribbon>
                    </Col>
                  </Row>

                  <div className="line_item">
                    <Table
                      dataSource={data}
                      columns={columns}
                      pagination={false}
                    />
                    {(sessionData.role_id == 1 || sessionData.role_id == 2) && (
                      <Button
                        type="dashed-secondary"
                        size="small"
                        block
                        onClick={() => this.openAddItem()}
                        style={{ width: "100%" }}
                        icon={<PlusOutlined />}
                      >
                        Add Items
                      </Button>
                    )}
                  </div>
                </Spin>
              </div>
            </div>
            <Drawer title="Add Item" placement="right" closable={true}
              mask={true}
              width={600}
              visible={drawerAddItem}
              onClose={() => { this.setState({ drawerAddItem: false }) }}
              destroyOnClose
              extra={
                <Space>
                  <Button onClick={() => { this.setState({ drawerAddItem: false }) }}>Cancel</Button>
                </Space>
              }>
              <Row>
                <Form
                  ref={this.formRef}
                  name="add_item"
                  layout="vertical"
                  onFinish={this.onFinish}
                  scrollToFirstError
                  className="add_Cart_in"
                >
                  <Row>
                    <Col md={12}>
                      <Form.Item

                        name={"item_id"}
                        label="Select Product"
                        rules={[
                          {
                            required: true,
                            message: "Please select product!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select item type"
                          onChange={this.handleItemsChange.bind(
                            this
                          )}
                          style={{
                            width: "200px",
                          }}
                        >
                          {skus_options}
                          <Option value="customized_box">
                            New Item
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item

                        name={"item_description"}
                        label="Item Description"
                        rules={[
                          {
                            required: true,
                            message: "Please select Item description!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12} style={{ display: this.state.qty_visible }}>
                      <Form.Item

                        name={"available_qty"}
                        label="Quantity in Stock"
                      >
                        <Input
                          style={{
                            width: "100px",
                          }}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item

                        name={"item_quantity"}
                        label="Item quantity"
                        // dependencies={"available_qty"}
                        rules={[
                          {
                            required: true,
                            message: "Please enter item quantity!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              // console.log(getFieldValue("items")[key]['available_qty']);
                              if (!value || value <= getFieldValue("available_qty")) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "Quantity can not exeeds from available Quantity!"
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input
                          placeholder="Item Quantity"
                        // style={{
                        //   width: "150px",
                        // }}
                        />
                      </Form.Item>
                    </Col>


                    <Col md={12}>
                      <Form.Item
                        name={"packaging"}
                        label="Packaging Required before collection"
                        rules={[
                          {
                            required: true,
                            message: "Please select option!",
                          },
                        ]}
                      >
                        <Select>
                          <Option value="YES">Yes</Option>
                          <Option value="NO">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={24}>
                      <Form.Item
                        name={"other_info"}
                        label="Other Information"
                        rules={[
                          {
                            required: false,
                            message: "Please enter other information!",
                          },
                        ]}
                      >
                        <Input placeholder="Other Information" />
                      </Form.Item>

                    </Col>
                    {/* <div className="help">(Note - please provide any other information which should be noted before shipping the items such as any specific serial numbers)</div> */}
                    <Col sm={24}>
                      <Form.Item>
                        <Button type="primary" htmlType="submit" size="meduim" icon={<AppstoreAddOutlined />}>
                          Save this item
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Row>

              {/* <Divider /> */}
              <Row>

              </Row>
            </Drawer>
          </>
        }
      />
    );
  }
}

export default withRouter(ViewShippingRequest);
