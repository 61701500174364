import React from "react";
import { Layout, Menu, Modal, notification, Button, Row, Col, Drawer, Input, Card, Select, Tag } from "antd";
import Form from "../../Helper/Form";
import { Link } from "react-router-dom";

import {
  DesktopOutlined,
  PieChartOutlined,
  UserOutlined,
  LogoutOutlined,
  UnorderedListOutlined,
  BuildOutlined,
  AppstoreAddOutlined,
  ShoppingCartOutlined
} from "@ant-design/icons";
import { sessionData, token } from "../../Helper/Session";
import { withRouter } from "react-router-dom";
import { API, BASE_URL, SITE_URL } from "../../Endpoint";
const { Header, Content, Footer, Sider } = Layout;

const { SubMenu } = Menu;
// alert(window.location.hostname);
class Headers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      openkey: "admin",
      theme: "dark",
      sessionData: [],
      img_collapse: "",
      changePasswordDrawer: false,
      warehouse: [],
      openSelectCustomerAccountWareHouse: false,
      openSelectCustomerAccount: false,
      customer_account: [],
      selectedCustomerAccount: [],
      selectedWareHouse: [],
      warehouse_menu: [],
      selectedShipProCustomerAccount: [],
    };
  }
  formRef = React.createRef();
  formRefShip = React.createRef();
  componentDidMount() {
    var getSession = localStorage.getItem("authenticated");
    const parseSession = JSON.parse(getSession);
    this.setState({
      sessionData: parseSession,
      openProfileModal: false,
      logo: SITE_URL + "image/logo-white.png",
      img_collapse: "",
    });
    // console.log(parseSession);
    if (parseSession != null) {
      if (
        typeof parseSession.token === "undefined" ||
        parseSession.token === "" ||
        parseSession.token === " " ||
        parseSession === "null"
      ) {
        notification.error({
          message: "Your session is expired, Please login again!",
        });
        this.props.history.push("/login");
      } else {
        fetch(`${BASE_URL}${API.AUTHORIZATION}`, {
          headers: {
            Accept: "application/form-data",
            "Content-Type": "application/json",
            Authorization: "Bearer " + parseSession.token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status === false) {
              localStorage.clear();
              notification.error({
                message: json.error_msg,
              });
              this.props.history.push("/login");
            } else {
              if (sessionData.role_id == 1 || sessionData.role_id == 2) {
                this.loadWareHouseMenu();
              }
            }
          });
      }
    } else {
      notification.error({
        message: "Your session is expired, Please login again!",
      });
      this.props.history.push("/login");
    }
    /*check warehouselocation & customer account selected or not*/
    var selectedWareCA = localStorage.getItem('selectedWareCA');
    selectedWareCA = JSON.parse(selectedWareCA);
    if (selectedWareCA != null) {
      this.setState({
        selectedCustomerAccount: selectedWareCA.selectedCustomerAccount,
        selectedWareHouse: selectedWareCA.selectedWareHouse
      })
    }
    var selectedCAShipPro = localStorage.getItem('selectedCAShipPro');
    selectedCAShipPro = JSON.parse(selectedCAShipPro);
    if (selectedCAShipPro != null) {
      this.setState({
        selectedShipProCustomerAccount: selectedCAShipPro.selectedShipProCustomerAccount,
      })
    }
    /* //check warehouselocation & customer account selected or not*/
  }


  loadWareHouseMenu = () => {
    fetch(`${BASE_URL}${API.WAREHOUSE_MENU}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({ warehouse_menu: json.data });
        }
      });
  }

  onFinish = (values) => {
    fetch(`${BASE_URL}${API.CHANGE_PASSWORD}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          notification.success({ message: json.success_msg });
          this.setState({ changePasswordDrawer: false });
        } else {
          notification.error({ message: json.error_msg });
        }
      });
  };

  onCollapse = (collapsed) => {
    if (collapsed === true) {
      this.setState({
        collapsed,
        logo: SITE_URL + "image/favicon.png",
        img_collapse: "img_collapse",
      });
    } else {
      this.setState({
        collapsed,
        logo: SITE_URL + "image/logo-white.png",
        img_collapse: "",
      });
    }
  };

  fetchSelectCustomerWarehouse = (e) => {
    e.preventDefault();
    this.fetchCustomerAccount();
    this.setState({ openSelectCustomerAccountWareHouse: true });
    this.formRef.current.setFieldsValue({
      customer_account: this.state.selectedCustomerAccount.id
    })
  }

  fetchSelectCustomerWarehouseForCustomerAccount = (customer_account_id) => {
    fetch(`${BASE_URL}${API.WAREHOUSE}?customer_account_id=` + customer_account_id, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            warehouse: json.data,
          });
          this.formRef.current.setFieldsValue({
            warehouse: this.state.selectedWareHouse.id,
          })
        } else {
          this.setState({
            warehouse: [],
          });
          this.formRef.current.setFieldsValue({
            warehouse: "",
          })
        }
      });
  }

  fetchSelectCustomerForShipPro = (e) => {
    e.preventDefault();
    fetch(`${BASE_URL}${API.CUSTOMER_ACCOUNT_LIST}`, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            customer_account: json.data,
          });
        }
      });
    this.setState({ openSelectCustomerAccount: true }, () => {
      this.formRefShip.current.setFieldsValue({
        customer_account: this.state.selectedShipProCustomerAccount.id
      })
    });
  }

  handleSelectCustomerAccount = (value) => {
    let warehouseArray = this.state.warehouse.filter(function (el) {
      return el.id == value.warehouse;
    });
    let CustomerAccountArray = this.state.customer_account.filter(function (ca) {
      return ca.id == value.customer_account;
    });
    this.setState({
      selectedWareHouse: warehouseArray[0],
      selectedCustomerAccount: CustomerAccountArray[0],
      openSelectCustomerAccountWareHouse: false,
    })
    const storageDta = {
      selectedWareHouse: this.state.selectedWareHouse,
      selectedCustomerAccount: this.state.selectedCustomerAccount,
      user_id: sessionStorage.id
    }
    localStorage.setItem("selectedWareCA", JSON.stringify(storageDta));
    if (window.location.pathname.match('dashboard')) {
      localStorage.setItem("saveOpenKey", "manage_cart");
    }
    if (window.location.pathname == "/cart-out" || window.location.pathname == "/cart-in" || window.location.pathname == "/inventory" || window.location.pathname == "/dashboard") {
      window.location.reload();
    }
  }

  handleSelectShipProCustomerAccount = (value) => {
    let CustomerAccountArray = this.state.customer_account.filter(function (ca) {
      return ca.id == value.customer_account;
    });
    this.setState({
      selectedShipProCustomerAccount: CustomerAccountArray[0],
      openSelectCustomerAccount: false,
    })
    const storageDta = {
      selectedShipProCustomerAccount: this.state.selectedShipProCustomerAccount,
      user_id: sessionStorage.id
    }
    localStorage.setItem("selectedCAShipPro", JSON.stringify(storageDta));
    if (window.location.pathname.match('dashboard')) {
      localStorage.setItem("saveOpenKey", "shipping_request");
    }
    if (window.location.pathname.match("/shipping-request-list") || window.location.pathname.match("/add-shipping-request") || window.location.pathname == "/dashboard") {
      window.location.reload();
      // alert();
    }
  }

  fetchCustomerAccount = () => {
    fetch(`${BASE_URL}${API.CUSTOMER_ACCOUNT_LIST}`, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            customer_account: json.data,
          }, () => {
            this.formRef.current.setFieldsValue({
              customer_account: this.state.selectedCustomerAccount.id
            })
            this.fetchSelectCustomerWarehouseForCustomerAccount(this.state.selectedCustomerAccount.id);
          });
        }
      });
  }
  logout = () => {
    localStorage.removeItem("authenticated");
    localStorage.removeItem("selectedWareCA");
    localStorage.removeItem("selectedCAShipPro");
    notification.warn({ message: "You are successfully logout." });
    window.location.href = "/login";
  };

  openChangePassword = () => {
    this.setState({ changePasswordDrawer: true })
  }

  showMyProfile = () => {
    this.setState({ openProfileModal: true });
  };

  render() {
    if (
      window.location.pathname === "/module" ||
      window.location.pathname === "/all-users" ||
      window.location.pathname === "/user-role" ||
      window.location.pathname === "/project" ||
      window.location.pathname === "/category" ||
      window.location.pathname === "/add-user" ||
      window.location.pathname === "/customer-account" ||
      window.location.pathname === "/customer-users" ||
      window.location.pathname === "/sisl-users" ||
      window.location.pathname === "/edit-customer-user" ||
      window.location.pathname === "/sisl-entity" ||
      window.location.pathname === "/add-customer-entity" ||
      window.location.pathname === "/customer-entity" ||
      window.location.pathname === "/add-sisl-entity" ||
      window.location.pathname === "/edit-sisl-entity" ||
      window.location.pathname === "/warehouse" ||
      window.location.pathname === "/warehouse/add" ||
      window.location.pathname === "/add-user" ||
      window.location.pathname === "/account-managers" ||
      window.location.pathname == "/warehouse-operators" ||
      window.location.pathname == "/logistic-operators"
    ) {
      var openkey = "admin";
    }
    if (
      window.location.pathname === "/cart-in" ||
      window.location.pathname === "/cart-out" || window.location.pathname === "/inventory"
    ) {
      var openkey = "manage_cart";
    }
    if (
      window.location.pathname === "/product"
    ) {
      var openkey = "manage_warehouse";
    }
    var path = window.location.pathname;
    if (path.match("shipping-request/view")) {
      openkey = "shipping_request";
      var selectedKey = "/shipping-request-list";
    }
    if (path.match("edit-customer-account")) {
      openkey = "admin";
      selectedKey = "/customer-account";
    }
    if (path.match("logistic-operators")) {
      selectedKey = "/logistic-operators";
    }
    if (path.match("/finance-users")) {
      selectedKey = "/finance-users";
    }
    // if (path.match("add-user")) {
    //   openkey = "admin";
    //   selectedKey = "/customer-users";
    // }
    if (path.match("edit-sisl-entity")) {
      openkey = "admin";
      selectedKey = "/sisl-entity";
    }
    if (path.match("customer-entity")) {
      openkey = "admin";
      selectedKey = "/customer-entity";
    }
    if (path.match("clients")) {
      openkey = "manage_warehouse";
    }
    if (path.match("product")) {
      openkey = "manage_warehouse";
    }
    if (path.match('shipping')) {
      openkey = "shipping_request";
    }
    if (path.match('dashboard')) {
      openkey = localStorage.getItem('saveOpenKey');
    }
    if (path.match('/member')) {
      selectedKey = "member";
    }
    if (path.match('users/edit')) {
      openkey = "admin";
    }
    if (path.match('/search')) {
      openkey = "manage_cart";
      selectedKey = "/search";
    }
    const {
      collapsed,
      theme,
      sessionData,
      openProfileModal,
      logo,
      img_collapse,
      changePasswordDrawer,
      warehouse,
      warehouse_menu,
      openSelectCustomerAccountWareHouse, customer_account, openSelectCustomerAccount
    } = this.state;
    const { Option } = Select;
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={this.onCollapse}
          width={256}
          breakpoint="lg"
          style={{
            overflow: 'auto'
          }}
        >
          <div className="logo">
            <img src={logo} className={img_collapse} />
          </div>
          <Menu
            theme={theme}
            defaultOpenKeys={[openkey]}
            defaultSelectedKeys={[window.location.pathname, selectedKey]}
            mode="inline"
          >
            <Menu.Item key="1" icon={<PieChartOutlined />}>
              <Link to="/dashboard">Dashboard</Link>
            </Menu.Item>
            {sessionData.is_manager == 1 && (
              <Menu.Item key="member" icon={<UserOutlined />}>
                <Link to="/member">Your Team Member</Link>
              </Menu.Item>
            )}
            {sessionData.role_id == 1 && (
              <SubMenu
                key="admin"
                icon={<DesktopOutlined />}
                title="Manage Admin"
              >
                <Menu.Item key="/sisl-entity">
                  <Link to="/sisl-entity">SISL Entity</Link>
                </Menu.Item>
                <Menu.Item key="/customer-account">
                  <Link to="/customer-account">Customer Account</Link>
                </Menu.Item>
                <Menu.Item key="/customer-entity">
                  <Link to="/customer-entity">Customer Entity</Link>
                </Menu.Item>
                <Menu.Item key="/warehouse">
                  <Link to="/warehouse">Warehouse</Link>
                </Menu.Item>
                <Menu.Item key="/customer-users">
                  <Link to="/customer-users">Customer User</Link>
                </Menu.Item>
                <Menu.Item key="/account-managers">
                  <Link to="/account-managers">Account Managers</Link>
                </Menu.Item>
                <Menu.Item key="/warehouse-operators">
                  <Link to="/warehouse-operators">Warehouse Operators</Link>
                </Menu.Item>
                <Menu.Item key="/logistic-operators">
                  <Link to="/logistic-operators">Logistic Operators</Link>
                </Menu.Item>
                <Menu.Item key="/finance-users">
                  <Link to="/finance-users">Finance User</Link>
                </Menu.Item>
                <Menu.Item key="/search">
                  <Link to="/search">Search SKU & Serial</Link>
                </Menu.Item>
                <Menu.Item key="/all-users">
                  <Link to="/all-users">All Users</Link>
                </Menu.Item>
              </SubMenu>
            )}

            {(sessionData.role_id == 2 || sessionData.role_id == 1) && (
              <SubMenu
                key="manage_warehouse"
                icon={<UnorderedListOutlined />}
                title="Manage Products"
              >

                {
                  warehouse_menu.map((wr) => {
                    return (
                      <Menu.Item key={"/warehouse/clients/" + wr.id}>
                        <Link onClick={() => this.props.history.push("/warehouse/clients/" + wr.id)}>{wr.location_name}</Link>
                      </Menu.Item>
                    )
                  })
                }
              </SubMenu>)}
            {(sessionData.role_id != 5) && (
              <SubMenu
                key="manage_cart"
                icon={<ShoppingCartOutlined />}
                title="Warehouse Pro"
                className="warehousepro"
                onClick={() => this.menuClick}
              >
                <Menu.Item key="/select1" className="warehousing_show_selected">
                  <Link onClick={this.fetchSelectCustomerWarehouse}>Selected Customer Account<span><Tag color="orange">{(this.state.selectedCustomerAccount.customer_account_name) ? this.state.selectedCustomerAccount.nickname : 'None'}</Tag></span></Link>
                </Menu.Item>
                <Menu.Item key="/select2" className="warehousing_show_selected">
                  <Link onClick={this.fetchSelectCustomerWarehouse}>Selected Warehouse<span><Tag color="purple">{(this.state.selectedWareHouse.location_name) ? this.state.selectedWareHouse.location_name : 'None'}</Tag></span></Link>
                </Menu.Item>
                {(sessionData.role_id != 6) && (
                  <Menu.Item key="/cart-in">
                    <Link to="/cart-in">Goods-In</Link>
                  </Menu.Item>
                )}
                <Menu.Item key="/cart-out">
                  <Link to="/cart-out">Goods-Out</Link>
                </Menu.Item>
                {(sessionData.role_id != 6) && (
                  <>
                    <Menu.Item key="/inventory">
                      <Link to="/inventory">Inventory</Link>
                    </Menu.Item>
                    {(sessionData.role_id != 4) && (
                      <Menu.Item key="/search">
                        <Link to="/search">Search SKU & Serial</Link>
                      </Menu.Item>
                    )}
                  </>
                )}
              </SubMenu>)}
            {/* shipping request */}
            {(sessionData.role_id != 6) && (
              <SubMenu
                key="shipping_request"
                icon={<BuildOutlined />}
                title="Ship Pro"
                className="shippro"
              >
                <Menu.Item key="/select3" className="warehousing_show_selected">
                  <Link onClick={this.fetchSelectCustomerForShipPro}>Selected Customer Account<span><Tag color="orange">{(this.state.selectedShipProCustomerAccount.customer_account_name) ? this.state.selectedShipProCustomerAccount.nickname : 'None'}</Tag></span></Link>
                </Menu.Item>
                <Menu.Item key="/add-shipping-request">
                  <Link to="/add-shipping-request">Create Shipment Instruction</Link>
                </Menu.Item>
                <Menu.Item key="/shipping-request-list">
                  <Link to="/shipping-request-list">Shipment Instruction List</Link>
                </Menu.Item>
              </SubMenu>
            )}
            {/* shipping request */}
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <Menu
              theme="light"
              mode="inline"
              style={{ float: "left", width: "250px", borderRight: "none", height: "inherit" }}
            >
              {/* <Menu.Item key="3" onClick={""} className="item_selected"> */}
              <span className="left_greeting">{(this.props.title) ? this.props.title : "Hello, " + sessionData.name}</span>
              {/* </Menu.Item> */}
            </Menu>
            <Menu theme="light" mode="horizontal" style={{ float: "right" }}>
              <Menu.Item key="1">
                <UserOutlined />{" "}
                <Link className="submenu" onClick={this.showMyProfile}>
                  My profile
                </Link>
              </Menu.Item>
              <Menu.Item key="2" onClick={this.logout}>
                <LogoutOutlined /> <span className="submenu">Logout</span>
              </Menu.Item>
            </Menu>
          </Header>

          <Content style={{ margin: "10px" }}>
            <div style={{ padding: "10px", minHeight: "360" }}>
              {this.props.bodyContain}
            </div>
          </Content>
          <Modal
            title="My Profile"
            style={{ top: 20 }}
            visible={openProfileModal}
            onCancel={() => {
              this.setState({ openProfileModal: false });
            }}
            footer={[
              <Button type="primary" onClick={this.openChangePassword}>
                Change Password
              </Button>,
              <Button
                key="ok"
                onClick={() => {
                  this.setState({ openProfileModal: false });
                }}
              >
                Ok
              </Button>,
            ]}
          >
            <Drawer title="Change Password" placement="right" closable={true}
              mask={true}
              width={600}
              visible={changePasswordDrawer}
              onClose={() => this.setState({ changePasswordDrawer: false })}
              destroyOnClose>
              <Card>
                <Form
                  name="register"
                  layout="vertical"
                  onFinish={this.onFinish}
                  scrollToFirstError
                  className="add_Cart_in"
                >
                  <Row>
                    <Col sm={24}>
                      <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                          {
                            required: true,
                            message: "Please enter new password!",
                          },
                        ]}
                      >
                        <Input.Password placeholder="Password" />
                      </Form.Item>
                    </Col>
                    <Col sm={24}>
                      <Form.Item
                        name="confirm_password"
                        label="Change password"
                        dependencies={["password"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please enter confirm password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue("password") === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "The two passwords that you entered do not match!"
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password placeholder="Confirm Password" />
                      </Form.Item>
                    </Col>
                    <Col sm={24}>
                      <Form.Item>
                        <Button type="primary" htmlType="submit" size="meduim" icon={<AppstoreAddOutlined />}>
                          Save Password
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Drawer>
            <div className="my-profile">
              <Row>
                <Col md={12}>
                  <p>
                    Name:{" "}
                    <b>
                      <span>{sessionData.name}</span>
                    </b>
                  </p>
                </Col>
                <Col md={12}>
                  <p>
                    Role:{" "}
                    <b>
                      <span>{sessionData.user_role}</span>
                    </b>
                  </p>
                </Col>
                <Col md={24}>
                  <p>
                    Email:{" "}
                    <b>
                      <span>{sessionData.email}</span>
                    </b>
                  </p>
                </Col>
              </Row>
            </div>
          </Modal>
          {/* Modal for selecting customer account and warehouse */}
          <Modal
            title="Select Customer Account & WareHouse"
            style={{ top: 20 }}
            visible={openSelectCustomerAccountWareHouse}
            width={600}
            onCancel={() => this.setState({ openSelectCustomerAccountWareHouse: false })}
            footer={[
              <Button
                key="cancel"
                type="default"
                loading=""
                onClick={() => this.setState({ openSelectCustomerAccountWareHouse: false })}
              >
                Cancel
              </Button>,
            ]}>
            <Form
              name="select_customer_account_warehouse"
              layout="vertical"
              ref={this.formRef}
              onFinish={this.handleSelectCustomerAccount}
              scrollToFirstError
            >
              <Row>
                <Col md={24}>
                  <Form.Item
                    name="customer_account"
                    label="Customer account"
                    rules={[
                      {
                        required: true,
                        message: "Please select customer account name!",
                      },
                    ]}
                  >
                    <Select
                      allowClear={true}
                      onChange={this.fetchSelectCustomerWarehouseForCustomerAccount}
                    >
                      {customer_account.map((ca) => {
                        return (
                          <Option key={ca.id} value={ca.id}>
                            {ca.customer_account_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="warehouse"
                    label="Warehouse"
                    rules={[
                      {
                        required: true,
                        message: "Please select warehouse!",
                      },
                    ]}
                  >
                    <Select
                      allowClear={true}

                    >
                      {warehouse.map((opt) => {
                        return (
                          <Option value={opt.id}>{opt.location_name}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={24}>
                  <Form.Item>
                    <Button
                      type="info"
                      htmlType="submit"
                      size="medium"
                      loading={false}
                    >
                      Continue
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
          {/* Modal for selecting customer account and warehouse */}
          <Modal
            title="Select Customer Account"
            style={{ top: 20 }}
            visible={openSelectCustomerAccount}
            width={600}
            onCancel={() => this.setState({ openSelectCustomerAccount: false })}
            footer={[
              <Button
                key="cancel"
                type="default"
                loading=""
                onClick={() => this.setState({ openSelectCustomerAccount: false })}
              >
                Cancel
              </Button>,
            ]}>
            <Form
              name="select_customer_account_warehouse"
              layout="vertical"
              ref={this.formRefShip}
              onFinish={this.handleSelectShipProCustomerAccount}
              scrollToFirstError
            >
              <Row>
                <Col md={24}>
                  <Form.Item
                    name="customer_account"
                    label="Customer account"
                    rules={[
                      {
                        required: true,
                        message: "Please select customer account name!",
                      },
                    ]}
                  >
                    <Select
                      allowClear={true}

                    >
                      {customer_account.map((ca) => {
                        return (
                          <Option key={ca.id} value={ca.id}>
                            {ca.customer_account_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={24}>
                  <Form.Item>
                    <Button
                      type="info"
                      htmlType="submit"
                      size="medium"
                      loading={false}
                    >
                      Continue
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
          <Footer style={{ textAlign: "center", fontWeight: "600" }}>
            <span style={{ padding: "1px" }}>
              SG Pro tool by</span>
            <img src="https://shippro-backend.sisl.global/assets/logo/sisl-logo.png" className="img_footer" />
          </Footer>
        </Layout>
      </Layout>
    );
  }
}
export default withRouter(Headers);