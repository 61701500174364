import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import {
    Row,
    Col,
    Button,
    Form,
    Input,
    Divider,
    notification,
} from "antd";
import { token } from "../../Helper/Session";
import { withRouter } from "react-router-dom";

class AddSislEntity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            id: props.id,
            entity: "SISL"
        };
    }
    formRef = React.createRef();
    async componentDidMount() {
        await fetch(`${BASE_URL}${API.ENTITY_DETAIL}`, {
            method: "POST",
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({ id: this.state.id }),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        user: json.data,
                    });
                    this.formRef.current.setFieldsValue({
                        company_name: this.state.user.company_name,
                        address: this.state.user.address,
                        email: this.state.user.email,
                        contact_person: this.state.user.contact_person,
                        contact_person_phone: this.state.user.contact_person_phone,
                        contact_person_email: this.state.user.contact_person_email,
                    });
                }
                this.setState({
                    isLoading: false,
                });
            });
    }
    render() {
        const onFinish = (values) => {
            values.entity = this.state.entity;
            if (this.state.id) {
                values.id = this.state.id;
            }
            fetch(`${BASE_URL}${API.ADD_SISL_ENTITY}`, {
                method: "POST",
                headers: {
                    Accept: "application/form-data",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify(values),
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.status === true) {
                        notification.success({ message: json.success_msg });
                        this.props.history.push("/sisl-entity");
                    } else {
                        notification.error({ message: json.error_msg });
                    }
                });
        };
        return (
            <Headers
                bodyContain={
                    <>
                        <div className="site-layout-background form-design">
                            <h4>{(this.state.id) ? "Edit" : "Add"} SISL Entity</h4>
                            <Divider />
                            <Form
                                name="register"
                                layout="vertical"
                                onFinish={onFinish}
                                scrollToFirstError
                                ref={this.formRef}
                            >
                                <Row>
                                    <Col md={12}>
                                        <Form.Item
                                            name="company_name"
                                            label="Company Name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please input company name!",
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Item
                                            name="address"
                                            label="Company address"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter comapny address!",
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Item
                                            name="contact_person"
                                            label="Contact person"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter contact person!",
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Item
                                            name="contact_person_email"
                                            label="Contact Email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter contact person email!",
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Item
                                            name="contact_person_phone"
                                            label="Contact person phone"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter contact person phone!",
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col md={12}>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" size="large">
                                                Register
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </>
                }
            />
        );
    }
}
export default withRouter(AddSislEntity);
