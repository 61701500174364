// import React from "react";
import React, { createRef } from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import {
    Row,
    Col,
    Table,
    Button,
    Switch,
    notification,
    Modal,
    Select,
    Input,
} from "antd";
import { withRouter } from "react-router-dom";
import { token } from "../../Helper/Session";
import Form from "../../Helper/Form";

class Warehouse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            user_role: this.props.user_role,
            openAssignUserModal: false,
            selectedUserId: "",
            customer_account: [],
            isLoading: true
        };
    }

    goToAddWarehouse = () => {
        this.props.history.push("/warehouse/add");
    };
    formRef = React.createRef();
    componentDidMount() {
        var user_role = this.state.user_role;
        if (typeof user_role === "undefined" || user_role === null) {
            user_role = 0;
        }
        fetch(`${BASE_URL}${API.WAREHOUSE}?user_role=${user_role}`, {
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        users: json.data,
                    });
                    notification.success({ message: json.success_msg, placement: "top" });
                } else {
                    notification.error({ message: json.error_msg, placement: "top" });
                }
                this.setState({ isLoading: false })
            });
    }

    handleStatus = (user_id, v) => {
        if (v === true) {
            fetch(`${BASE_URL}${API.UPDATE_USER_STATUS}`, {
                method: "POST",
                headers: {
                    Accept: "application/form-data",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify({ status: 1, user_id: user_id }),
            });
        } else if (v === false) {
            fetch(`${BASE_URL}${API.UPDATE_USER_STATUS}`, {
                method: "POST",
                headers: {
                    Accept: "application/form-data",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify({ status: 0, user_id: user_id }),
            });
        }
    };

    handleAssignCustomerAccount = (value) => {
        var assign_data = {
            customer_account_id: value.name,
            user_id: this.state.selectedUserId,
        };
        fetch(`${BASE_URL}${API.ASSIGN_CUSTOMER_ACCOUNT}`, {
            method: "POST",
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(assign_data),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    notification.success({ message: json.success_msg, placement: "top" });
                    this.setState({ openAssignUserModal: false });
                    this.formRef.current.setFieldsValue({
                        name: [],
                    });
                } else {
                    notification.error({ message: json.error_msg, placement: "top" });
                }
            });
    };

    loadAssignUserAccount = (user_id) => {
        fetch(`${BASE_URL}${API.GET_ASSIGN_CUSTOMER_ACCOUNT}`, {
            method: "POST",
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({ user_id: user_id }),
        })
            .then((res) => res.json())
            .then((json) => {
                this.setState({ openAssignUserModal: true });
                if (json.status === true) {
                    this.formRef.current.setFieldsValue({
                        name: json.data,
                    });
                } else {
                    this.formRef.current.setFieldsValue({
                        name: [],
                    });
                }
            });
    };
    render() {
        const { users, openAssignUserModal, customer_account, isLoading } =
            this.state;
        // console.log(user);
        const { Option } = Select;
        var data = [];
        const columns = [
            {
                title: "SNo",
                dataIndex: "key",
            },
            {
                title: "Location name",
                dataIndex: "location_name",
            },
            {
                title: "Address",
                dataIndex: "address",
            },
            {
                title: "City",
                dataIndex: "city",
            },
            {
                title: "Country",
                dataIndex: "country",
            },
            {
                title: "State",
                dataIndex: "state",
            },
            {
                title: "Zip",
                dataIndex: "zip",
            },
            {
                title: "Action",
                dataIndex: "action",
            },
        ];
        users.map((usr, i) =>
            data.push({
                key: i + 1,
                location_name: usr["location_name"],
                address: usr["address"],
                city: usr["city"],
                country: usr["country"],
                state: usr["state"],
                zip: usr["zip"],
                status: (
                    <Switch
                        size="default"
                        checkedChildren="Active"
                        unCheckedChildren="InActive"
                        defaultChecked={usr["status"] == 1 ? true : false}
                        onChange={this.handleStatus.bind(this, usr["id"])}
                        style={{
                            width: "80px",
                        }}
                    />
                ),
                action: (
                    <div>
                        <Button
                            type="warning"
                            size="medium"
                            onClick={() =>
                                this.props.history.push(
                                    "/warehouse/edit/" + usr["id"]
                                )
                            }
                        >
                            Edit
                        </Button>
                    </div>
                ),
            })
        );

        return (
            <Headers
                bodyContain={
                    <>
                        <Row>
                            <Col offset={20} md={2}>
                                <Button
                                    onClick={this.goToAddWarehouse.bind(this)}
                                    type="primary"
                                    style={{ marginBottom: "10px" }}
                                    shape="round"
                                >
                                    Add Warehouse
                                </Button>
                            </Col>
                        </Row>
                        <div className="site-layout-background">
                            <Row>
                                <Col md={24}>
                                    <Table
                                        columns={columns}
                                        dataSource={data}
                                        size="middle"
                                        bordered
                                        loading={isLoading}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <Modal
                            title="Assign customer account"
                            style={{ top: 20 }}
                            visible={openAssignUserModal}
                            width={600}
                            onCancel={() => {
                                this.setState({
                                    openAssignUserModal: false,
                                });
                            }}
                            footer={[
                                <Button
                                    key="cancel"
                                    type="default"
                                    loading=""
                                    onClick={() => this.setState({ openAssignUserModal: false })}
                                >
                                    Cancel
                                </Button>,
                            ]}
                        >
                            <div className="form-design">
                                <Form
                                    name="assign_customer_account"
                                    layout="vertical"
                                    onFinish={this.handleAssignCustomerAccount}
                                    scrollToFirstError
                                    ref={this.formRef}
                                >
                                    <Row>
                                        <Col md={24}>
                                            <Form.Item
                                                name="name"
                                                label="Customer account name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select customer account name!",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    allowClear={true}
                                                    mode="multiple"
                                                    size="large"
                                                    // value={selectedValues}
                                                    autoClearSearchValue={true}
                                                    filterOption={(input, option) =>
                                                        option.props.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0 ||
                                                        option.props.value
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {customer_account.map((ca) => {
                                                        return (
                                                            <Option key={ca.id} value={ca.id}>
                                                                {ca.customer_account_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={24}>
                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    size="large"
                                                    loading={false}
                                                >
                                                    Save Items
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Modal>
                    </>
                }
            />
        );
    }
}
export default withRouter(Warehouse);
