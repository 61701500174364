import React, { createRef } from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import {
  Row,
  Col,
  Table,
  Button,
  Switch,
  notification,
  Modal,
  Select,
  Tag,
  Card
} from "antd";
import { FolderAddOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { token } from "../../Helper/Session";
import Form from "../../Helper/Form";

class CustomerUserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      user_role: this.props.user_role,
      openAssignUserModal: false,
      selectedUserId: "",
      customer_account: [],
      warehouse: [],
      openAssignWareHouseModal: false,
      isLoading: true
    };
  }

  goToAddUser = () => {
    this.props.history.push("/add-user");
  };
  formRef = React.createRef();
  componentDidMount() {
    var user_role = this.state.user_role;
    if (typeof user_role === "undefined" || user_role === null) {
      user_role = 0;
    }
    fetch(`${BASE_URL}${API.USERLISTING}?user_role=${user_role}`, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            users: json.data,
          });
          notification.success({ message: json.success_msg, placement: "top" });
        } else {
          notification.error({ message: json.error_msg, placement: "top" });
        }
        this.setState({ isLoading: false })
      });
  }

  handleStatus = (user_id, v) => {
    if (v === true) {
      fetch(`${BASE_URL}${API.UPDATE_USER_STATUS}`, {
        method: "POST",
        headers: {
          Accept: "application/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ status: 1, user_id: user_id }),
      });
    } else if (v === false) {
      fetch(`${BASE_URL}${API.UPDATE_USER_STATUS}`, {
        method: "POST",
        headers: {
          Accept: "application/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ status: 0, user_id: user_id }),
      });
    }
  };

  handleAssignCustomerAccount = (value) => {
    var assign_data = {
      customer_account_id: value.name,
      user_id: this.state.selectedUserId,
    };
    fetch(`${BASE_URL}${API.ASSIGN_CUSTOMER_ACCOUNT}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(assign_data),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          notification.success({ message: json.success_msg, placement: "top" });
          this.setState({ openAssignUserModal: false });
          this.formRef.current.setFieldsValue({
            name: [],
          });
        } else {
          notification.error({ message: json.error_msg, placement: "top" });
        }
      });
  };

  handleAssignWareHouse = (value) => {
    var assign_data = {
      warehouse_id: value.warehouse_id,
      user_id: this.state.selectedUserId,
    };
    fetch(`${BASE_URL}${API.ASSIGN_WAREHOUSE}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(assign_data),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          notification.success({ message: json.success_msg, placement: "top" });
          this.setState({ openAssignWareHouseModal: false });
          this.formRef.current.setFieldsValue({
            warehouse_id: [],
          });
          this.componentDidMount();
        } else {
          notification.error({ message: json.error_msg, placement: "top" });
        }
      });
  };

  loadAssignWareHouse = (user_id) => {
    this.loadWarehouseForUser(user_id);
    fetch(`${BASE_URL}${API.GET_ASSIGN_WAREHOUSE}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ user_id: user_id }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ loadAssignUserAccount: true });
        if (json.status == true) {
          this.formRef.current.setFieldsValue({
            warehouse_id: json.data,
          });
        } else {
          this.formRef.current.setFieldsValue({
            warehouse_id: []
          });
        }
      });
  };

  loadWarehouseForUser = (user_id) => {
    fetch(`${BASE_URL}${API.WAREHOUSE_FOR_CUSTOMER_USER}`, {
      method: 'post',
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ user_id: user_id })
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            warehouse: json.data,
          });
        } else {
          this.setState({
            warehouse: []
          })
          notification.error({ message: 'Fail', description: json.error_msg, placement: 'top' })
        }
      });
  }
  render() {
    const { users, openAssignUserModal, customer_account, openAssignWareHouseModal, warehouse, isLoading } =
      this.state;
    // console.log(user);
    const { Option } = Select;
    var data = [];
    const columns = [
      {
        title: "SNo",
        dataIndex: "key",
      },
      {
        title: "User detail",
        dataIndex: "name",
      },
      {
        title: "Customer Account",
        dataIndex: "customer_account",
        render: (_, { customer_account }) => (
          <>
            {customer_account.map((customer_account) => {
              return (
                <Tag color="geekblue" key={customer_account}>
                  {customer_account.toUpperCase()}
                </Tag>
              );
            })}
          </>
        ),
      },
      {
        title: "Warehouse",
        dataIndex: "location",
        render: (_, { location }) => (
          <>
            {location.map((location) => {
              return (
                <Tag color="geekblue" key={location}>
                  {location.toUpperCase()}
                </Tag>
              );
            })}
          </>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
      },
      {
        title: "Action",
        dataIndex: "action",
      },
    ];

    users.map((usr, i) =>
      data.push({
        key: i + 1,
        name: (
          <Card className="user_detail">
            <p>{usr["first_name"] + " " + usr["last_name"]}</p>
            <p>{usr['email']}</p>
            <p>{usr['mobile']}</p>
          </Card>
        ),
        customer_account: (usr['customer_account']) ? usr['customer_account'].split(",") : [],
        location: (usr['warehouse']) ? usr['warehouse'].split(",") : [],
        status: (
          <Switch
            size="small"
            checkedChildren="Active"
            unCheckedChildren="InActive"
            defaultChecked={usr["status"] == 1 ? true : false}
            onChange={this.handleStatus.bind(this, usr["id"])}
            style={{
              width: "80px",
            }}
          />
        ),
        action: (
          <div>
            <Button
              type="warning"
              size="small"
              onClick={() =>
                this.props.history.push(
                  "/edit-customer-user/?user_id=" + usr["id"]
                )
              }
            >
              Edit
            </Button>
            <Button
              type="primary"
              size="small"
              title="Assign Warehouse"
              icon={<FolderAddOutlined />}
              onClick={() => {
                this.setState({
                  openAssignWareHouseModal: true,
                  selectedUserId: usr["id"]
                });
                this.loadAssignWareHouse(usr['id'])
              }
              }
            >

            </Button>
          </div>
        ),
      })
    );

    return (
      <Headers
        bodyContain={
          <>
            <Row>
              <Col offset={21} md={2}>
                <Button
                  onClick={this.goToAddUser.bind(this)}
                  type="primary"
                  style={{ marginBottom: "10px" }}
                  shape="round"
                >
                  Add User
                </Button>
              </Col>
            </Row>
            <div className="site-layout-background">
              <Row>
                <Col md={24}>
                  <Table
                    columns={columns}
                    dataSource={data}
                    size="small"
                    bordered
                    loading={isLoading}
                  />
                </Col>
              </Row>
            </div>
            <Modal
              title="Assign customer account"
              style={{ top: 20 }}
              visible={openAssignUserModal}
              width={600}
              onCancel={() => {
                this.setState({
                  openAssignUserModal: false,
                });
              }}
              footer={[
                <Button
                  key="cancel"
                  type="default"
                  loading=""
                  onClick={() => this.setState({ openAssignUserModal: false })}
                >
                  Cancel
                </Button>,
              ]}
            >
              <div className="form-design">
                <Form
                  name="assign_customer_account"
                  layout="vertical"
                  onFinish={this.handleAssignCustomerAccount}
                  scrollToFirstError
                  ref={this.formRef}
                >
                  <Row>
                    <Col md={24}>
                      <Form.Item
                        name="name"
                        label="Customer account name"
                        rules={[
                          {
                            required: true,
                            message: "Please select customer account name!",
                          },
                        ]}
                      >
                        <Select
                          allowClear={true}
                          mode="multiple"
                          size="large"
                          // value={selectedValues}
                          autoClearSearchValue={true}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0 ||
                            option.props.value
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {customer_account.map((ca) => {
                            return (
                              <Option key={ca.id} value={ca.id}>
                                {ca.customer_account_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={24}>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="large"
                          loading={false}
                        >
                          Save Items
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Modal>
            <Modal
              title="Assign Warehouse"
              style={{
                top: 20,
              }}
              visible={openAssignWareHouseModal}
              width={600}
              onCancel={() => {
                this.setState({
                  openAssignWareHouseModal: false,
                });
              }}
              footer={[
                <Button
                  key="cancel"
                  type="default"
                  loading=""
                  onClick={() => this.setState({ openAssignWareHouseModal: false })}
                >
                  Cancel
                </Button>,
              ]}
            >
              <Form
                name="assign_customer_account"
                layout="vertical"
                onFinish={this.handleAssignWareHouse}
                scrollToFirstError
                ref={this.formRef}
              >
                <Row>
                  <Col md={24}>
                    <Form.Item
                      name="warehouse_id"
                      label="Select Warehouse"
                      rules={[
                        {
                          required: true,
                          message: "Please select warehouse location!",
                        },
                      ]}
                    >
                      <Select
                        allowClear={true}
                        mode="multiple"
                        size="large"
                        // value={selectedValues}
                        autoClearSearchValue={true}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                          option.props.value
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {warehouse.map((wh) => {
                          return (
                            <Option key={wh.id} value={wh.id}>
                              {wh.location_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col md={24}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="medium"
                        loading={false}
                      >
                        Save Warehouse
                      </Button>
                    </Form.Item>
                  </Col>

                </Row>
              </Form>
            </Modal>
          </>
        }
      />
    );
  }
}
export default withRouter(CustomerUserList);
