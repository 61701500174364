import React from "react";
import "../style/login.css";
import * as antd from "antd";
import { Link } from "react-router-dom";
import { BASE_URL, API } from "../Endpoint";
import { notification } from "antd";
import { useState } from "react";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);

  var getSession = localStorage.getItem("authenticated");
  const parseSession = JSON.parse(getSession);
  
  if (parseSession != null) {
    window.location.href = "/dashboard";
  }

  const onFinish = (values) => {
    setIsLoading(true);
    fetch(`${BASE_URL}${API.LOGIN}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((json) => {
        setIsLoading(false);
        if (json.status === true) {
          notification.success({ message: json.success_msg });
          localStorage.setItem("authenticated", JSON.stringify(json.data));
          window.location.href = "/dashboard";
        } else {
          notification.error({ message: json.error_msg });
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="login-page">
      <div className="login-box">
        <div className="illustration-wrapper">
          <img src="image/logo.png" alt="Login" />
        </div>
        <antd.Form
          name="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <p className="form-title">Welcome back</p>
          <p>Login to the Dashboard</p>
          <antd.Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <antd.Input placeholder="Email Id" />
          </antd.Form.Item>

          <antd.Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <antd.Input.Password placeholder="Password" />
          </antd.Form.Item>
          {/* <div style={{ paddingBottom: "10px" }}>
            <Link to="#" className="secondary-font-color">
              Forget Password?
            </Link>
          </div> */}
          <antd.Form.Item>
            <antd.Button
              type="secondary"
              htmlType="submit"
              className="login-form-button"
              loading={isLoading}
            >
              LOGIN
            </antd.Button>
          </antd.Form.Item>
        </antd.Form>
      </div>
    </div>
  );
};

export default Login;
