import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import {
    Row,
    Col,
    Button,
    Form,
    Input,
    Select,
    Divider,
    notification,
    Drawer,
    Card,
    Table,
    Tag,
    Breadcrumb
} from "antd";
import { token } from "../../Helper/Session";
import { withRouter } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import { AppstoreAddOutlined } from '@ant-design/icons';
import "antd/dist/antd.css";
import warehouse from "../warehouse";

class Products extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product_detail: [],
            category: [],
            products: [],
            warehouse: [],
            id: props.id,
            drawerVisible: false,
            visibleStock: "none",
            product_id: 0,
            isTableLoading: false,
            warehouse_id: props.warehouse_id,
            client_id: props.client_id,
            breadcrumb: [],
            customer_entity: [],
            product_sku: ""
        };
    }
    formRef = React.createRef();

    addProduct = () => {
        this.setState({ drawerVisible: true, visibleStock: "none", product_id: 0 });
    };

    viewProduct = (sku, id) => {
        const dta = {
            product_id: sku,
        }
        this.setState({ drawerVisible: true, product_id: id, product_sku: sku });
        fetch(`${BASE_URL}${API.PRODUCT_DETAIL}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dta),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        product_detail: json.data,
                        confirm_stock: json.data.confirm_stock,
                        cart_in_stock: json.data.cart_in_stock,
                        visibleStock: "block",
                    }, () => {
                        this.formRef.current.setFieldsValue({
                            product_name: this.state.product_detail.product_name,
                            sku: this.state.product_detail.sku,
                            product_type: this.state.product_detail.product_type,
                            category: this.state.product_detail.category,
                            description: this.state.product_detail.description,
                            customer_entity: this.state.product_detail.customer_entity
                        })
                    });
                }
            });
    }

    componentDidMount() {
        fetch(`${BASE_URL}${API.CATEGORY_LIST}`, {
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        category: json.data,
                    });
                }
            });
        this.handleProductList();
        this.loadCustomerEntity();
        fetch(`${BASE_URL}${API.GET_Breadcrumb_warehouse_client}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({ warehouse_id: this.state.warehouse_id, client_id: this.state.client_id })
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        breadcrumb: json.data,
                    });
                } else {
                    notification.error({ message: json.error_msg });
                    this.props.history.push("/");
                }
            });
    }

    loadCustomerEntity = () => {
        fetch(`${BASE_URL}${API.CUSTOMER_ENTIIY_FOR_CUSTOMER_ACCOUNT}?customer_account=` + this.state.client_id, {
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        customer_entity: json.data,
                    });
                }
                this.setState({
                    isLoading: false
                })
            });
    }

    handleProductList = (value) => {
        this.setState({
            isTableLoading: true
        })
        fetch(`${BASE_URL}${API.PRODUCT_LIST}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({ warehouse_id: this.state.warehouse_id, client_id: this.state.client_id })
        })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    isTableLoading: false
                })
                if (json.status === true) {
                    this.setState({
                        products: json.data,
                    });
                } else {
                    notification.error({ message: json.error_msg });
                }
            });
    }



    render() {
        const { category, products, visibleStock, product_detail, isTableLoading, breadcrumb, warehouse_id, customer_entity } = this.state;
        const { Option } = Select;
        var data = [];
        const columns = [
            {
                title: "SNo",
                dataIndex: "key",
            },
            {
                title: "Product",
                dataIndex: "product",
            },
            {
                title: "SKU",
                dataIndex: "sku",
            },
            {
                title: "Category",
                dataIndex: "category",
            },

            {
                title: "Product type",
                dataIndex: "type",
            },

            // {
            //     title: "Action",
            //     dataIndex: "action",
            // },
        ];
        products.map((pro, i) =>
            data.push({
                key: i + 1,
                product: (<a href="javascript:void(0);" onClick={() => this.viewProduct(pro['sku'], pro['id'])}>{pro["product_name"]}</a>),
                category: (<Tag color="geekblue">{pro["category"]}</Tag>),
                quantity: (<Tag color={pro["quantity"] < 4 ? "red" : "#108ee9"} style={{ fontSize: "17px", padding: "5px 10px" }}>{pro["quantity"]}</Tag>),
                sku: (<Tag color="green">{pro.entity + "-" + pro.sku}</Tag>),
                type: (<Tag color="gold">{(pro.product_type == 1) ? "Stocked" : ((pro.product_type == 2) ? "Serialized" : '')}</Tag>)
            })
        );

        const onFinish = (values) => {
            // console.log(values);
            const sub_data = {
                product_name: values.product_name,
                sku: values.sku,
                category: values.category,
                product_type: values.product_type,
                description: values.description,
                warehouse_id: warehouse_id,
                customer_account_id: this.state.client_id,
                customer_entity: values.customer_entity
            };
            if (this.state.product_id != 0) {
                sub_data.id = this.state.product_id
            }
            fetch(`${BASE_URL}${API.ADD_PRODUCT}`, {
                method: "POST",
                headers: {
                    Accept: "application/form-data",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: JSON.stringify(sub_data),
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.status === true) {
                        notification.success({ message: json.success_msg });
                        this.handleProductList();
                        this.setState({ drawerVisible: false, product_id: 0 });
                    } else {
                        notification.error({ message: json.error_msg });
                    }
                });
        };
        return (
            <Headers
                bodyContain={
                    <>
                        <Row>
                            <Col md={20}>
                                <Breadcrumb>
                                    <Breadcrumb.Item>WareHouse</Breadcrumb.Item>
                                    <Breadcrumb.Item><a href={"/warehouse/clients/" + warehouse_id}>{breadcrumb.warehouse}</a></Breadcrumb.Item>
                                    <Breadcrumb.Item>{breadcrumb.customer}</Breadcrumb.Item>
                                    <Breadcrumb.Item>Products</Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                            <Col>
                                <Button
                                    onClick={this.addProduct}
                                    type="primary"
                                    style={{ marginBottom: "10px" }}
                                    shape="round"
                                >
                                    Add Product
                                </Button>
                            </Col>
                        </Row>
                        <div className="site-layout-background form-design">
                            <Row>
                                <Col md={24}>
                                    <Table
                                        columns={columns}
                                        dataSource={data}
                                        size="small"
                                        bordered
                                        loading={isTableLoading}
                                        pagination={{ pageSize: 50 }}
                                    />
                                </Col>
                            </Row>
                            <Divider />
                            <Drawer title="Add Product" placement="right" closable={true}
                                mask={true}
                                width={750}
                                visible={this.state.drawerVisible}
                                onClose={() => { this.setState({ drawerVisible: false, isTableLoading: true }); this.handleProductList(); }}
                                destroyOnClose>
                                {/* <Card> */}
                                <Form
                                    name="register"
                                    layout="vertical"
                                    onFinish={onFinish}
                                    scrollToFirstError
                                    ref={this.formRef}
                                    className="add_product"
                                >
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Item
                                                name="product_name"
                                                label="Product Name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter product name!",
                                                        whitespace: true,
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>

                                        <Col sm={6}>
                                            <Form.Item
                                                name="sku"
                                                label="SKU"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter SKU!",
                                                        whitespace: true,
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col sm={6}>

                                            <Form.Item
                                                name="category"
                                                label="Category"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select category!",
                                                    },
                                                ]}
                                            >
                                                <Select placeholder="Select category">
                                                    {category.map((opt, i) => {
                                                        return <Option value={opt.id}>{opt.name}</Option>;
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col sm={12}>
                                            <Form.Item
                                                name="product_type"
                                                label="Product type"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please choose product type.",
                                                    },
                                                ]}
                                            >
                                                <Select placeholder="Select Product type...">
                                                    <Option value="">Select Product</Option>
                                                    <Option value={"1"}>Stocked Product</Option>
                                                    <Option value={"2"}>Serialized Product</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col sm={12}>
                                            <Form.Item
                                                name="customer_entity"
                                                label="Customer Entity"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please choose customer entity.",
                                                    },
                                                ]}
                                            >
                                                <Select placeholder="Select Customer entity...">
                                                    {customer_entity.map((opt) => {
                                                        return <Option value={opt.id}>{opt.company_name}</Option>;
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col sm={24}>
                                            <Form.Item
                                                name="description"
                                                label="Description"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter description!",
                                                        whitespace: true,
                                                    },
                                                ]}
                                            >
                                                <TextArea />
                                            </Form.Item>
                                        </Col>


                                    </Row>

                                    <Row>
                                        <Col sm={24}>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit" size="meduim" icon={<AppstoreAddOutlined />}>
                                                    Save
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                                {/* </Card> */}
                                <Divider />
                                {/* <Row style={{ display: visibleStock }}>
                                    <Col md={12}>
                                        <div className="stock_box">
                                            <p>Quantity breakdown</p>
                                            <div className="quantity">
                                                <Row>
                                                    <Col sm={18}>
                                                        On hand
                                                    </Col>
                                                    <Col sm={6}>
                                                        {product_detail.onhand}
                                                    </Col>
                                                    <Col sm={18}>
                                                        Reserved
                                                    </Col>
                                                    <Col sm={6}>
                                                        0
                                                    </Col>
                                                    <Col sm={18}>
                                                        Available
                                                    </Col>
                                                    <Col sm={6}>
                                                        {product_detail.available}
                                                    </Col>
                                                    <Col sm={18}>
                                                        Shipped
                                                    </Col>
                                                    <Col sm={6}>
                                                        {(product_detail.shipped) ? product_detail.shipped : 0}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                </Row> */}
                            </Drawer>
                        </div>

                    </>
                }
            />
        );
    }
}
export default withRouter(Products);
