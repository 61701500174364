import React from "react";
import Headers from "../component/Headers";
import {
  Button,
  Divider,
  Tabs,
  Table,
  Switch,
  Spin,
  Row,
  Col,
  Input,
  Modal,
  notification,
  Menu,
  Dropdown,
  Popconfirm,
} from "antd";
import { token } from "../../Helper/Session";
import { API, BASE_URL } from "../../Endpoint";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import Form from "../../Helper/Form";

const { TabPane } = Tabs;

class EditCustomerAccount extends React.Component {
  constructor(props) {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("user_id");
    if (id == null) {
      props.history.push("/customer-account");
    }
    // console.log(props.form);
    super(props);
    this.state = {
      address: [],
      item: [],
      user: {},
      user_id: id,
      isLoadedAddress: true,
      isLoadSaveAddress: false,
      isLoadItem: true,
      isLoadSaveItem: false,
      idFormLoading: true,
      isItemModalVisible: false,
      isEditAddresssModalVisible: false,
      address_detail_id: "",
      item_detail_id: "",
      isEditItemModalVisible: false,
      setConfirmLoading: false,
    };
  }
  formRef = React.createRef();
  EditAddrForm = React.createRef();
  refItemForm = React.createRef();
  async componentDidMount() {
    // console.log(token);
    await fetch(`${BASE_URL}${API.CUSTOMER_ACCOUNT_DETAIL}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ user_id: this.state.user_id }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            user: json.data,
          });
          this.formRef.current.setFieldsValue({
            customer_account_name: this.state.user.customer_account_name,
            nickname: this.state.user.nickname,
          });
        }
        this.setState({
          isLoading: false,
          idFormLoading: false,
        });
      });
  }

  loadAddress = (key) => {
    const user_id = this.state.user_id;
    if (key == 2 && this.state.isLoadedAddress) {
      this.setState({
        isLoadedAddress: false,
      });
      fetch(`${BASE_URL}${API.ADDRESS_LIST}`, {
        method: "POST",
        headers: {
          Accept: "application/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ customer_account_id: user_id }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.status === true) {
            notification.success({ message: json.success_msg });
            this.setState({
              address: json.data,
            });
          } else {
            notification({ message: json.error_msg });
          }
        });
    } else if (key == 3 && this.state.isLoadItem) {
      this.setState({
        isLoadItem: false,
      });
      fetch(`${BASE_URL}${API.ITEM_LIST}`, {
        method: "POST",
        headers: {
          Accept: "application/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ user_id: user_id }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.status === true) {
            notification.success({ message: json.success_msg });
            this.setState({
              item: json.data,
            });
          } else {
            notification.error({ message: json.error_msg });
          }
        });
    }
  };

  onFinish = (values) => {
    const sub_data = {
      customer_account_name: values.customer_account_name,
      nickname: values.nickname,
      user_id: this.state.user_id,
    };
    this.setState({ idFormLoading: true });
    fetch(`${BASE_URL}${API.UPDATE_CUSTOMER_ACCOUNT}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(sub_data),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isLoading: false });
        if (json.status === true) {
          notification.success({ message: json.success_msg });
          this.setState({ idFormLoading: false });
        } else {
          notification.error({ message: json.error_msg });
        }
      });
  };

  openAddressModal = (id) => {
    fetch(`${BASE_URL}${API.ADDRESS_LIST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ customer_account_id: this.state.user_id, id: id }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            address_detail_id: json.data.id,
          });
          this.EditAddrForm.current.setFieldsValue({
            company_name: json.data.company_name,
            contact_name: json.data.contact_name,
            email: json.data.email,
            mobile: json.data.mobile,
            street: json.data.street,
            city: json.data.city,
            postal: json.data.postal,
            state: json.data.state,
            country: json.data.country,
          });
        }
      });
    this.setState({ isEditAddresssModalVisible: true });
  };

  openItemsModal = (id) => {
    fetch(`${BASE_URL}${API.ITEM_DETAIL}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ user_id: this.state.user_id, item_id: id }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            item_detail_id: json.data.id,
          });
          this.refItemForm.current.setFieldsValue({
            name: json.data.name,
            description: json.data.description,
            weight: json.data.weight,
            length: json.data.length,
            width: json.data.width,
            height: json.data.height,
          });
        }
      });
    this.setState({ isEditItemModalVisible: true });
  };

  handleModal = () => {
    this.setState({ isModalVisible: true });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleOk = () => {
    this.setState({ isLoading: true });
  };

  handleSaveAddress = (values) => {
    this.setState({
      isLoadSaveAddress: true,
    });
    values.customer_account_id = this.state.user_id;
    fetch(`${BASE_URL}${API.ADD_ADDRESS}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          isLoadSaveAddress: false,
        });
        if (json.status === true) {
          this.setState({
            isLoadedAddress: true,
            isModalVisible: false,
          });
          this.loadAddress(2);
          notification.success({ message: json.success_msg });
        } else {
          notification.error({ message: json.error_msg });
        }
      });
  };

  handleEditSaveAddress = (values) => {
    this.setState({
      isLoadSaveAddress: true,
    });
    values.customer_account_id = this.state.user_id;
    values.id = this.state.address_detail_id;
    fetch(`${BASE_URL}${API.ADD_ADDRESS}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          isLoadSaveAddress: false,
        });
        if (json.status === true) {
          this.setState({
            isLoadedAddress: true,
            isEditAddresssModalVisible: false,
          });
          this.loadAddress(2);
          notification.success({ message: json.success_msg });
        } else {
          notification.error({ message: json.error_msg });
        }
      });
  };

  handleSaveItem = (values) => {
    this.setState({ isLoadSaveItem: true });
    values.user_id = this.state.user_id;
    fetch(`${BASE_URL}${API.ADD_ITEMS}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isLoadSaveItem: false });
        if (json.status === true) {
          this.setState({
            isLoadItem: true,
            isItemModalVisible: false,
          });
          this.loadAddress(3);
          notification.success({ message: json.success_msg });
        } else {
          notification.error({ message: json.error_msg });
        }
      });
  };

  deleteItem = (id) => {
    this.setState({
      setConfirmLoading: true,
    });
    fetch(`${BASE_URL}${API.DELETE_PROPERTY}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ id: id, module: "ITEM" }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            isLoadItem: true,
            setConfirmLoading: false,
          });
          this.loadAddress(3);
          notification.success({ message: json.success_msg });
        } else {
          notification.error({ message: json.error_msg });
        }
      });
  };

  handleEditSaveItem = (values) => {
    this.setState({ isLoadSaveItem: true });
    values.user_id = this.state.user_id;
    values.id = this.state.item_detail_id;
    fetch(`${BASE_URL}${API.ADD_ITEMS}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isLoadSaveItem: false });
        if (json.status === true) {
          this.setState({
            isLoadItem: true,
            isEditItemModalVisible: false,
          });
          this.loadAddress(3);
          notification.success({ message: json.success_msg });
        } else {
          notification.error({ message: json.error_msg });
        }
      });
  };
  render() {
    const {
      address,
      item,
      user,
      idFormLoading,
      isModalVisible,
      isItemModalVisible,
      isLoadSaveAddress,
      isLoadSaveItem,
      isEditAddresssModalVisible,
      isEditItemModalVisible,
      setConfirmLoading,
    } = this.state;
    var data = [];
    var data_item = [];
    const columns = [
      {
        title: "SNo",
        dataIndex: "key",
      },
      {
        title: "Company Name",
        dataIndex: "company_name",
      },
      {
        title: "Contact Name",
        dataIndex: "contact_name",
      },
      {
        title: "Contact Number",
        dataIndex: "mobile",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Street",
        dataIndex: "street",
      },
      {
        title: "City",
        dataIndex: "city",
      },
      {
        title: "State",
        dataIndex: "state",
      },
      {
        title: "Country",
        dataIndex: "country",
      },
      {
        title: "Action",
        dataIndex: "action",
      },
    ];
    const columns_item = [
      {
        title: "Sno",
        dataIndex: "key",
      },
      {
        title: "Item name",
        dataIndex: "name",
      },
      {
        title: "Weight(kg)",
        dataIndex: "weight",
      },
      {
        title: "Length(cm)",
        dataIndex: "length",
      },
      {
        title: "Width(cm)",
        dataIndex: "width",
      },
      {
        title: "Height(cm)",
        dataIndex: "height",
      },
      // {
      //   title: "Contents ",
      //   dataIndex: "content_description",
      // },
      // {
      //   title: "Contents value",
      //   dataIndex: "content_value",
      // },

      {
        title: "Description",
        dataIndex: "description",
      },
      {
        title: "Action",
        dataIndex: "action",
      },
    ];

    address.map((addr, i) => {
      if (addr.is_warehouse == 0) {
        data.push({
          key: i + 1,
          company_name: addr["company_name"],
          contact_name: addr["contact_name"],
          mobile: addr["mobile"],
          email: addr["email"],
          street: addr["street"],
          city: addr["city"],
          state: addr["state"],
          country: addr["country"],
          action: (
            <Button
              type="primary"
              size="small"
              style={{ background: "#ffc107", border: "none" }}
              onClick={() => this.openAddressModal(addr["id"])}
            >
              Edit
            </Button>
          ),
        })
      }
    });
    item.map((itm, i) =>
      data_item.push({
        key: i + 1,
        name: itm["name"],
        weight: itm["weight"],
        width: itm["width"],
        height: itm["height"],
        length: itm["length"],
        // content_value: itm["content_value"],
        // content_description: itm["content_description"],
        description: itm["description"],
        action: (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    key: "2",
                    label: (
                      <Popconfirm
                        title="Are you sure, you want to delete this Item?"
                        open={true}
                        onConfirm={() => this.deleteItem(itm["id"])}
                        okButtonProps={{
                          loading: setConfirmLoading,
                        }}
                      >
                        {/* <Button type="primary" onClick={showPopconfirm}>
                        Open Popconfirm with async logic
                      </Button> */}
                        <Button
                          type="danger"
                          // className="actionButton"
                          icon={<DeleteOutlined />}
                        // onClick={() => this.deleteItem(itm["id"])}
                        ></Button>
                      </Popconfirm>
                    ),
                  },
                ]}
              />
            }
            type="warning"
            placement="right"
            onClick={() => this.openItemsModal(itm["id"])}
          >
            Edit
          </Dropdown.Button>
        ),
      })
    );
    return (
      <Headers
        bodyContain={
          <>
            <div className="site-layout-background form-design">
              <h3>Edit Customer Account Detail</h3>
              <Divider />
              <Tabs tabBarExtraContent={""} onTabClick={this.loadAddress}>
                <TabPane tab="User Detail" key="1">
                  {/* user detail */}
                  <Spin spinning={idFormLoading}>
                    <Form
                      ref={this.formRef}
                      name="register"
                      layout="vertical"
                      onFinish={this.onFinish}
                      scrollToFirstError
                    >
                      <Row>
                        <Col md={12}>
                          <Form.Item
                            name="customer_account_name"
                            label="Customer Account Name"
                            rules={[
                              {
                                required: true,
                                message: "Please input customer account name!",
                                whitespace: true,
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col md={12}>
                          <Form.Item
                            name="nickname"
                            label="Customer Account Short Name"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please input Customer Account Short Name!",
                                whitespace: true,
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              size="middle"
                            >
                              Save
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Spin>
                </TabPane>
                <TabPane tab="Customer Frequent Address" key="2">
                  {/* address list */}
                  <Row>
                    <Col offset={21}>
                      <Button
                        type="secondary"
                        style={{ marginBottom: "10px" }}
                        onClick={this.handleModal}
                      >
                        Add Address
                      </Button>
                    </Col>
                    <Col md={24}>
                      <Table
                        columns={columns}
                        dataSource={data}
                        size="middle"
                        bordered
                        loading="false"
                      />
                    </Col>
                  </Row>
                </TabPane>
                {/* <TabPane tab="Items" key="3">
                  <Row>
                    <Col offset={21}>
                      <Button
                        type="secondary"
                        style={{ marginBottom: "10px" }}
                        onClick={() =>
                          this.setState({ isItemModalVisible: true })
                        }
                      >
                        Add item
                      </Button>
                    </Col>
                    <Col md={24}>
                      <Table
                        columns={columns_item}
                        dataSource={data_item}
                        size="middle"
                        bordered
                        loading="false"
                      />
                    </Col>
                  </Row>
                </TabPane> */}
              </Tabs>
            </div>
            <Modal
              title="Add major addresses"
              style={{ top: 20 }}
              visible={isModalVisible}
              width={790}
              onCancel={() => {
                this.setState({ isModalVisible: false });
              }}
              destroyOnClose={true}
              footer={[
                <Button
                  key="cancel"
                  type="default"
                  loading=""
                  onClick={this.handleCancel}
                >
                  Cancel
                </Button>,
              ]}
            >
              <div className="form-design">
                <Form
                  ref={this.addrForm}
                  name="register"
                  layout="vertical"
                  onFinish={this.handleSaveAddress}
                  scrollToFirstError
                >
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        name="company_name"
                        label="Company Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter company name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="contact_name"
                        label="Contact Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter contact name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                          {
                            required: false,
                            message: "Please enter email!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="mobile"
                        label="Contact number"
                        rules={[
                          {
                            required: false,
                            message: "Please enter contact mobile number!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="street"
                        label="Street"
                        rules={[
                          {
                            required: true,
                            message: "Please enter street address!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col md={12}>
                      <Form.Item
                        name="city"
                        label="City"
                        rules={[
                          {
                            required: true,
                            message: "Please enter city",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col md={12}>
                      <Form.Item
                        name="postal"
                        label="Postal"
                        rules={[
                          {
                            required: false,
                            message: "Please enter postal",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col md={12}>
                      <Form.Item
                        name="state"
                        label="State"
                        rules={[
                          {
                            required: true,
                            message: "Please enter state",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col md={12}>
                      <Form.Item
                        name="country"
                        label="Country"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Country",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="middle"
                          loading={isLoadSaveAddress}
                        >
                          Save Address
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Modal>

            {/* Edit Address */}
            <Modal
              title="Edit major addresses"
              style={{ top: 20 }}
              visible={isEditAddresssModalVisible}
              width={790}
              onCancel={() => {
                this.setState({ isEditAddresssModalVisible: false });
              }}
              destroyOnClose={true}
              footer={[
                <Button
                  key="cancel"
                  type="default"
                  loading=""
                  onClick={() => {
                    this.setState({ isEditAddresssModalVisible: false });
                  }}
                >
                  Cancel
                </Button>,
              ]}
            >
              <div className="form-design">
                <Form
                  ref={this.EditAddrForm}
                  name="register"
                  layout="vertical"
                  onFinish={this.handleEditSaveAddress}
                  scrollToFirstError
                >
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        name="company_name"
                        label="Company Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter company name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="contact_name"
                        label="Contact Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter contact name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                          {
                            required: false,
                            message: "Please enter email!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="mobile"
                        label="Contact number"
                        rules={[
                          {
                            required: false,
                            message: "Please enter contact mobile number!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="street"
                        label="Street"
                        rules={[
                          {
                            required: true,
                            message: "Please enter street address!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col md={12}>
                      <Form.Item
                        name="city"
                        label="City"
                        rules={[
                          {
                            required: true,
                            message: "Please enter city",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col md={12}>
                      <Form.Item
                        name="postal"
                        label="Postal"
                        rules={[
                          {
                            required: false,
                            message: "Please enter postal",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col md={12}>
                      <Form.Item
                        name="state"
                        label="State"
                        rules={[
                          {
                            required: true,
                            message: "Please enter state",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col md={12}>
                      <Form.Item
                        name="country"
                        label="Country"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Country",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="middle"
                          loading={isLoadSaveAddress}
                        >
                          Save Address
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Modal>
            {/* Edit address */}

            <Modal
              title="Add Items"
              style={{ top: 20 }}
              visible={isItemModalVisible}
              width={790}
              destroyOnClose={true}
              onCancel={() => {
                this.setState({
                  isItemModalVisible: false,
                });
              }}
              footer={[
                <Button
                  key="cancel"
                  type="default"
                  loading=""
                  onClick={() => this.setState({ isItemModalVisible: false })}
                >
                  Cancel
                </Button>,
              ]}
            >
              <div className="form-design">
                <Form
                  name="add_item"
                  layout="vertical"
                  onFinish={this.handleSaveItem}
                  scrollToFirstError
                >
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        name="name"
                        label="Item Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter item name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="description"
                        label="Item description"
                        rules={[
                          {
                            required: true,
                            message: "Please enter item description!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        name="weight"
                        label="Weight(kg)"
                        rules={[
                          {
                            required: true,
                            message: "Please enter weight!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="length"
                        label="Length(cm)"
                        rules={[
                          {
                            required: true,
                            message: "Please enter length!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        name="width"
                        label="Width(cm)"
                        rules={[
                          {
                            required: true,
                            message: "Please enter width!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="height"
                        label="Height(cm)"
                        rules={[
                          {
                            required: true,
                            message: "Please enter height!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md={12}>
                      <Form.Item
                        name="content_description"
                        label="Content description"
                        rules={[
                          {
                            required: true,
                            message: "Please enter content description!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="content_value"
                        label="Content value"
                        rules={[
                          {
                            required: true,
                            message: "Please enter content value!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col md={12}>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="middle"
                          loading={isLoadSaveItem}
                        >
                          Save Items
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Modal>
            {/* Edit Items */}
            <Modal
              title="Edit Items"
              style={{ top: 20 }}
              visible={isEditItemModalVisible}
              width={790}
              destroyOnClose={true}
              onCancel={() => {
                this.setState({
                  isEditItemModalVisible: false,
                });
              }}
              footer={[
                <Button
                  key="cancel"
                  type="default"
                  loading=""
                  onClick={() =>
                    this.setState({ isEditItemModalVisible: false })
                  }
                >
                  Cancel
                </Button>,
              ]}
            >
              <div className="form-design">
                <Form
                  name="add_item"
                  layout="vertical"
                  onFinish={this.handleEditSaveItem}
                  ref={this.refItemForm}
                  scrollToFirstError
                >
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        name="name"
                        label="Item Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter item name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="description"
                        label="Item description"
                        rules={[
                          {
                            required: true,
                            message: "Please enter item description!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        name="weight"
                        label="Weight(kg)"
                        rules={[
                          {
                            required: true,
                            message: "Please enter weight!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="length"
                        label="Length(cm)"
                        rules={[
                          {
                            required: true,
                            message: "Please enter length!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        name="width"
                        label="Width(cm)"
                        rules={[
                          {
                            required: true,
                            message: "Please enter width!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="height"
                        label="Height(cm)"
                        rules={[
                          {
                            required: true,
                            message: "Please enter height!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="middle"
                          loading={isLoadSaveItem}
                        >
                          Save Items
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Modal>
            {/* Edit Items */}
          </>
        }
      />
    );
  }
}

export default withRouter(EditCustomerAccount);
