import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import {
    Row,
    Col,
    Select,
    Divider,
    notification,
    Drawer,
    Button,
    Table,
    Tag,
    Breadcrumb,
    DatePicker,
    Input
} from "antd";
import { ExportOutlined, DownloadOutlined } from "@ant-design/icons";
import Form from "../../Helper/Form";
import { token } from "../../Helper/Session";
import { withRouter } from "react-router-dom";
import "antd/dist/antd.css";
import moment from "moment";
import { Excel } from "antd-table-saveas-excel";
const { Search } = Input;
const { RangePicker } = DatePicker;

class Inventory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product_detail: [],
            serial_detail: [],
            serial_discrepancy: [],
            category: [],
            products: [],
            all_products: [],
            warehouse: [],
            id: props.id,
            drawerVisible: false,
            visibleStock: "none",
            product_id: 0,
            isTableLoading: false,
            warehouse_id: props.warehouse_id,
            client_id: props.client_id,
            isProDetailLoading: false,
            selectedWareHouse: [],
            selectedCustomerAccount: [],
            back_date: ""
        };
    }
    formRef = React.createRef();
    onFinish = (value) => {
        this.setState({
            back_date: (value.back_date) ? moment(value.back_date).format("YYYY-MM-DD") : '',
            range_date_start: (value.range_date) ? moment(value.range_date[0]).format("YYYY-MM-DD") : '',
            range_date_end: (value.range_date) ? moment(value.range_date[1]).format("YYYY-MM-DD") : '',
        }, () => {
            this.handleProductList();
        })
    }
    viewProduct = (id) => {
        const dta = {
            product_id: id,
            warehouse_id: this.state.selectedWareHouse.id,
            client_id: this.state.selectedCustomerAccount.id
        }
        this.setState({ drawerVisible: true, product_id: id, isProDetailLoading: true, product_detail: [] });
        fetch(`${BASE_URL}${API.PRODUCT_INVENTORY}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dta),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        product_detail: json.data.product_detail,
                        serial_detail: json.data.serial_detail,
                        serial_discrepancy: json.data.serial_discrepancy,
                        visibleStock: "flex",
                    });
                }
                this.setState({
                    isProDetailLoading: false
                })
            });
    }

    componentDidMount() {
        var selectedWareCA = localStorage.getItem('selectedWareCA');
        selectedWareCA = JSON.parse(selectedWareCA);
        if (selectedWareCA != null) {
            if (!selectedWareCA.selectedWareHouse && !selectedWareCA.selectedCustomerAccount) {
                notification.error({ message: 'Error', description: 'Customer account and Warehouse location is not selected! Please select to proceed.', placement: "top" });
            } else {
                this.setState({
                    selectedCustomerAccount: selectedWareCA.selectedCustomerAccount,
                    selectedWareHouse: selectedWareCA.selectedWareHouse
                }, () => {
                    this.handleProductList();
                })
            }
        } else {
            notification.error({ message: 'Error', description: 'Customer account and Warehouse location is not selected! Please select to proceed.', placement: "top" });
        }
    }

    handleProductList = () => {
        const data = { warehouse_id: this.state.selectedWareHouse.id, client_id: this.state.selectedCustomerAccount.id }
        if (this.state.back_date != "") {
            data.back_date = this.state.back_date;
        }
        if (this.state.range_date_start != "") {
            data.range_date_start = this.state.range_date_start;
        }
        if (this.state.range_date_end != "") {
            data.range_date_end = this.state.range_date_end;
        }
        this.setState({ isTableLoading: true })
        fetch(`${BASE_URL}${API.PRODUCT_LIST}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    isTableLoading: false
                })
                if (json.status === true) {
                    this.setState({
                        products: json.data,
                        all_products: json.data
                    });
                } else {
                    notification.error({ message: json.error_msg });
                }
            });
    }

    //Printing function

    searchKeyword = (value) => {
        value = (value) ? value : "";

        let productsArray = this.state.all_products.filter(function (pro) {
            return pro.sku == value.toUpperCase();
        });
        this.setState({
            products: productsArray
        })
        if (value == "" || value == " ") {
            this.setState({
                products: this.state.all_products
            })
        }
    }

    render() {
        const { serial_detail, serial_discrepancy, products, visibleStock, product_detail, isTableLoading, isProDetailLoading, selectedWareHouse, selectedCustomerAccount } = this.state;
        const { Option } = Select;
        var data = [];
        var product_data = [];
        var product_data_ = [];
        const handlePrint = () => {
            serial_detail.map((pro, i) =>
                product_data_.push({
                    key: i + 1,
                    serial: pro["serial"],
                    hardware_hash: pro.hardware_hash,
                    asset_tag: pro.asset_tag,
                    room_no: pro.room_no,
                    rack_no: pro.rack_no,
                    shelf_no: pro.shelf_no,
                    confirm_date: (pro.confirm_date) ? moment(pro.confirm_date).format("DD-MM-YYYY") : ''
                })
            );
            const excel = new Excel();
            excel
                .addSheet("Available Serial")
                .addColumns(product_columns)
                .addDataSource(product_data_, {
                    str2Percent: true,
                })
                .saveAs("Available Serial of SKU " + product_detail.sku + ".xlsx");
        };
        const columns = [
            {
                title: "SNo",
                dataIndex: "key",
            },
            {
                title: "Product Name",
                dataIndex: "product",
            },
            {
                title: "SKU",
                dataIndex: "sku",

                key: 'sku',
                // sorter: (a, b) => a.sku.length - b.sku.length,
                // sortDirections: ['descend', 'ascend'],
            },
            {
                title: "Category",
                dataIndex: "category",
            },
            {
                title: "Available",
                dataIndex: "quantity",
                sorter: (a, b) => a.qua - b.qua,
            },
            {
                title: "Reserved",
                dataIndex: "reserved",
                sorter: (a, b) => a.res - b.res,
            },
            {
                title: "Shipped",
                dataIndex: "shipped",
                sorter: (a, b) => a.shi - b.shi,
            }
        ];
        if (this.state.back_date != "") {
            columns.splice(5, 2)
        }
        const product_columns = [
            {
                title: "SNo",
                dataIndex: "key",
            },
            {
                title: "Serial",
                dataIndex: "serial",
            },
            {
                title: "Hardware hash",
                dataIndex: "hardware_hash",
            },
            {
                title: "Hardware asset tag",
                dataIndex: "asset_tag",
            },
            {
                title: "Room no",
                dataIndex: "room_no",
            },
            {
                title: "Rack no",
                dataIndex: "rack_no",
            },
            {
                title: "Shelf no",
                dataIndex: "shelf_no",
            },
            {
                title: "Received date",
                dataIndex: "confirm_date"
            }
        ];
        serial_detail.map((pro, i) =>
            product_data.push({
                key: i + 1,
                serial: (<Tag color="geekblue">{pro["serial"]}</Tag>),
                hardware_hash: (<Tag color="purple">{pro.hardware_hash}</Tag>),
                asset_tag: (<Tag color="blue">{pro.asset_tag}</Tag>),
                room_no: pro.room_no,
                rack_no: pro.rack_no,
                shelf_no: pro.shelf_no,
                confirm_date: (pro.confirm_date) ? moment(pro.confirm_date).format("DD-MM-YYYY") : ''
            })
        );
        products.map((pro, i) =>
            data.push({
                key: i + 1,
                product: (<a href="javascript:void(0);" onClick={() => this.viewProduct(pro['sku'])}>{pro["product_name"]}</a>),
                category: (<Tag color="geekblue">{pro["category"]}</Tag>),
                quantity: (<Tag color={pro["quantity"] < 4 ? "red" : "#108ee9"}>{pro["quantity"]}</Tag>),
                qua: pro.quantity,
                reserved: (<Tag color={pro["reserved"] < 4 ? "red" : "#108ee9"}>{pro["reserved"]}</Tag>),
                res: pro.reserved,
                shipped: (<Tag color={pro["shipped"] <= 0 ? "red" : "gold"}>{pro["shipped"]}</Tag>),
                shi: pro.shipped,
                sku: (<Tag color="green">{pro.entity + "-" + pro.sku}</Tag>)
            })
        );
        return (
            <Headers
                bodyContain={
                    <>
                        <Row>
                            <Col md={16}>
                                <Breadcrumb>
                                    <Breadcrumb.Item>{selectedWareHouse.location_name}</Breadcrumb.Item>
                                    <Breadcrumb.Item>{selectedCustomerAccount.customer_account_name}</Breadcrumb.Item>
                                    <Breadcrumb.Item>Inventory</Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                            {(this.state.back_date == "") && (
                                <Col md={4}><Button type="secondary" icon={<DownloadOutlined />} onClick={() => window.location.href = BASE_URL + "" + API.GENERATE_INVENTORY_REPORT + "?customer_account=" + this.state.selectedCustomerAccount.id + "&warehouse=" + this.state.selectedWareHouse.id + "&is_detailed=detailed"} size="small" style={{ marginBottom: "10px" }}>Detailed Report</Button></Col>
                            )}
                            <Col md={4} justify="end" style={{ float: "right" }}><Button type="warning" icon={<DownloadOutlined />} onClick={() => window.location.href = BASE_URL + "" + API.GENERATE_INVENTORY_REPORT + "?customer_account=" + this.state.selectedCustomerAccount.id + "&warehouse=" + this.state.selectedWareHouse.id + "&is_detailed=summary&back_date=" + ((this.formRef.current.getFieldValue('back_date')) ? moment(this.formRef.current.getFieldValue('back_date')).format("YYYY-MM-DD") : '')} size="small" style={{ marginBottom: "10px" }}>Summary Report</Button></Col>
                        </Row>

                        <div className="site-layout-background form-design">
                            <Row>
                                <Col>
                                    <Form
                                        name="search_filter"
                                        layout="vertical"
                                        onFinish={this.onFinish}
                                        scrollToFirstError
                                        ref={this.formRef}
                                    >
                                        <Row>
                                            <Col>
                                                <Form.Item name="back_date"
                                                    label="Please enter Date to apply filter"

                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "Please select back date!",
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker style={{ width: "100%" }} size="small"
                                                        format="DD-MM-YYYY"
                                                        defaultValue={moment()} disabledDate={(current) => {
                                                            let customDate = moment().format("YYYY-MM-DD");
                                                            return current && current > moment(customDate, "YYYY-MM-DD");
                                                        }} />
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <Form.Item name="range_date"
                                                    label="Please enter date range to see shipped information."

                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "Please select date!",
                                                        },
                                                    ]}
                                                >
                                                    <RangePicker
                                                        size="small"
                                                        format="DD-MM-YYYY"
                                                        defaultValue={moment()} disabledDate={(current) => {
                                                            let customDate = moment().format("YYYY-MM-DD");
                                                            return current && current > moment(customDate, "YYYY-MM-DD");
                                                        }} />

                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <Button
                                                    htmlType="submit"
                                                    size="small"
                                                    type="primary"
                                                    style={{ marginTop: "25px" }}
                                                >
                                                    Search
                                                </Button>
                                                <Button size="small" onClick={() => window.location.reload()} type="default" style={{ marginTop: "25px", marginLeft: "1px" }}>Reset</Button>
                                            </Col>


                                        </Row>
                                    </Form>
                                </Col>

                                <Col md={6} style={{ "marginTop": "25px" }}>
                                    <Search size="small" className="search" onSearch={this.searchKeyword} placeholder="Search SKU" style={{ "width": "100%" }} />
                                </Col>

                            </Row>
                            <Row>
                                <Col md={24}>
                                    <Table
                                        columns={columns}
                                        dataSource={data}
                                        size="small"
                                        bordered
                                        loading={isTableLoading}
                                        pagination={{ pageSize: 150 }}
                                    />
                                </Col>
                            </Row>
                            <Divider />
                            <Drawer title={product_detail.product_name + " [" + product_detail.sku + "]"} placement="right" closable={true}
                                mask={true}
                                width={850}
                                visible={this.state.drawerVisible}
                                onClose={() => { this.setState({ drawerVisible: false, isTableLoading: true }); this.handleProductList(); }}
                                extra={

                                    <Button type="primary" icon={<DownloadOutlined />} onClick={handlePrint}>Download</Button>

                                }
                                destroyOnClose>
                                <Row>

                                    <Col md={24}>
                                        <Table
                                            columns={product_columns}
                                            dataSource={product_data}
                                            size={"small"}
                                            bordered
                                            loading={isProDetailLoading}
                                        />
                                    </Col>
                                </Row>
                                <Divider />
                                <Row style={{ display: visibleStock }}>
                                    <Col sm={12}>
                                        <div className="stock_box">
                                            <p>Quantity breakdown</p>
                                            <div className="quantity">
                                                <Row>
                                                    {/* <Col sm={18}>
                                                        On hand
                                                    </Col>
                                                    <Col sm={6}>
                                                        {product_detail.onhand}
                                                    </Col> */}
                                                    <Col sm={18}>
                                                        Available
                                                    </Col>
                                                    <Col sm={6}>
                                                        {product_detail.available}
                                                    </Col>
                                                    <Col sm={18}>
                                                        Reserved
                                                    </Col>
                                                    <Col sm={6}>
                                                        {product_detail.reserved}
                                                    </Col>

                                                    <Col sm={18}>
                                                        Shipped
                                                    </Col>
                                                    <Col sm={6}>
                                                        {(product_detail.shipped) ? product_detail.shipped : 0}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                    {(serial_discrepancy.length > 0) && (
                                        <Col sm={12}>
                                            <div className="stock_box discre">
                                                <p>Serial with Discrepancy</p>
                                                <div className="quantity">
                                                    <Row>

                                                        {serial_discrepancy.map((srl) => {
                                                            return (
                                                                <Tag color={"red"} key={srl}>
                                                                    <Col> {srl.toUpperCase()}</Col>
                                                                </Tag>
                                                            );
                                                        })}

                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </Drawer>
                        </div>

                    </>
                }
            />
        );
    }
}
export default withRouter(Inventory);
