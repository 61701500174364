import React from "react";
import Headers from "../component/Headers";
import {
  Button,
  Divider,
  Tabs,
  Table,
  Switch,
  Spin,
  Row,
  Col,
  Input,
  Modal,
  notification,
  Dropdown,
  Menu,
} from "antd";
import { token } from "../../Helper/Session";
import { API, BASE_URL } from "../../Endpoint";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import Form from "../../Helper/Form";

const { TabPane } = Tabs;

class EditCustomerUser extends React.Component {
  constructor(props) {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("user_id");
    if (id == null) {
      props.history.push("/customer-users");
    }
    // console.log(props.form);
    super(props);
    this.state = {
      address: [],
      item: [],
      user: {},
      user_id: id,
      isLoadedAddress: true,
      isLoadSaveAddress: false,
      isLoadItem: true,
      isLoadSaveItem: false,
      idFormLoading: true,
      isItemModalVisible: false,
    };
  }
  formRef = React.createRef();
  async componentDidMount() {
    await fetch(`${BASE_URL}${API.USER_DETAIL}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ user_id: this.state.user_id }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          notification.success({ message: json.success_msg });
          this.formRef.current.setFieldsValue({
            first_name: json.data.first_name,
            last_name: json.data.last_name,
            email: json.data.email,
            mobile: json.data.mobile,
          });
          this.setState({
            idFormLoading: false,
          });
        } else {
          notification({ message: json.error_msg });
        }
      });
  }

  loadAddress = (key) => {
    const user_id = this.state.user_id;
    if (key == 2 && this.state.isLoadedAddress) {
      this.setState({
        isLoadedAddress: false,
      });
      fetch(`${BASE_URL}${API.MAJOR_ADDRESS_LIST}`, {
        method: "POST",
        headers: {
          Accept: "application/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ user_id: user_id }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.status === true) {
            notification.success({ message: json.success_msg });
            this.setState({
              address: json.data,
            });
          } else {
            notification({ message: json.error_msg });
          }
        });
    } else if (key == 3 && this.state.isLoadItem) {
      this.setState({
        isLoadItem: false,
      });
      fetch(`${BASE_URL}${API.ITEM_LIST}`, {
        method: "POST",
        headers: {
          Accept: "application/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ user_id: user_id }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.status === true) {
            notification.success({ message: json.success_msg });
            this.setState({
              item: json.data,
            });
          } else {
            notification.error({ message: json.error_msg });
          }
        });
    }
  };
  onFinish = (values) => {
    this.setState({ idFormLoading: true });
    var user_data = [];
    user_data = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      mobile: values.mobile,
      user_id: this.state.user_id,
    };
    console.log(user_data);
    fetch(`${BASE_URL}${API.SAVE_USER}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(user_data),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          notification.success({ message: json.success_msg });
          this.setState({ idFormLoading: false });
        } else {
          notification.error({ message: json.error_msg });
        }
      });
  };

  handleModal = () => {
    this.setState({ isModalVisible: true });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleOk = () => {
    this.setState({ isLoading: true });
  };

  render() {
    const {
      address,
      item,
      user,
      idFormLoading,
      isModalVisible,
      isItemModalVisible,
      isLoadSaveAddress,
      isLoadSaveItem,
    } = this.state;
    var data = [];
    var data_item = [];
    const columns = [
      {
        title: "SNo",
        dataIndex: "key",
      },
      {
        title: "Company Name",
        dataIndex: "company_name",
      },
      {
        title: "Contact Name",
        dataIndex: "contact_name",
      },
      {
        title: "Contact Number",
        dataIndex: "mobile",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Street",
        dataIndex: "street",
      },
      {
        title: "City",
        dataIndex: "city",
      },
      {
        title: "State",
        dataIndex: "state",
      },
      {
        title: "Country",
        dataIndex: "country",
      },
    ];
    const columns_item = [
      {
        title: "Sno",
        dataIndex: "key",
      },
      {
        title: "Item name",
        dataIndex: "name",
      },
      {
        title: "Weight(kg)",
        dataIndex: "weight",
      },
      {
        title: "Length(cm)",
        dataIndex: "length",
      },
      {
        title: "Width(cm)",
        dataIndex: "width",
      },
      {
        title: "Height(cm)",
        dataIndex: "height",
      },
      // {
      //   title: "Contents ",
      //   dataIndex: "content_description",
      // },
      // {
      //   title: "Contents value",
      //   dataIndex: "content_value",
      // },

      {
        title: "Description",
        dataIndex: "description",
      },
    ];
    return (
      <Headers
        bodyContain={
          <>
            <div className="site-layout-background form-design">
              <h4>Edit Customer User Detail</h4>
              <Divider />

              {/* user detail */}
              <Spin spinning={idFormLoading}>
                <Form
                  ref={this.formRef}
                  name="register"
                  layout="vertical"
                  onFinish={this.onFinish}
                  scrollToFirstError
                >
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        name="first_name"
                        label="First Name"
                        value={user.first_name}
                        rules={[
                          {
                            required: true,
                            message: "Please input user first name!",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input value={user.first_name} />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="last_name"
                        label="Last Name"
                        onChange={this.handleChange}
                        initialValue={user.last_name}
                        value={user.last_name}
                        rules={[
                          {
                            required: true,
                            message: "Please input user last name!",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input
                        // initialvalues={user.last_name}
                        // onChange={this.changeBody}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[
                          {
                            type: "email",
                            message: "The input is not valid E-mail!",
                          },
                          {
                            required: true,
                            message: "Please input your E-mail!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="mobile"
                        label="Contact Number"
                        rules={[
                          {
                            required: true,
                            message: "Please input your contact number!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Item>
                        <Button type="primary" htmlType="submit" size="middle">
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Spin>
            </div>
          </>
        }
      />
    );
  }
}

export default withRouter(EditCustomerUser);
