import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import {
  Row,
  Col,
  Button,
  Input,
  Divider,
  notification,
  Spin,
  Select,
} from "antd";
import Form from "../../Helper/Form";
import { token } from "../../Helper/Session";

class EditUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      id: props.id,
      isLoading: true,
      role_opt: [],
    };
  }

  formRef = React.createRef();
  async componentDidMount() {
    fetch(`${BASE_URL}${API.ROLE_LIST}`, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            role_opt: json.data,
          });
        }
      });
    // console.log(token);
    await fetch(`${BASE_URL}${API.USER_DETAIL}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ user_id: this.state.id }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            user: json.data,
          });
          this.formRef.current.setFieldsValue({
            first_name: this.state.user.first_name,
            last_name: this.state.user.last_name,
            email: this.state.user.email,
            mobile: this.state.user.mobile,
            user_role: this.state.user.role_id,
          });
        }
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const { id, isLoading, role_opt } = this.state;
    const { Option } = Select;
    const onFinish = (values) => {
      this.setState({ idFormLoading: true });
      var user_data = [];
      user_data = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        mobile: values.mobile,
        user_id: id,
        user_role: values.user_role
      };
      console.log(user_data);
      fetch(`${BASE_URL}${API.SAVE_USER}`, {
        method: "POST",
        headers: {
          Accept: "application/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(user_data),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.status === true) {
            notification.success({ message: json.success_msg });
            this.setState({ idFormLoading: false });
          } else {
            notification.error({ message: json.error_msg });
          }
        });
    };
    return (
      <Headers
        bodyContain={
          <>
            <div className="site-layout-background form-design">
              <h4>Update user account detail</h4>
              <Divider />
              <Form
                name="register"
                layout="vertical"
                onFinish={onFinish}
                scrollToFirstError
                ref={this.formRef}
              >
                <Spin size="middle" spinning={isLoading}>
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        name="first_name"
                        label="First Name"
                        rules={[
                          {
                            required: true,
                            message: "Please input user first name!",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="last_name"
                        label="Last Name"
                        rules={[
                          {
                            required: true,
                            message: "Please input user last name!",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input
                        // initialvalues={user.last_name}
                        // onChange={this.changeBody}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[
                          {
                            type: "email",
                            message: "The input is not valid E-mail!",
                          },
                          {
                            required: true,
                            message: "Please input your E-mail!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="mobile"
                        label="Contact Number"
                        rules={[
                          {
                            required: true,
                            message: "Please input your contact number!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="user_role"
                        label="User Role"
                        rules={[
                          {
                            required: true,
                            message: "Please select user role!",
                          },
                        ]}
                      >
                        <Select placeholder="Select user role">
                          {role_opt.map((opt, i) => {
                            return <Option value={opt.id}>{opt.name}</Option>;
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Spin>

                <Row>
                  <Col md={12}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" size="medium">
                        Register
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </>
        }
      />
    );
  }
}
export default EditUsers;
