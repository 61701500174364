import React, { useEffect } from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import {
    Row,
    Col,
    Button,
    Input,
    Select,
    Divider,
    notification,
    Drawer,
    Card,
    Tag,
    Space,
    DatePicker,
    Spin,
    Modal,
    Table,
    Breadcrumb
} from "antd";
import { MinusCircleOutlined, PlusOutlined, SwapOutlined, PlusCircleOutlined, DownloadOutlined, PlusCircleTwoTone, FileAddOutlined } from "@ant-design/icons";
import Form from "../../Helper/Form";
import { token } from "../../Helper/Session";
import { withRouter } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import { AppstoreAddOutlined, InteractionOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import "antd/dist/antd.css";
import moment from "moment";
import { sessionData } from "../../Helper/Session";

class CartIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cart_in: [],
            product_detail: [],
            category: [],
            products: [],
            warehouse: [],
            id: props.id,
            drawerVisible: false,
            visibleStock: "none",
            items: [],
            skus: [],
            cart_detail: [],
            cart_id: 0,
            confirm_stock: [],
            drawerAddReceiveVisible: false,
            tableLoadingRecived: false,
            confirm_stock_detail: [],
            confirm_stock_detail_id: 0,
            drawerViewReceiveVisible: [],
            drawerAddCartInVisible: false,
            drawerViewCartInVisible: false,
            stock_detail: [],
            stock_detail_id: 0,
            stock: [],
            customer_account: [],
            customer_user: [],
            users: [],
            users_data: [],
            isCartInViewLoading: false,
            isonFinishReceive: false,
            isCartLoading: false,
            customer_account_id: 0,
            warehouse_id: 0,
            openSelectCustomerAccountWareHouse: false,
            selectedWareHouse: 0,
            selectedCustomerAccount: 0,
            confirm_stock_rec_data: [],
            openConfirmProductRec: false,
            selectedSkuId: 0,
            isConfirmRecProductsLoading: false,
            cart_in_confirm: [],
            isCartLoadingConfirm: false,
            isConfirmProductSaving: false,
            drawerDataProduct_name: "",
            ConfirmDetail: [],
            drawerDataProduct_Qty: "",
            ConfirmDetailDrawer: false,
            openModalConfirmationEmail: false,
            confirm_stock_for_mail: [],
            selectedRowKeys: "",
            openConfirmProductRecEDIT: "",
            edit_confirmation_id: ""
        };
    }

    formRef = React.createRef();
    formRefR = React.createRef();
    formRefFR = React.createRef();
    formRefEdit = React.createRef();
    formRefEditStock = React.createRef();
    editFormConfirm = React.createRef();
    addCartIn = () => {
        this.setState({ visibleStock: "none", customer_account_id: 0, warehouse_id: 0, drawerVisible: true });
    };
    viewProduct = (id) => {
        const dta = {
            product_id: id
        }
        this.setState({ drawerVisible: true });
        fetch(`${BASE_URL}${API.PRODUCT_DETAIL}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dta),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        product_detail: json.data,
                        visibleStock: "block",
                    });
                    this.formRef.current.setFieldsValue({
                        product_name: this.state.product_detail.product_name,
                        sku: this.state.product_detail.sku,
                        category: this.state.product_detail.category,
                        description: this.state.product_detail.description,
                    })
                }
            });
    }
    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const cart_id = queryParams.get("view_confirm");
        if (cart_id != null) {
            this.openReceiveDrawer(cart_id);
        }
        fetch(`${BASE_URL}${API.WAREHOUSE}`, {
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        warehouse: json.data,
                    });
                }
            });
        this.fetchCustomerAccount();
        var selectedWareCA = localStorage.getItem('selectedWareCA');
        selectedWareCA = JSON.parse(selectedWareCA);
        if (selectedWareCA != null) {
            if (!selectedWareCA.selectedWareHouse && !selectedWareCA.selectedCustomerAccount) {
                notification.error({ message: 'Error', description: 'Customer account and Warehouse location is not selected! Please select to proceed.', placement: "top" });
            } else {
                this.setState({
                    selectedCustomerAccount: selectedWareCA.selectedCustomerAccount,
                    selectedWareHouse: selectedWareCA.selectedWareHouse
                }, () => {
                    this.handleCartList();
                    this.handleCartListConfirm();
                    this.handleChangeSKU();
                })
            }
        } else {
            notification.error({ message: 'Error', description: 'Customer account and Warehouse location is not selected! Please select to proceed.', placement: "top" });
        }
    }
    fetchUserDetail = (user_id) => {
        const dta = {
            user_id: user_id
        }
        fetch(`${BASE_URL}${API.USER_DETAIL}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dta)
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        users: json.data,
                    });
                    this.formRef.current.setFieldsValue({
                        phone: this.state.users.mobile,
                        email: this.state.users.email
                    })
                }
            });
    }
    fetchCustomerAccount = () => {
        fetch(`${BASE_URL}${API.CUSTOMER_ACCOUNT_LIST}`, {
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        customer_account: json.data,
                    });
                }
            });
    }
    fetchCustomerUser = (customer_account_id) => {
        const dta = {
            customer_account_id: customer_account_id
        }
        this.setState({
            customer_account_id: customer_account_id,
            skus: []
        })
        fetch(`${BASE_URL}${API.CUSTOMER_USER_FOR_CUSTOMER_ACCOUNT}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dta)
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        customer_user: json.data.customer_user,
                        ship_to: json.data.address
                    });
                } else {
                    this.setState({
                        customer_user: [],
                    });
                }
            });
    }
    setWareHouse = (warehouse_id) => {
        this.setState({ warehouse_id: warehouse_id, skus: [] })
    }
    handleCartList = (value) => {
        this.setState({
            isCartLoading: true
        })
        fetch(`${BASE_URL}${API.CART_LIST}?cart_type=1&warehouse=` + this.state.selectedWareHouse.id + `&customer_account_id=` + this.state.selectedCustomerAccount.id, {
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    cart_in: json.data,
                    users_data: json.users,
                    isCartLoading: false
                });
            });
    }

    handleCartListConfirm = () => {
        this.setState({
            isCartLoading: true
        })
        fetch(`${BASE_URL}${API.CONFIRM_INSTRUCTION}?cart_type=cart_in&warehouse=` + this.state.selectedWareHouse.id + `&customer_account=` + this.state.selectedCustomerAccount.id, {
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    cart_in_confirm: json.data,
                    isCartLoadingConfirm: false
                });
            });
    }

    handleChangeSKU = () => {
        this.setState({ skus: [] });
        const dta = {
            // sku: value,
            warehouse_id: this.state.selectedWareHouse.id,
            customer_account_id: this.state.selectedCustomerAccount.id
        }
        fetch(`${BASE_URL}${API.GET_SKU}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dta)
        }).then((res) => res.json()).then((json) => {
            if (json.status === true) {
                const skus = [];
                json.data.forEach((r) => {
                    skus.push({
                        id: r['sku'],
                        product_name: r['product_name'] + "-" + r['sku'],
                    });
                });
                this.setState({ skus });
            }
        });
    }
    openReceiveDrawer = (id) => {
        this.setState({
            drawerReceiveVisible: true
        });
        this.handleCartDetail(id);
    }
    openViewCartInDrawer = (id) => {
        this.setState({
            drawerViewCartInVisible: true,
            isCartInViewLoading: true
        });
        this.handleCartViewDetail(id);
    }
    showConfirmedDetail = (id) => {
        var ConfirmDetail = this.state.cart_in_confirm.filter(function (el) {
            return el.id == id;
        });
        // console.log(ConfirmDetail);
        this.setState({
            ConfirmDetail: ConfirmDetail[0],
            ConfirmDetailDrawer: true,
        })
        // console.log(this.state.ConfirmDetail);
    }
    handleCartViewDetail = (id) => {
        // CartIn detail
        const dta = {
            'cart_id': id,
            'cart_type': 'cart_in'
        }
        this.setState({
            isCartInViewLoading: true
        });
        fetch(`${BASE_URL}${API.CART_DETAIL}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dta)
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        cart_detail: json.data,
                        cart_id: json.data.id,
                        stock: json.data.stock,
                        confirm_stock: [],
                        customer_user: [{
                            'user_id': json.data.customer_id,
                            'first_name': json.data.first_name,
                            'last_name': json.data.last_name
                        }]
                    });
                    this.formRefEdit.current.setFieldsValue({
                        order_date: this.state.cart_detail.order_date ? moment(this.state.cart_detail.order_date) : '',
                        customer_account: this.state.cart_detail.customer_account,
                        customer_id: this.state.cart_detail.customer_id,
                        phone: this.state.cart_detail.phone,
                        email: this.state.cart_detail.email,
                        warehouse_location: this.state.cart_detail.warehouse_location,
                        remarks: this.state.cart_detail.remarks
                    })
                }
                this.setState({
                    isCartInViewLoading: false
                })
            });
    }

    handleCartDetail = (id) => {
        // CartInReceive detail
        const dta = {
            'cart_id': id,
            'cart_type': 'cart_in'
        }
        this.setState({
            tableLoadingRecived: true
        })
        fetch(`${BASE_URL}${API.CART_DETAIL}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dta)
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        cart_detail: json.data,
                        cart_id: json.data.id,
                        stock: [],
                        confirm_stock: json.data.confirm_stock
                    });
                }
                this.setState({
                    tableLoadingRecived: false
                })
            });
    }
    handleStockDetailEdit = (id) => {
        const dta = {
            'stock_id': id
        }
        this.setState({
            drawerAddCartInVisible: true
        })
        fetch(`${BASE_URL}${API.STOCK_DETAIL}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dta)
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        stock_detail: json.data,
                        stock_detail_id: json.data.id
                    });
                    this.formRefEditStock.current.setFieldsValue({
                        qty: this.state.stock_detail.qty,
                        sku: this.state.stock_detail.sku,
                        serial: (this.state.stock_detail.serial) ? this.state.stock_detail.serial.split(",") : [],
                        confirm_date: (this.state.stock_detail.confirm_date) ? moment(this.state.stock_detail.confirm_date) : '',
                        relevant_info: this.state.stock_detail.relevant_info
                    })
                    const skus = [];
                    skus.push({
                        id: this.state.stock_detail.sku,
                        product_name: this.state.stock_detail.product_name + "-" + this.state.stock_detail.sku,
                    });
                    this.setState({ skus });
                }
            });
    }
    handleSelectCustomerAccount = (value) => {
        let warehouseArray = this.state.warehouse.filter(function (el) {
            return el.id == value.warehouse;
        });
        let CustomerAccountArray = this.state.customer_account.filter(function (ca) {
            return ca.id == value.customer_account;
        });
        this.setState({
            selectedWareHouse: warehouseArray[0],
            selectedCustomerAccount: CustomerAccountArray[0],
            openSelectCustomerAccountWareHouse: false,
        })
        this.fetchCustomerUser(value.customer_account);
        this.handleCartList();
    }
    handleConfirmStockDetail = (id, sku, product_name, qty) => {
        const dta = {
            'stock_id': id
        }
        this.setState({
            drawerAddReceiveVisible: true,
            stock_id: id,
            selectedSkuId: sku,
            isConfirmRecProductsLoading: true,
            drawerDataProduct_name: product_name,
            drawerDataProduct_Qty: qty
        })
        fetch(`${BASE_URL}${API.CONFIRM_STOCK_DETAIL}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dta)
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        confirm_stock_detail: json.data,
                        // confirm_stock_detail_id: json.data.id
                    });
                    // this.formRefFR.current.setFieldsValue({
                    //     qty: this.state.confirm_stock_detail.qty,
                    //     sku: this.state.confirm_stock_detail.sku,
                    //     serial: (this.state.confirm_stock_detail.serial) ? this.state.confirm_stock_detail.serial.split(",") : [],
                    //     confirm_date: this.state.confirm_stock_detail.confirm_date ? moment(this.state.confirm_stock_detail.confirm_date) : '',
                    // })
                    // const skus = [];
                    // skus.push({
                    //     id: this.state.confirm_stock_detail.sku,
                    //     product_name: this.state.confirm_stock_detail.product_name + "-" + this.state.confirm_stock_detail.sku,
                    // });
                    // this.setState({ skus });
                } else {
                    this.setState({
                        confirm_stock_detail: [],
                        // confirm_stock_detail_id: 0
                    });
                }
                this.setState({
                    openConfirmProductRec: false,
                    isConfirmRecProductsLoading: false
                })
            });
    }

    handleConfirmProductReceived = () => {
        this.setState({
            edit_confirmation_id: "",
            openConfirmProductRec: true
        })
    }
    openModalConfirmationEmailUp = () => {
        fetch(`${BASE_URL}${API.CONFIRM_STOCK_FOR_MAIL}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({ cart_id: this.state.cart_id })
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        confirm_stock_for_mail: json.data,
                        openModalConfirmationEmail: true
                    })
                } else {
                    this.setState({
                        confirm_stock_for_mail: []
                    })
                    notification.error({ message: json.error_msg })
                }
            })
    }
    onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        this.setState({
            selectedRowKeys: newSelectedRowKeys
        })
    };
    send_email = () => {
        fetch(`${BASE_URL}${API.SEND_CONFIRMATION_EMAIL}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({ cart_id: this.state.cart_id, confirmation_id: this.state.selectedRowKeys })
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({ openModalConfirmationEmail: false });
                    notification.success({ message: json.success_msg })
                } else {
                    notification.error({ message: json.error_msg })
                }
            })
    }

    edit_confirm_product = (id) => {
        this.setState({ openConfirmProductRecEDIT: true, edit_confirmation_id: "" })
        fetch(`${BASE_URL}${API.FETCH_CONFIRM_REC_PRDCT}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({ id: id })
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        edit_confirmation_id: id
                    });
                    this.editFormConfirm.current.setFieldsValue({
                        qty: json.data.qty,
                        serial: (json.data.serial) ? json.data.serial.split(",") : [],
                        confirm_date: (json.data.confirm_date) ? moment(json.data.confirm_date) : '',
                        hardware_hash: json.data.hardware_hash,
                        hardware_asset_tag: json.data.hardware_asset_tag,
                        room_no: json.data.room_no,
                        rack_no: json.data.rack_no,
                        shelf_no: json.data.shelf_no
                    })
                } else {
                    notification.error({ message: json.error_msg })
                }
            })
    }

    render() {
        const { Option } = Select;
        const { users_data, openConfirmProductRecEDIT, selectedRowKeys, warehouse, cart_in, cart_detail, skus, confirm_stock, tableLoadingRecived, ConfirmDetail, stock, customer_account, openConfirmProductRec, isCartInViewLoading, isonFinishReceive, isCartLoading, openSelectCustomerAccountWareHouse, selectedWareHouse, selectedCustomerAccount, confirm_stock_for_mail, selectedSkuId, confirm_stock_detail, isConfirmRecProductsLoading, cart_in_confirm, isConfirmProductSaving, drawerDataProduct_Qty, drawerDataProduct_name, openModalConfirmationEmail } = this.state;
        const skus_options = skus.map(d => <Option key={d.id} value={d.id}>{d.product_name}</Option>);
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        var data = [];
        var data_receive_pro = [];
        var data_stock_pro = [];
        var confirm_stock_detail_ = [];
        var data_confirm_instruction = [];
        var data_confirm_stock_for_mail = [];
        const confirm_instruction = [
            {
                title: "Goods-In Confirmation ID",
                dataIndex: "id"
            },
            {
                title: "Goods-In Instruction ID",
                dataIndex: "instruction_id"
            },
            {
                title: "Product",
                dataIndex: "product"
            },
            {
                title: "Quantity Received",
                dataIndex: "qty"
            },
            {
                title: "Confirmed By",
                dataIndex: "created_by"
            },

        ]
        const confirm_instruction_mail = [
            {
                title: "Goods-In Confirmation ID",
                dataIndex: "id"
            },
            // {
            //     title: "Goods-In Instruction ID",
            //     dataIndex: "instruction_id"
            // },
            {
                title: "Product",
                dataIndex: "product"
            },
            {
                title: "Quantity Received",
                dataIndex: "qty"
            },
            {
                title: "Confirmed By",
                dataIndex: "created_by"
            },

        ]
        const confirm_stock_rec_col = [
            {
                title: "Confirmation Id",
                dataIndex: "id"
            },
            {
                title: "Quantity",
                dataIndex: "qty"
            },
            {
                title: "Serial no.",
                dataIndex: "serial",
                render: (_, { serial }) => (
                    <>
                        {serial.map((serial) => {
                            let color = serial.length > 5 ? 'geekblue' : 'green';
                            return (
                                <Tag color={color} key={serial}>
                                    {serial.toUpperCase()}
                                </Tag>
                            );
                        })}
                    </>
                ),
            },
            {
                title: "Receiving date",
                dataIndex: "confirm_date"
            },
            {
                title: "Hardware hash no.",
                dataIndex: "hardware_hash"
            },
            {
                title: "Hardware asset tag",
                dataIndex: "hardware_asset_tag"
            },
            {
                title: "Room no.",
                dataIndex: "room_no"
            },
            {
                title: "Rack no.",
                dataIndex: "rack_no"
            },
            {
                title: "Shelf no.",
                dataIndex: "shelf_no"
            },
            {
                title: "Edit",
                dataIndex: "action"
            }
        ]
        const columns = [
            {
                title: "Goods-In Instruction Id",
                dataIndex: "key",
                defaultSortOrder: 'descend'
            },
            {
                title: "Instruction date",
                dataIndex: "order_date",
            },
            {
                title: "Created by",
                dataIndex: "created_by",
            },
            {
                title: "Status",
                dataIndex: "statuses",
                filters: [
                    {
                        text: 'Partially received',
                        value: 'Partially received',
                    },
                    {
                        text: 'Fully received',
                        value: 'Fully received',
                    },
                    {
                        text: 'Not received',
                        value: 'Not received',
                    },
                ],
                // filterMode: 'tree',
                filterSearch: true,
                onFilter: (value, record) => record.statuses.props.children.props.children.includes(value),
                width: '30%',
            },
            {
                title: "Confirm Goods-In Instructions",
                dataIndex: "action",
            }
        ];
        if (sessionData.role_id == 4 || sessionData.role_id == 2) {
            columns.splice(4, 1)
        }
        const columns_receive_products = [
            {
                title: "SNo",
                dataIndex: "key",
            },
            {
                title: "Product name",
                dataIndex: "product_name",
            },
            {
                title: "Instruction Quantity",
                dataIndex: "qty",
                render: (qty) => <Tag color="purple">{qty}</Tag>,
            },
            {
                title: "Received Quantity",
                dataIndex: "rec_qty",
                render: (rec_qty) => <Tag color="purple">{rec_qty}</Tag>,
            },
            {
                title: "Serial no.",
                dataIndex: "serial",
                render: (_, { serial }) => (
                    <>
                        {serial.map((serial) => {
                            let color = serial.length > 5 ? 'geekblue' : 'green';
                            return (
                                <Tag color={color} key={serial}>
                                    {serial.toUpperCase()}
                                </Tag>
                            );
                        })}
                    </>
                ),
            },
            {
                title: "Receive date",
                dataIndex: "confirm_date",
            },
            {
                title: "",
                dataIndex: "action",
            }
        ];
        const stock_pro = [
            {
                title: "SNo",
                dataIndex: "key",
            },
            {
                title: "Product name",
                dataIndex: "product_name",
            },
            {
                title: "Quantity",
                dataIndex: "qty",
                render: (qty) => <Tag color="purple">{qty}</Tag>,
            },
            {
                title: "Serial no.",
                dataIndex: "serial",
                render: (_, { serial }) => (
                    <>
                        {serial.map((serial) => {
                            let color = serial.length > 5 ? 'geekblue' : 'green';
                            return (
                                <Tag color={color} key={serial}>
                                    {serial.toUpperCase()}
                                </Tag>
                            );
                        })}
                    </>
                ),
            },
            {
                title: "Expected receiving date",
                dataIndex: "expected_date"
            },
            {
                title: "Relevant Information",
                dataIndex: "relevant_info"
            },
            {
                title: "Edit",
                dataIndex: "action",
            }
        ];
        confirm_stock_detail.map((csd, i) =>
            confirm_stock_detail_.push({
                qty: csd.qty,
                serial: (csd['serial']) ? csd['serial'].split(",") : [],
                confirm_date: csd.confirm_date,
                hardware_hash: csd.hardware_hash,
                hardware_asset_tag: csd.hardware_asset_tag,
                room_no: csd.room_no,
                rack_no: csd.rack_no,
                shelf_no: csd.shelf_no,
                id: csd.id,
                action: <a onClick={() => this.edit_confirm_product(csd.id)}>[Edit]</a>
            })
        );
        stock.map((drp, i) =>
            data_stock_pro.push({
                key: i + 1,
                product_name: drp['product_name'] + "-" + drp['sku'],
                warehouse: drp['warehouse'],
                qty: drp['qty'],
                serial: (drp['serial']) ? drp['serial'].split(",") : [],
                expected_date: (drp['confirm_date']) ? moment(drp['confirm_date']).format('DD-MM-YYYY') : '',
                relevant_info: drp['relevant_info'],
                action: (
                    <>
                        <Button
                            type="primary"
                            size="small"
                            ghost
                            icon={<EditOutlined />}
                            style={{ fontSize: "12px" }}
                            onClick={() => this.handleStockDetailEdit(drp['id'])}
                        >
                        </Button>
                    </>
                )
            })
        );
        confirm_stock.map((drp, i) =>
            data_receive_pro.push({
                key: i + 1,
                product_name: drp['product_name'] + "-" + drp['sku'],
                warehouse: drp['warehouse'],
                qty: drp['qty'],
                rec_qty: drp['received']['qty'],
                serial: (drp['received']['serial']) ? drp['received']['serial'].split(",") : [],
                confirm_date: (drp['received']['confirm_date']) ? moment(drp['received']['confirm_date']).format("DD-MM-YYYY") : '-',
                action: (
                    <>
                        <Button
                            type="primary"
                            size="small"
                            ghost
                            title="Confirm stock"
                            icon={<EditOutlined />}
                            style={{ fontSize: "12px" }}
                            onClick={() => this.handleConfirmStockDetail(drp['stock_id'], drp['sku'], drp.product_name, drp.qty)}
                        >
                        </Button>
                    </>
                )
            })
        );
        cart_in_confirm.map((pro) => {
            data_confirm_instruction.push({
                id: (<Button type="link" onClick={() => this.showConfirmedDetail(pro.id)}>{pro['instruction_id'] + "" + pro['id']}</Button>),
                instruction_id: pro.instruction_id,
                created_by: pro.created_by,
                qty: pro.qty,
                product: pro.product_name
            })
        })

        confirm_stock_for_mail.map((pro) => {
            data_confirm_stock_for_mail.push({
                key: pro.id,
                id: pro['instruction_id'] + "" + pro['id'],
                created_by: pro.created_by,
                qty: pro.qty,
                product: pro.product_name
            })
        })
        cart_in.map((pro, i) =>
            data.push({
                key: (
                    <Button
                        type="link"
                        size="small"
                        style={{ fontSize: "12px" }}
                        title="View CartIn"
                        onClick={() => this.openViewCartInDrawer(pro["id"])}
                    >
                        {pro["instruction_id"]}
                    </Button>
                ),
                customer_name: pro['customer_account_name'],
                created_by: pro['created_by'],
                order_date: moment(pro['order_date']).format("DD-MM-YYYY"),
                warehouse: (<Tag color="geekblue">{pro["warehouse"]}</Tag>),
                quantity: (<Tag color="#108ee9" style={{ fontSize: "17px", padding: "5px 10px" }}>{pro["quantity"]}</Tag>),
                action: (
                    <>
                        {(sessionData.role_id == 1 || sessionData.role_id == 3) && (
                            <Button
                                type="secondary"
                                onClick={() => this.openReceiveDrawer(pro["id"])}
                                size="small"
                                icon={<InteractionOutlined />}
                                style={{ fontSize: "12px" }}
                                title="Confirm Instruction"
                            > Confirm
                            </Button>
                        )}
                    </>
                ),
                statuses: (
                    <>
                        <Tag color={(pro['confirm_stock_qty'] == pro['stock_qty']) ? 'green' : (pro['confirm_stock_qty'] > 0) ? 'orange' : ''}>{(pro['confirm_stock_qty'] == pro['stock_qty']) ? 'Fully received' : (pro['confirm_stock_qty'] > 0) ? 'Partially received' : 'Not received'}</Tag>
                    </>
                )
            })
        );
        const onFinish = (values) => {
            values.order_date = moment(values.order_date).format("YYYY-MM-DD");
            values.customer_account = selectedCustomerAccount.id;
            values.warehouse_location = selectedWareHouse.id;
            fetch(`${BASE_URL}${API.ADD_CARTIN}`, {
                method: "POST",
                headers: {
                    Accept: "application/form-data",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: JSON.stringify(values),
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.status === true) {
                        notification.success({ message: json.success_msg });
                        this.handleCartList();
                        this.setState({ drawerVisible: false });
                    } else {
                        notification.error({ message: json.error_msg });
                    }
                });
        };
        const handleCartInUpdate = (values) => {
            this.setState({
                isCartInViewLoading: true
            })
            values.order_date = moment(values.order_date).format("YYYY-MM-DD");
            values.customer_account = this.state.cart_detail.customer_account;
            values.warehouse_location = this.state.cart_detail.warehouse_location;
            values.cart_id = this.state.cart_id;
            values.cart_type = 'cart_in';
            fetch(`${BASE_URL}${API.UPDATE_CARTIN}`, {
                method: "POST",
                headers: {
                    Accept: "application/form-data",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: JSON.stringify(values),
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.status === true) {
                        notification.success({ message: json.success_msg });
                        this.handleCartList();
                        this.setState({ drawerViewCartInVisible: false });
                    } else {
                        notification.error({ message: json.error_msg });
                    }
                    this.setState({
                        isCartInViewLoading: false
                    })
                });
        }
        const onFinishReceive = (values) => {
            this.setState({
                isonFinishReceive: true,
                isConfirmProductSaving: true
            })
            values.stock_id = this.state.stock_id;
            values.cart_id = this.state.cart_id;
            values.cart_type = 'cart_in';
            values.sku = this.state.selectedSkuId;
            values.confirm_stock_id = this.state.edit_confirmation_id;
            values.confirm_date = moment(values.confirm_date).format("YYYY-MM-DD");
            values.warehouse_location = selectedWareHouse.id;
            values.customer_account = selectedCustomerAccount.id;
            // if (this.state.confirm_stock_detail_id != 0) {
            //     values.confirm_stock_id = this.state.confirm_stock_detail_id;
            // }
            fetch(`${BASE_URL}${API.ADD_CONFIRM_STOCK}`, {
                method: "POST",
                headers: {
                    Accept: "application/form-data",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: JSON.stringify(values),
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.status === true) {
                        notification.success({ message: 'Success', description: json.success_msg });
                        this.setState({
                            drawerAddReceiveVisible: false,
                            openConfirmProductRec: false
                        });
                        //load mainRecieve drawer
                        this.handleCartDetail(this.state.cart_id);
                    } else {
                        notification.error({ message: 'Error', description: json.error_msg });
                    }
                    if (this.state.edit_confirmation_id != "") {
                        this.setState({
                            openConfirmProductRecEDIT: false,
                        })
                    }
                    this.setState({
                        isonFinishReceive: false,
                        isConfirmProductSaving: false
                    })
                });
        };

        const onFinishAddCartInProduct = (values) => {
            values.cart_id = this.state.cart_id;
            values.confirm_date = moment(values.confirm_date).format("YYYY-MM-DD");
            values.cart_type = 'cart_in';
            if (this.state.stock_detail_id != 0) {
                values.stock_id = this.state.stock_detail_id;
            }
            fetch(`${BASE_URL}${API.SAVE_CART_STOCK}`, {
                method: "POST",
                headers: {
                    Accept: "application/form-data",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: JSON.stringify(values),
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.status === true) {
                        notification.success({ message: 'Success', description: json.success_msg });
                        this.setState({ drawerAddCartInVisible: false });
                        //load mainRecieve drawer
                        this.handleCartViewDetail(this.state.cart_id);
                    } else {
                        notification.error({ message: 'Error', description: json.error_msg });
                    }
                });
        }
        return (
            <Headers
                bodyContain={
                    <>
                        <Row>
                            <Col md={17}>
                                <Breadcrumb>
                                    <Breadcrumb.Item>{selectedWareHouse.location_name}</Breadcrumb.Item>
                                    <Breadcrumb.Item>{selectedCustomerAccount.customer_account_name}</Breadcrumb.Item>
                                    <Breadcrumb.Item>Goods-In Instructions</Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                            {/* <Col md={1}><Button onClick={() => this.setState({ openSelectCustomerAccountWareHouse: true })} size="small" title="Switch Customer Account & Warehouse" type="warning" icon={<SwapOutlined />} style={{ marginBottom: "10px" }}></Button></Col> */}
                            <Col md={4}>

                                <Button
                                    onClick={this.addCartIn}
                                    type="primary"
                                    style={{ marginBottom: "10px" }}
                                    icon={<FileAddOutlined />}
                                    size="medium"

                                >
                                    Create a new Goods-In Instructions
                                </Button>
                            </Col>
                        </Row>
                        <div className="site-layout-background form-design">
                            <Row>
                                <h1>Goods-In Instructions List</h1>
                                <Col md={24}>
                                    <Table
                                        columns={columns}
                                        dataSource={data}
                                        size="small"
                                        bordered
                                        loading={isCartLoading}
                                    />
                                </Col>
                            </Row>
                            <Divider />

                            <Row>
                                <Col md={16}><h1>Goods-In Confirmation List</h1></Col>
                                <Col><Button icon={<DownloadOutlined />} onClick={() => window.location.href = BASE_URL + "" + API.GENERATE_REPORT + "?customer_account=" + this.state.selectedCustomerAccount.id + "&warehouse=" + this.state.selectedWareHouse.id + "&cart_type=cart_in"} type="primary" size="small" style={{ marginBottom: "10px" }}>Generate Goods-In Confirmation Report</Button></Col>
                            </Row>
                            <Row>
                                <Col md={24}>
                                    <Table
                                        columns={confirm_instruction}
                                        dataSource={data_confirm_instruction}
                                        size="small"
                                        bordered
                                        loading={isCartLoading}
                                    />
                                </Col>
                            </Row>
                            <Divider />
                            <Drawer title="Create Goods-In Instruction" placement="right" closable={true}
                                mask={true}
                                width={950}
                                visible={this.state.drawerVisible}
                                onClose={() => { this.setState({ drawerVisible: false }) }}
                                destroyOnClose>
                                <Card>
                                    <Form
                                        name="register"
                                        layout="vertical"
                                        onFinish={onFinish}
                                        scrollToFirstError
                                        ref={this.formRef}
                                        className="add_Cart_in"
                                    >

                                        <Row>
                                            <Col md={12}>Customer Account -  <Tag color="purple">{selectedCustomerAccount.customer_account_name}</Tag></Col>
                                            <Col md={12}>Warehouse -  <Tag color="green">{selectedWareHouse.location_name}</Tag></Col>
                                        </Row>
                                        <Divider />
                                        <Row>
                                            <Col sm={8}>
                                                <Form.Item
                                                    name="order_date"
                                                    label="Instruction date"
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "Please enter Instruction date!",
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker style={{ width: "100%" }}
                                                        onChange={this.onChange}
                                                        format="DD-MM-YYYY"
                                                        defaultValue={moment()} />
                                                </Form.Item>
                                            </Col>

                                            {/* <Col sm={8}>
                                                <Form.Item
                                                    name="customer_id"
                                                    label="Customer"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please select customer!",
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Select customer" onChange={this.fetchUserDetail}>
                                                        <Option value="">Select user</Option>
                                                        {customer_user.map((opt, i) => {
                                                            return <Option value={opt.user_id}>{opt.first_name + " " + opt.last_name}</Option>;
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col sm={8}>
                                                <Form.Item
                                                    name="phone"
                                                    label="Customer phone"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter customer phone!",
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col sm={12}>
                                                <Form.Item
                                                    name="email"
                                                    label="Customer email"
                                                    rules={[
                                                        {
                                                            type: "email",
                                                            required: true,
                                                            message: "Please enter correct customer email!",
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col> */}
                                            {/* <Col sm={6}>
                                                <Form.Item
                                                    name="warehouse_location"
                                                    label="Warehouse Location"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please select warehouse location!",
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Select warehouse location" onChange={this.setWareHouse}>
                                                        {warehouse.map((opt, i) => {
                                                            return <Option value={opt.id}>{opt.location_name}</Option>;
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col> */}
                                            <Divider />
                                            <Form.List name="items">
                                                {(fields, { add, remove }) => (
                                                    <>
                                                        {fields.map(({ key, name, ...restField }) => (
                                                            <Space key={key}>
                                                                <Row>
                                                                    {/* <Divider orientation="left" plain>
                                                                        Cart {key + 1}
                                                                    </Divider> */}
                                                                    <Col>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, "sku"]}
                                                                            label="Product and SKU"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Please enter product & SKU!",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Select
                                                                                showSearch
                                                                                style={{ width: 200 }}
                                                                                // onSearch={this.handleChangeSKU}
                                                                                size="small"
                                                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                            >
                                                                                {skus_options}
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, "qty"]}
                                                                            label="Quantity"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Please enter quantity!",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input
                                                                                placeholder="Quantity"
                                                                                size="small"
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, "serial"]}
                                                                            label="Serial numbers"
                                                                            rules={[
                                                                                {
                                                                                    required: false,
                                                                                    message: "Please enter serial numbers!",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Select
                                                                                size="small"
                                                                                mode="tags"
                                                                                style={{ width: "300px" }}
                                                                                tokenSeparators={[',']}
                                                                            >
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, "confirm_date"]}
                                                                            label="Expected receiving date"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Please enter expected receiving date!",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            {/* <Input
                                                                                placeholder="Serial numbers"
                                                                                size="small"
                                                                            /> */}
                                                                            <DatePicker size="small" style={{ width: "200px" }} />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[name, "relevant_info"]}
                                                                            label="Relevant information"
                                                                            rules={[
                                                                                {
                                                                                    required: false,
                                                                                    message: "Please enter relevant information",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input
                                                                                placeholder="Relevant information"
                                                                                size="small"
                                                                                style={{ width: "400px" }}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                                <Button type="link" style={{ top: "10px" }} danger icon={<MinusCircleOutlined />} onClick={() => remove(name)} />
                                                                {/* <PlusCircleOutlined onClick={() => add(name)} /> */}
                                                            </Space>
                                                        ))}
                                                        <Col md={24}>
                                                            <Form.Item>
                                                                <Button
                                                                    type="dashed-secondary"
                                                                    size="small"
                                                                    onClick={() => add()}
                                                                    block
                                                                    style={{ width: "100%" }}
                                                                    icon={<PlusOutlined />}
                                                                >
                                                                    Add Products
                                                                </Button>
                                                            </Form.Item>
                                                        </Col>
                                                    </>
                                                )}
                                            </Form.List>
                                        </Row>
                                        <Row>
                                            {/* new field addded */}
                                            <Col sm={12}>
                                                <Form.Item
                                                    name="assign_to"
                                                    label="Assign to"
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: "Please select a user to assign this task!",
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Select user" showSearch filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        {users_data.map((opt, i) => {
                                                            return <Option value={opt.id}>{opt.first_name + " " + opt.last_name}</Option>;
                                                        })}
                                                    </Select>
                                                </Form.Item>

                                            </Col>
                                            {/* new field addded */}
                                            <Col sm={24}>
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit" size="meduim" icon={<AppstoreAddOutlined />}>
                                                        Create a new Goods-In Intruction
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card>
                                <Divider />
                            </Drawer>
                            <Drawer title="Goods-In Confirmation" placement="right" closable={true}
                                mask={true}
                                width={800}
                                visible={this.state.drawerReceiveVisible}
                                onClose={() => { this.setState({ drawerReceiveVisible: false, drawerAddReceiveVisible: false }, () => { this.handleCartList(); }) }}
                                destroyOnClose
                                extra={
                                    <Space>
                                        <Button type="success" onClick={this.openModalConfirmationEmailUp}>
                                            Send Confirmation Email
                                        </Button>
                                    </Space>
                                }>

                                <Spin spinning={tableLoadingRecived}>
                                    <Row>
                                        <div className="cart_box">
                                            <div className="summary_box">
                                                <Col md={12}>
                                                    <div className="summary_box">
                                                        <p>Instruction Id</p>
                                                        <div style={{ width: "60%" }}>
                                                            <p><Tag color="gold">{cart_detail.instruction_id}</Tag></p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="summary_box">
                                                        <p>Instruction date</p>
                                                        <div style={{ width: "60%" }}>
                                                            <p><Tag color="gold">{moment(cart_detail.order_date).format("DD-MM-YYYY")}</Tag></p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </div>
                                            <div className="summary_box">

                                                <Col md={12}>
                                                    <div className="summary_box">
                                                        <p>Customer Account</p>
                                                        <div style={{ width: "60%" }}>
                                                            <p><Tag color="gold">{cart_detail.customer_account_name}</Tag></p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="summary_box">
                                                        <p>Created by</p>
                                                        <div style={{ width: "60%" }}>
                                                            <p><Tag color="gold">{cart_detail.created_by}</Tag></p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </div>
                                            <div className="summary_box">

                                                <Col md={12}>
                                                    <div className="summary_box">
                                                        <p>Warehouse</p>
                                                        <div style={{ width: "60%" }}>
                                                            <p><Tag color="gold">{cart_detail.warehouse}</Tag></p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </div>
                                        </div>
                                    </Row>
                                </Spin>
                                <Divider />
                                <Col md={24}>
                                    <Table
                                        dataSource={data_receive_pro}
                                        columns={columns_receive_products}
                                        pagination={false}
                                        tableLayout="auto"
                                        size="small"
                                        width="100%"
                                        bordered
                                        loading={tableLoadingRecived}
                                    />
                                </Col>
                                <Divider />


                                <Drawer title="Confirm Received Products" placement="right" closable={true}
                                    mask={true}
                                    width={950}
                                    visible={this.state.drawerAddReceiveVisible}
                                    onClose={() => { this.setState({ drawerAddReceiveVisible: false }) }}
                                    destroyOnClose
                                    extra={
                                        <Space>
                                            <Button onClick={() => { this.setState({ drawerAddReceiveVisible: false }) }}>Cancel</Button>
                                        </Space>
                                    }>


                                    <Row gutter={16}>
                                        <Col span={12}>
                                            Product name :
                                            <Tag color="gold">
                                                {drawerDataProduct_name}
                                            </Tag>
                                        </Col>
                                        <Col span={12}>
                                            Instruction Qty :
                                            <Tag color="purple">
                                                {drawerDataProduct_Qty}
                                            </Tag>
                                        </Col>
                                    </Row>
                                    <Divider />
                                    <Table
                                        columns={confirm_stock_rec_col}
                                        dataSource={confirm_stock_detail_}
                                        size="small"
                                        bordered
                                        loading={isConfirmRecProductsLoading}>

                                    </Table>
                                    <Divider />
                                    <Row gutter={16}>
                                        <Col md={24}>
                                            <Button type="primary" size="small" onClick={this.handleConfirmProductReceived} loading={isonFinishReceive} style={{ width: "100%" }} icon={<AppstoreAddOutlined />}>
                                                Confirm products received
                                            </Button>
                                        </Col>
                                    </Row>
                                    {/* </Form> */}
                                </Drawer>
                                {/* <Row>
                                    <Col sm={24}>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" size="meduim" icon={<AppstoreAddOutlined />}>
                                                Save
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row> */}
                            </Drawer>
                            {/* Add Cart In Detail Drawer */}
                            <Drawer title="Goods In - Instruction Detail" placement="right" closable={true}
                                mask={true}
                                width={950}
                                visible={this.state.drawerViewCartInVisible}
                                onClose={() => { this.setState({ drawerViewCartInVisible: false, drawerAddCartInVisible: false }) }}
                                destroyOnClose>
                                <Spin spinning={isCartInViewLoading}>
                                    <Form
                                        name="register"
                                        layout="vertical"
                                        onFinish={handleCartInUpdate}
                                        scrollToFirstError
                                        ref={this.formRefEdit}
                                        className="add_Cart_in"
                                    >
                                        <Row>
                                            <Col md={12}>Customer Account -  <Tag color="purple">{cart_detail.customer_account_name}</Tag></Col>
                                            <Col md={12}>Warehouse -  <Tag color="green">{cart_detail.warehouse}</Tag></Col>
                                        </Row>
                                        <Divider />
                                        <Row>
                                            <Col sm={6}>
                                                <Form.Item
                                                    name="order_date"
                                                    label="Instruction date"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter Instruction date!",
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker style={{ width: "100%" }}
                                                        format="DD-MM-YYYY" />
                                                </Form.Item>
                                            </Col>
                                            {/* <Col sm={6}>
                                                <Form.Item
                                                    name="customer_account"
                                                    label="Customer Account"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please select customer account!",
                                                        },
                                                    ]}

                                                >
                                                    <Select placeholder="Select customer account" onChange={this.fetchCustomerUser}>
                                                        <Option value="">Select customer account</Option>
                                                        {customer_account.map((opt, i) => {
                                                            return <Option value={opt.id}>{opt.customer_account_name}</Option>;
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Item
                                                    name="customer_id"
                                                    label="Customer"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please select customer!",
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Select customer" onChange={this.fetchUserDetail}>
                                                        <Option value="">Select user</Option>
                                                        {customer_user.map((opt, i) => {
                                                            return <Option value={opt.user_id}>{opt.first_name + " " + opt.last_name}</Option>;
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Item
                                                    name="phone"
                                                    label="Customer phone"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter customer phone!",
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Item
                                                    name="email"
                                                    label="Customer email"
                                                    rules={[
                                                        {
                                                            type: "email",
                                                            required: true,
                                                            message: "Please enter correct customer email!",
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Item
                                                    name="warehouse_location"
                                                    label="Warehouse Location"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please select warehouse location!",
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Select warehouse location">
                                                        {warehouse.map((opt, i) => {
                                                            return <Option value={opt.id}>{opt.location_name}</Option>;
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col> */}
                                        </Row>

                                        <Divider />
                                        <Row>
                                            <Col md={24}>
                                                <Table
                                                    dataSource={data_stock_pro}
                                                    columns={stock_pro}
                                                    pagination={false}
                                                    tableLayout="auto"
                                                    size="small"
                                                    width="100%"
                                                    bordered
                                                />
                                            </Col>
                                            <Col md={24}>
                                                <Form.Item>
                                                    <Button
                                                        type="dashed-secondary"
                                                        size="small"
                                                        onClick={() => this.setState({ drawerAddCartInVisible: true })}
                                                        block
                                                        style={{ width: "100%" }}
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Add Products
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Drawer title="Add Products" placement="right" closable={true}
                                            mask={true}
                                            width={500}
                                            visible={this.state.drawerAddCartInVisible}
                                            onClose={() => { this.setState({ drawerAddCartInVisible: false }) }}
                                            destroyOnClose
                                            extra={
                                                <Space>
                                                    <Button onClick={() => { this.setState({ drawerAddCartInVisible: false }) }}>Cancel</Button>
                                                </Space>
                                            }>
                                            <Form
                                                name="register"
                                                layout="vertical"
                                                onFinish={onFinishAddCartInProduct}
                                                scrollToFirstError
                                                ref={this.formRefEditStock}
                                                className=""
                                            >
                                                <Row gutter={16}>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            name="sku"
                                                            label="Product and SKU"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Please enter product & SKU!",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                // style={{ width: 200 }}
                                                                // onSearch={this.handleChangeSKU}
                                                                placeholder="Products & SKU"
                                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                            >
                                                                {skus_options}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            name="qty"
                                                            label="Quantity"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter Quantity',
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                placeholder="Quantity"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={16}>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            name="serial"
                                                            label="Serial numbers"
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: "Please enter serial numbers!",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                mode="tags"
                                                                // style={{ width: "300px" }}
                                                                tokenSeparators={[',']}
                                                            >
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            name="confirm_date"
                                                            label="Expected receiving date"
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: "Please enter expected receiving date!",
                                                                },
                                                            ]}
                                                        >

                                                            <DatePicker style={{ width: "100%" }}
                                                                format="DD-MM-YYYY" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={16}>

                                                </Row>
                                                <Row gutter={16}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="relevant_info"
                                                            label="relevant Information"
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message: "Please enter relevant information!",
                                                                },
                                                            ]}
                                                        >
                                                            <TextArea />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={16}>
                                                    <Col md={24}>
                                                        <Button type="primary" htmlType="submit" size="meduim" style={{ width: "100%" }} icon={<AppstoreAddOutlined />}>
                                                            Save
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Drawer>
                                        <Row>
                                            <Col sm={24}>
                                                <Form.Item>
                                                    <Button type="primary" size="meduim" htmlType="submit" icon={<AppstoreAddOutlined />}>
                                                        Save Intruction
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Spin>
                            </Drawer>
                            {/* Add cart in detail */}
                            {/* Modal for selecting customer account and warehouse */}
                            <Modal
                                title="Select Customer Account & WareHouse"
                                style={{ top: 20 }}
                                visible={openSelectCustomerAccountWareHouse}
                                width={600}
                                onCancel={() => this.setState({ openSelectCustomerAccountWareHouse: false })}
                                footer={[
                                    <Button
                                        key="cancel"
                                        type="default"
                                        loading=""
                                        onClick={() => this.setState({ openSelectCustomerAccountWareHouse: false })}
                                    >
                                        Cancel
                                    </Button>,
                                ]}>
                                <Form
                                    name="select_customer_account_warehouse"
                                    layout="vertical"
                                    onFinish={this.handleSelectCustomerAccount}
                                    scrollToFirstError
                                >
                                    <Row>
                                        <Col md={24}>
                                            <Form.Item
                                                name="customer_account"
                                                label="Customer account"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select customer account name!",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    allowClear={true}

                                                >
                                                    {customer_account.map((ca) => {
                                                        return (
                                                            <Option key={ca.id} value={ca.id}>
                                                                {ca.customer_account_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                name="warehouse"
                                                label="Warehouse"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select warehouse!",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    allowClear={true}

                                                >
                                                    {warehouse.map((opt) => {
                                                        return (
                                                            <Option value={opt.id}>{opt.location_name}</Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={24}>
                                            <Form.Item>
                                                <Button
                                                    type="info"
                                                    htmlType="submit"
                                                    size="medium"
                                                    loading={false}
                                                >
                                                    Continue
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Modal>
                            {/* Modal for selecting customer account and warehouse */}

                            <Modal
                                title="Add confirm product received"
                                style={{ top: 20 }}
                                visible={openConfirmProductRec}
                                width={600}
                                destroyOnClose
                                onCancel={() => this.setState({ openConfirmProductRec: false })}
                                footer={[
                                    <Button
                                        key="cancel"
                                        type="default"
                                        loading=""
                                        onClick={() => this.setState({ openConfirmProductRec: false })}
                                    >
                                        Cancel
                                    </Button>,
                                ]}>
                                <Form
                                    name="onFinishReceive_COL"
                                    layout="vertical"
                                    onFinish={onFinishReceive}
                                    scrollToFirstError
                                >
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Item
                                                name="qty"
                                                label="Quantity"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter product quantity!",
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col sm={12}>
                                            <Form.Item
                                                name="serial"
                                                label="Serial"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter serial!",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    mode="tags"
                                                    tokenSeparators={[',']}
                                                >
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Item
                                                name="confirm_date"
                                                label="Received date"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter received date!",
                                                    },
                                                ]}
                                            >
                                                <DatePicker format={"DD-MM-YYYY"} />
                                            </Form.Item>
                                        </Col>
                                        <Col sm={12}>
                                            <Form.Item
                                                name="hardware_hash"
                                                label="Hardware hash number"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter Hardware hash numbern!",
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col sm={12}>
                                            <Form.Item
                                                name="hardware_asset_tag"
                                                label="Hardware asset tag"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter Hardware asset tag!",
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col sm={12}>
                                            <Form.Item
                                                name="room_no"
                                                label="Room no"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter room number.",
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col sm={12}>
                                            <Form.Item
                                                name="rack_no"
                                                label="Rack no"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter Rack number!",
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col sm={12}>
                                            <Form.Item
                                                name="shelf_no"
                                                label="Shelf no"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter shelf number!",
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={24}>
                                            <Form.Item>
                                                <Button
                                                    type="secondary"
                                                    htmlType="submit"
                                                    size="medium"
                                                    loading={isConfirmProductSaving}
                                                >
                                                    Save
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Modal>

                            {/* Modal for edit confirm product received */}
                            <Modal
                                title="Edit confirm product received"
                                style={{ top: 20 }}
                                visible={openConfirmProductRecEDIT}
                                width={600}
                                destroyOnClose
                                onCancel={() => this.setState({ openConfirmProductRecEDIT: false })}
                                footer={[
                                    <Button
                                        key="cancel"
                                        type="default"
                                        loading=""
                                        onClick={() => this.setState({ openConfirmProductRecEDIT: false })}
                                    >
                                        Cancel
                                    </Button>,
                                ]}>
                                <Form
                                    name="onFinishReceive_COL"
                                    layout="vertical"
                                    onFinish={onFinishReceive}
                                    scrollToFirstError
                                    ref={this.editFormConfirm}
                                >
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Item
                                                name="qty"
                                                label="Quantity"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter product quantity!",
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col sm={12}>
                                            <Form.Item
                                                name="serial"
                                                label="Serial"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter serial!",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    mode="tags"
                                                    tokenSeparators={[',']}
                                                >
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Item
                                                name="confirm_date"
                                                label="Received date"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter received date!",
                                                    },
                                                ]}
                                            >
                                                <DatePicker format={"DD-MM-YYYY"} />
                                            </Form.Item>
                                        </Col>
                                        <Col sm={12}>
                                            <Form.Item
                                                name="hardware_hash"
                                                label="Hardware hash number"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter Hardware hash numbern!",
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col sm={12}>
                                            <Form.Item
                                                name="hardware_asset_tag"
                                                label="Hardware asset tag"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter Hardware asset tag!",
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col sm={12}>
                                            <Form.Item
                                                name="room_no"
                                                label="Room no"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter room number.",
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col sm={12}>
                                            <Form.Item
                                                name="rack_no"
                                                label="Rack no"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter Rack number!",
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col sm={12}>
                                            <Form.Item
                                                name="shelf_no"
                                                label="Shelf no"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Please enter shelf number!",
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={24}>
                                            <Form.Item>
                                                <Button
                                                    type="secondary"
                                                    htmlType="submit"
                                                    size="medium"
                                                    loading={isConfirmProductSaving}
                                                >
                                                    Save
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Modal>
                            {/* end Modal for edit confirm product received*/}
                            {/* modal for confimration email */}
                            <Modal
                                title="Confirmation Email"
                                style={{ top: 20 }}
                                visible={openModalConfirmationEmail}
                                width={900}
                                destroyOnClose
                                onCancel={() => this.setState({ openModalConfirmationEmail: false })}
                                footer={[
                                    <Button
                                        key="cancel"
                                        type="default"
                                        loading=""
                                        onClick={() => this.setState({ openModalConfirmationEmail: false })}
                                    >
                                        Cancel
                                    </Button>,
                                ]}>
                                <Table
                                    rowSelection={rowSelection}
                                    columns={confirm_instruction_mail}
                                    dataSource={data_confirm_stock_for_mail}
                                    size="small"
                                    bordered
                                    loading={isCartLoading}
                                    pagination={false}
                                />
                                <Button
                                    key="send_email"
                                    type="primary"
                                    loading=""
                                    onClick={this.send_email}
                                    style={{
                                        marginTop: "10px",
                                        float: "right",
                                        marginBottom: "20px"
                                    }}
                                >
                                    Send Email
                                </Button>,
                            </Modal>
                            {/* end confirmation email */}
                            <Drawer title="Goods-In Confirmation Detail" placement="top" closable={true}
                                mask={true}
                                height={"auto"}
                                visible={this.state.ConfirmDetailDrawer}
                                onClose={() => { this.setState({ ConfirmDetailDrawer: false }) }}
                                destroyOnClose>

                                <Row>
                                    <div className="cart_box">
                                        <div className="summary_box">
                                            <Col md={8}>
                                                <div class="summary_box">
                                                    <p>Instruction Id</p>
                                                    <div style={{ width: "60%" }}>
                                                        <p><Tag color="gold">{ConfirmDetail.instruction_id}</Tag></p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={8}>
                                                <div class="summary_box">
                                                    <p>Confirmation Id</p>
                                                    <div style={{ width: "60%" }}>
                                                        <p><Tag color="gold">{ConfirmDetail.instruction_id}{ConfirmDetail.id}</Tag></p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={8}>
                                                <div class="summary_box">
                                                    <p>Received date</p>
                                                    <div style={{ width: "60%" }}>
                                                        <p><Tag color="gold">{(ConfirmDetail.confirm_date) ? moment(ConfirmDetail.confirm_date).format("DD-MM-YYYY") : ''}</Tag></p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </div>
                                        <div className="summary_box">
                                            <Col md={8}>
                                                <div class="summary_box">
                                                    <p>Hardware asset tag</p>
                                                    <div style={{ width: "60%" }}>
                                                        <p><Tag color="gold">{ConfirmDetail.hardware_asset_tag}</Tag></p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={8}>
                                                <div class="summary_box">
                                                    <p>Room no.</p>
                                                    <div style={{ width: "60%" }}>
                                                        <p><Tag color="gold">{ConfirmDetail.room_no}</Tag></p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={8}>
                                                <div class="summary_box">
                                                    <p>Rack no</p>
                                                    <div style={{ width: "60%" }}>
                                                        <p><Tag color="gold">{ConfirmDetail.rack_no}</Tag></p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </div>
                                        <div className="summary_box">
                                            <Col md={8}>
                                                <div class="summary_box">
                                                    <p>Shelf no</p>
                                                    <div style={{ width: "60%" }}>
                                                        <p><Tag color="gold">{ConfirmDetail.shelf_no}</Tag></p>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col md={8}>
                                                <div class="summary_box">
                                                    <p>Hardware hash</p>
                                                    <div style={{ width: "60%" }}>
                                                        <p><Tag color="gold">{ConfirmDetail.hardware_hash}</Tag></p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </div>
                                    </div>
                                </Row>

                            </Drawer>
                        </div>
                    </>
                }
            />
        );
    }
}
export default withRouter(CartIn);
