import React from "react";
import Headers from "./component/Headers";
import { BASE_URL, API } from "../Endpoint";
import {
  Row,
  Col,
  Table,
  Button,
  notification,
  Spin,
  Card,
  Input,
  message,
  DatePicker,
  Select,
  Tag,
} from "antd";
import { withRouter } from "react-router-dom";
import { token } from "../Helper/Session";
import { ExportOutlined } from "@ant-design/icons";

import Form from "../Helper/Form";

import moment from "moment";

const { Search } = Input;
class OmsSo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      so: [],
      isLoadingRequestList: true,
      isSearching: false,
    };
  }

  formRef = React.createRef();

  componentDidMount() {
    fetch(`${BASE_URL}${API.GET_OMS_SO}`, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            so: json.data,
            isLoadingRequestList: false,
          });
        } else {
          notification.error({ message: json.error_msg });
        }
      });
  }

  render() {
    const { so, isLoadingRequestList, isSearching, customer_account } =
      this.state;

    var data = [];
    const columns = [
      {
        title: "SNo",
        dataIndex: "key",
      },
      {
        title: "SO Number",
        dataIndex: "so_number",
      },
      {
        title: "PO number",
        dataIndex: "po_number",
      },
      {
        title: "Item description",
        dataIndex: "item_desc",
      },
      {
        title: "Account manager",
        dataIndex: "account_manager",
      },
      {
        title: "Customer account",
        dataIndex: "customer_account",
      },
      {
        title: "Delivery address",
        dataIndex: "delivery_address",
      },
      {
        title: "Order date",
        dataIndex: "order_date",
      },
      {
        title: "View",
        dataIndex: "action",
      },
    ];
    so.map((sr, i) =>
      data.push({
        key: i + 1,
        id: sr["id"],
        so_number: sr["so_number"],
        po_number: sr["po_number"],
        // po_id: sr["po_id"],
        item_desc: sr["item_desc"],
        account_manager: sr["account_manager"],
        customer_account: sr["customer_account"],
        delivery_address: sr["delivery_address"],
        order_date: sr["order_date"]
          ? moment(sr["order_date"]).format("DD-MM-YYYY")
          : "",
        action: (
          <Button
            type="primary"
            onClick={() => {
              this.props.history.push("/oms-so-detail/" + sr["so_number"]);
            }}
          >
            Map
          </Button>
        ),
      })
    );

    return (
      <Headers
        bodyContain={
          <>
            <h3 className="title">All Shipping SO</h3>
            <div className="site-layout-background">
              <Spin spinning={isLoadingRequestList}>
                <Row>
                  <Col md={24}>
                    <Table
                      columns={columns}
                      dataSource={data}
                      size="middle"
                      bordered
                      loading="false"
                    />
                  </Col>
                </Row>
              </Spin>
            </div>
          </>
        }
      />
    );
  }
}
export default withRouter(OmsSo);
