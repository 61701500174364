import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import { Row, Col, Table, Button, Switch, notification, Modal, Form, Select, Tag } from "antd";
import { withRouter } from "react-router-dom";
import { token } from "../../Helper/Session";
import { FolderAddOutlined } from "@ant-design/icons";

class LogisticOperator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            user_role: 5,
            customer: [],
            customer_account: [],
            openAssignWareHouseModal: false,
            selectedUserId: "",
            location: [],
            isLoading: true
        };
    }

    goToAddUser = () => {
        this.props.history.push("/add-user");
    };

    handleStatus = (user_id, v) => {
        if (v === true) {
            fetch(`${BASE_URL}${API.UPDATE_USER_STATUS}`, {
                method: "POST",
                headers: {
                    Accept: "application/form-data",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify({ status: 1, user_id: user_id }),
            });
        } else if (v === false) {
            fetch(`${BASE_URL}${API.UPDATE_USER_STATUS}`, {
                method: "POST",
                headers: {
                    Accept: "application/form-data",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify({ status: 0, user_id: user_id }),
            });
        }
    };

    handleAssignWareHouse = (value) => {
        var assign_data = {
            warehouse_id: value.warehouse_id,
            user_id: this.state.selectedUserId,
        };
        fetch(`${BASE_URL}${API.ASSIGN_WAREHOUSE}`, {
            method: "POST",
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(assign_data),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    notification.success({ message: json.success_msg, placement: "top" });
                    this.setState({ openAssignWareHouseModal: false });
                    this.formRef.current.setFieldsValue({
                        warehouse_id: [],
                    });
                } else {
                    notification.error({ message: json.error_msg, placement: "top" });
                }
            });
    };

    loadAssignWareHouse = (user_id) => {
        fetch(`${BASE_URL}${API.GET_ASSIGN_WAREHOUSE}`, {
            method: "POST",
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({ user_id: user_id }),
        })
            .then((res) => res.json())
            .then((json) => {
                this.setState({ openAssignUserModal: true });
                if (json.status == true) {
                    this.formRef.current.setFieldsValue({
                        warehouse_id: json.data,
                    });
                } else {
                    this.formRef.current.setFieldsValue({
                        warehouse_id: []
                    });
                }
            });
    };

    formRef = React.createRef();
    componentDidMount() {
        var user_role = this.state.user_role;
        if (typeof user_role === "undefined" || user_role === null) {
            user_role = 0;
        }
        fetch(`${BASE_URL}${API.USERLISTING}?user_role=${user_role}`, {
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        users: json.data,
                    });
                    notification.success({ message: json.success_msg, duration: 2 });
                } else {
                    notification.error({ message: json.error_msg, duration: 2 });
                }
                this.setState({ isLoading: false })
            });

        fetch(`${BASE_URL}${API.WAREHOUSE}`, {
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        warehouse: json.data,
                    });
                }
            });
    }

    render() {
        const { users, customer, customer_account, openAssignWareHouseModal, isLoading } = this.state;
        const { Option } = Select;
        // console.log(user);
        var data = [];
        const columns = [
            {
                title: "SNo",
                dataIndex: "key",
            },
            {
                title: "Name",
                dataIndex: "name",
            },
            {
                title: "Email",
                dataIndex: "email",
            },
            {
                title: "Contact",
                dataIndex: "mobile",
            },
            {
                title: "Status",
                dataIndex: "status",
            },
            // {
            //     title: "Customer Account",
            //     dataIndex: "customer_account",
            //     render: (_, { customer_account }) => (
            //         <>
            //             {customer_account.map((customer_account) => {
            //                 return (
            //                     <Tag color="geekblue" key={customer_account}>
            //                         {customer_account.toUpperCase()}
            //                     </Tag>
            //                 );
            //             })}
            //         </>
            //     ),
            // },
            {
                title: "Action",
                dataIndex: "action",
            },
        ];
        users.map((usr, i) =>
            data.push({
                key: i + 1,
                name: usr["first_name"] + " " + usr["last_name"],
                email: usr["email"],
                mobile: usr["mobile"] !== "" ? usr["mobile"] : "",
                status: (
                    <Switch
                        size="default"
                        checkedChildren="Active"
                        unCheckedChildren="InActive"
                        defaultChecked={usr["status"] == 1 ? true : false}
                        onChange={this.handleStatus.bind(this, usr["id"])}
                        style={{
                            width: "80px",
                        }}
                    />
                ),
                location: (usr['warehouse']) ? usr['warehouse'].split(",") : [],
                action: (
                    <>
                        <Button
                            type="primary"
                            size="medium"
                            style={{ background: "#ffc107", border: "none" }}
                            onClick={() => this.props.history.push("users/edit/" + usr["id"])}
                        >
                            Edit
                        </Button>
                        {/* <Button
                            type="primary"
                            size="medium"
                            title="Assign Customer Account"
                            icon={<FolderAddOutlined />}
                            onClick={() => {
                                this.setState({
                                    openAssignWareHouseModal: true,
                                    selectedUserId: usr["id"]
                                });
                                this.loadAssignWareHouse(usr['id'])
                            }
                            }
                        >

                        </Button> */}
                    </>
                ),
            })
        );

        return (
            <Headers
                bodyContain={
                    <>
                        <Row>
                            <Col offset={21} md={2}>
                                <Button
                                    onClick={this.goToAddUser.bind(this)}
                                    type="primary"
                                    style={{ marginBottom: "10px" }}
                                    shape="round"
                                >
                                    Add User
                                </Button>
                            </Col>
                        </Row>
                        <div className="site-layout-background">
                            <Row>
                                {/* <h1>Logistic Operator</h1> */}
                                <Col md={24}>
                                    <Table
                                        columns={columns}
                                        dataSource={data}
                                        size="middle"
                                        bordered
                                        loading={isLoading}
                                    />
                                </Col>
                            </Row>
                            {/* <Modal
                                title="Assign WareHouse"
                                style={{
                                    top: 20,
                                }}
                                visible={openAssignWareHouseModal}
                                width={600}
                                onCancel={() => {
                                    this.setState({
                                        openAssignWareHouseModal: false,
                                    });
                                }}
                                footer={[
                                    <Button
                                        key="cancel"
                                        type="default"
                                        loading=""
                                        onClick={() => this.setState({ openAssignWareHouseModal: false })}
                                    >
                                        Cancel
                                    </Button>,
                                ]}
                            >
                                <Form
                                    name="assign_customer_account"
                                    layout="vertical"
                                    onFinish={this.handleAssignWareHouse}
                                    scrollToFirstError
                                    ref={this.formRef}
                                >
                                    <Row>
                                        <Col md={24}>
                                            <Form.Item
                                                name="warehouse_id"
                                                label="Select Warehouse"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select warehouse location!",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    allowClear={true}
                                                    mode="multiple"
                                                    size="large"
                                                    // value={selectedValues}
                                                    autoClearSearchValue={true}
                                                    filterOption={(input, option) =>
                                                        option.props.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0 ||
                                                        option.props.value
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {customer.map((wh) => {
                                                        return (
                                                            <Option key={wh.id} value={wh.id}>
                                                                {wh.location_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col md={24}>
                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    size="medium"
                                                    loading={false}
                                                >
                                                    Save Warehouse
                                                </Button>
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                </Form>
                            </Modal> */}
                        </div>
                    </>
                }
            />
        );
    }
}
export default withRouter(LogisticOperator);
