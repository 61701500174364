
import React from 'react';
import Routers from './Router/Routers';
import ReactDOM from 'react-dom';
import "antd/dist/antd.css";
import "./index.css";

import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//     <React.StrictMode>
//         <Routers />
//     </React.StrictMode>
// );

ReactDOM.render(<React.StrictMode><Routers /></React.StrictMode>, document.getElementById('root'));

reportWebVitals();
