import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import {
    Row,
    Col,
    Button,
    Input,
    Select,
    Divider,
    notification,
    Drawer,
    Card,
    Table,
    Tag,
    Space,
    DatePicker,
    Spin,
    Popconfirm,
    Modal,
    Breadcrumb,
} from "antd";
import { LogoutOutlined, LoginOutlined } from "@ant-design/icons";
import Form from "../../Helper/Form";
import { sessionData, token } from "../../Helper/Session";
import { withRouter } from "react-router-dom";

import TextArea from "antd/lib/input/TextArea";
import { AppstoreAddOutlined, DeleteOutlined, EditOutlined, DownloadOutlined } from '@ant-design/icons';
import "antd/dist/antd.css";
import moment from "moment";
import { Excel } from "antd-table-saveas-excel";
import { icons } from "antd/lib/image/PreviewGroup";
const { Search } = Input;
class Search_SKU extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cart_out: [],
            product_detail: [],
            category: [],
            products: [],
            warehouse: [],
            id: props.id,
            drawerVisible: false,
            visibleStock: "none",
            items: [],
            skus: [],
            cart_detail: [],
            cart_id: 0,
            confirm_stock: [],
            drawerAddFullfillVisible: false,
            tableLoadingRecived: false,
            confirm_stock_detail: [],
            confirm_stock_detail_id: 0,
            drawerViewFullfillVisible: [],
            draweraddCartOutVisible: false,
            drawerViewCartOutVisible: false,
            stock_detail: [],
            stock_detail_id: 0,
            stock: [],
            customer_user: [],
            customer_account: [],
            ship_to_address: "",
            isCartUpdate: false,
            isLoading: false,
            isViewCarOutLoading: false,
            showPpopconfirm: false,
            openSelectCustomerAccountWareHouse: false,
            selectedCustomerAccount: 0,
            selectedWareHouse: 0,
            selectedSkuId: 0,
            cart_out_confirm: [],
            isCartLoading: false,
            serials: [],
            isFullFilldetailLoading: false,
            isConfirmProductsLoading: false,
            isConfirmProductsSaving: false,
            ConfirmDetail: [],
            drawerData_productName: "",
            drawerData_productQty: "",
            available_qty: [],
            selectedRowKeys: "",
            selectedRowKeys2: "",
            openModalConfirmationEmail: false,
            confirm_stock_for_mail: [],
            keyword: "",
        };
    }
    formRef = React.createRef();
    formRefR = React.createRef();
    formRefFR = React.createRef();
    formRefEdit = React.createRef();
    formRefEditStock = React.createRef();

    componentDidMount() {

    }

    searchKeyword = (value) => {
        value = (value) ? value : "";
        this.setState({
            isLoading: true,
            keyword: value
        });
        const dta = {
            key: value,
            module: 'sku'
        }
        fetch(`${BASE_URL}${API.SEARCH_SKU}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(dta)
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status == true) {
                    this.setState({
                        cart_out: json.data,
                    });
                } else {
                    notification.error({ message: json.error_msg });
                }
                this.setState({
                    isLoading: false,
                });
            });
    }


    render() {
        const { Option } = Select;
        const { confirm_stock_for_mail, openModalConfirmationEmail, selectedRowKeys, selectedRowKeys2, warehouse, cart_out, cart_detail, skus, confirm_stock, selectedSkuId, tableLoadingRecived, isFullFilldetailLoading, drawerViewCartOutVisible, showPpopconfirm, isViewCarOutLoading, stock, customer_account, customer_user, isLoading, openSelectCustomerAccountWareHouse, selectedCustomerAccount, selectedWareHouse, cart_out_confirm, isCartLoading, serials, isConfirmProductsLoading, isConfirmProductsSaving, ConfirmDetail, drawerData_productQty, drawerData_productName, available_qty } = this.state;
        const skus_options = skus.map(d => <Option key={d.id} value={d.id}>{d.product_name}</Option>);
        const serial_options = serials.map(d => <Option key={d.id} value={d.id}>{d.id}</Option>);
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const rowSelection2 = {
            selectedRowKeys2,
            onChange: this.onSelectChange,
        };
        var data = [];
        var data_Fullfill_pro = [];
        var data_stock_pro = [];
        var data_confirm_instruction = [];
        var data_confirm_stock_for_mail = [];
        var serial_options_data = [];
        var data_ = [];
        const handlePrint = () => {
            cart_out.map((pro, i) =>
                data_.push({
                    id: pro.instruction_id,
                    cart_type: (pro.cart_type == "cart_in") ? "Goods In" : "Goods Out",
                    customer_name: pro.customer_name,
                    created_by: pro['created_by'],
                    order_date: (pro['confirm_date']) ? moment(pro['confirm_date']).format("DD-MM-YYYY") : '',
                    serial: pro['serial'],
                    qty: pro.qty,
                    sku: pro.sku
                })
            );
            if (data_.length == 0) {
                notification.error({ message: "Please enter keyword to search!" });
                return false;
            }
            const excel = new Excel();
            excel
                .addSheet("Available Serial")
                .addColumns(columns_)
                .addDataSource(data_, {
                    str2Percent: true,
                })
                .saveAs("OutPut-" + this.state.keyword + ".xlsx");
        };
        const columns = [
            {
                title: "Instruction Id",
                dataIndex: "id",
            },
            { title: "SKU", dataIndex: "sku" },
            {
                title: "Order type",
                dataIndex: "cart_type",
            },
            { title: "Qty", dataIndex: 'qty' },
            {
                title: "Serial no.",
                dataIndex: "serial",
                render: (_, { serial }) => (
                    <>
                        {serial.map((serial) => {
                            let color = serial.length > 5 ? 'geekblue' : 'green';
                            return (
                                <Tag color={color} key={serial}>
                                    {serial.toUpperCase()}
                                </Tag>
                            );
                        })}
                    </>
                ),
            },

            {
                title: "Confirm by",
                dataIndex: "created_by",
            },
            {
                title: "Confirm date",
                dataIndex: "order_date",
            },
        ];

        const columns_ = [
            {
                title: "Instruction Id",
                dataIndex: "id",
            },
            { title: "SKU", dataIndex: "sku" },
            {
                title: "Order type",
                dataIndex: "cart_type",
            },
            { title: "Qty", dataIndex: 'qty' },
            {
                title: "Serial no.",
                dataIndex: "serial",
            },

            {
                title: "Confirm by",
                dataIndex: "created_by",
            },
            {
                title: "Confirm date",
                dataIndex: "order_date",
            },
        ];


        cart_out.map((pro, i) =>
            data.push({
                id: pro.instruction_id,
                cart_type: (pro.cart_type == "cart_in") ? <Tag color="success">Goods In <LoginOutlined /></Tag> : <Tag color="warning">Goods Out <LogoutOutlined /></Tag>,
                customer_name: pro.customer_name,
                created_by: pro['created_by'],
                order_date: (pro['confirm_date']) ? moment(pro['confirm_date']).format("DD-MM-YYYY") : '',
                serial: (pro['serial']) ? pro['serial'].split(",") : [],
                qty: pro.qty,
                sku: pro.sku
            })
        );

        return (
            <Headers
                bodyContain={
                    <>

                        <div className="site-layout-background form-design">

                            <Spin spinning={isLoading}>
                                <Row>
                                    <Col md={6}>
                                        <h1>Search SKU or Serial Activity</h1>
                                    </Col>
                                    <Col md={6} style={{ "marginBottom": "10px", "marginTop": "-5px" }}>

                                        <Search className="search" onSearch={this.searchKeyword} placeholder="Search" style={{ "width": "100%" }} />

                                    </Col>
                                    <Col style={{ "marginBottom": "10px", "marginTop": "-5px" }}><Button type="primary" icon={<DownloadOutlined />} onClick={handlePrint}>Download</Button></Col>
                                    <Col md={24}>
                                        <Table
                                            columns={columns}
                                            dataSource={data}
                                            size="small"
                                            bordered
                                            loading={isLoading}
                                            // pageSize="10"
                                            pagination={false}
                                        />
                                    </Col>
                                </Row>

                            </Spin>

                        </div>
                    </>
                }
            />
        );
    }
}
export default withRouter(Search_SKU);
