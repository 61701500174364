import { site_url, base_url } from "./urls";

export const BASE_URL = base_url;
export const SITE_URL = site_url;

export const API = {
  AUTHORIZATION: "authorization",
  LOGIN: "login",
  DASHBOARD: "dashboard/",
  DASHBOARD_GET_PRODUCTS: "Api/dashboard_product",
  USERLISTING: "users/",
  ADDUSER: "user/create/",
  UPDATE_USER_STATUS: "Api/update_user_status",
  CUSTOMER_ACCOUNT_LIST: "Api/customer_account",
  GET_CUSTOMER_ACCOUNT_LIST: "Api/customer_account_list",
  CUSTOMER_USER_LIST: "Api/list_customer_user",
  CUSTOMER_ACCOUNT_DETAIL: "Api/customer_account_detail/",
  ADD_CUSTOMER_ACCOUNT: "Api/add_customer_account",
  UPDATE_CUSTOMER_ACCOUNT: "Api/update_customer_account",
  ADD_PRODUCT: "Api/add_product",
  PRODUCT_DETAIL: "Api/product_detail",
  USER_DETAIL: "Api/user_detail/",
  SAVE_USER: "Api/edit_user",
  ROLE_LIST: "Api/user_role",
  ASSIGN_CUSTOMER_ACCOUNT: "Api/assign_customer_user",
  ASSIGN_MEMBER: "Api/assign_warehouse_member",
  ASSIGN_WAREHOUSE: "Api/assign_warehouse",
  GET_ASSIGN_CUSTOMER_ACCOUNT: "Api/assign_customer_account",
  GET_ASSIGN_WAREHOUSE: "Api/assign_warehouses",
  GET_ASSIGN_MEMBER: "Api/assign_member",
  WAREHOUSE_MENU: "Api/warehouse_menu",
  CUSTOMER_USER_FOR_CUSTOMER_ACCOUNT: "Api/assign_customer_on_customer_account",
  UPLOAD_DOCS: "Api/upload_docs",
  SHIPMENT_TRACKING: "Api/shipment_track",
  GENERATE_REPORT: "export/goods_in_confirmation_report/",
  GENERATE_INVENTORY_REPORT: "export/inventory_report/",
  UPDATE_COLLECTION_LOGS: "Api/update_collection_fail_logs",
  GET_COLLECTION_LOGS: "Api/get_collection_fail_logs",
  UPDATE_DELIVERY_LOGS: "Api/update_delivery_fail_logs",
  GET_DELIVERY_LOGS: "Api/get_delivery_fail_logs",
  GET_OMS_SO: "Api/list_all_so",
  OMS_SO_DETAIL: "Api/so_detail",
  SHIPMENT_BY_CUSTOMER_ACCOUNT: "Api/shipment_by_customer_account",
  MAP_SHIPMENT: "Api/map_shipment",
  DELETE_PROPERTY: "Api/delete_property",
  ADD_SISL_ENTITY: "Api/add_sisl_entity",
  SISL_ENTIIY: "Api/entity/?entity=1",
  ENTITY_DETAIL: "Api/entity_detail",
  CUSTOMER_ENTIIY: "Api/entity/?entity=2",
  CUSTOMER_ENTIIY_FOR_CUSTOMER_ACCOUNT: "Api/customer_entity_for_customer",
  ADD_WAREHOUSE: "Api/add_warehouse",
  WAREHOUSE: "Api/warehouse",
  WAREHOUSE_DETAIL: "Api/warehouse_detail",
  CATEGORY_LIST: "Api/category",
  PRODUCT_LIST: "Api/products",
  GET_SKU: "Api/sku_search",
  ADD_CARTIN: "Api/add_cart_in",
  CART_LIST: "Api/get_cart",
  CART_DETAIL: "Api/cart_detail",
  ADD_CONFIRM_STOCK: "Api/save_confirm_stock",
  CONFIRM_STOCK_DETAIL: "Api/confirm_stock_detail",
  SAVE_CART_STOCK: "Api/save_cart_stock",
  GET_PENDING_TASK: "Api/get_pending_task",
  STOCK_DETAIL: "Api/stock_detail",
  UPDATE_CARTIN: "Api/update_cartin",
  UPDATE_CARTOUT: "Api/update_cartin",
  ADD_CARTOUT: "Api/add_cart_out",
  ADD_ADDRESS: "Api/add_major_address",
  ADDRESS_LIST: "Api/get_address",
  SHIPPING_REQUEST_DETAIL: "Api/shipping_request_detail",
  UPDATE_SHIPPING_STATUS: "Api/update_shipping_status",
  SHIPPING_STATUS_DETAIL: "Api/shipping_status",
  CHANGE_PASSWORD: "Api/change_password",
  GET_Breadcrumb_warehouse_client: "Api/breadcrumb_for_warehouse_client",
  SAVE_CONFIRM_PRODUCT_RECEIVED: "Api/save_confirm_product_received",
  CONFIRM_INSTRUCTION: "Api/confirm_instruction",
  AVAILABLE_SKU_SERIAL: "Api/available_sku_serial",
  PRODUCT_INVENTORY: "Api/product_inventory",
  CONFIRM_STOCK_FOR_MAIL: "Api/fetch_email_for_confirmation",
  // shipping request //
  ADD_SHIPPING_REQUEST: "Api/add_shipping_request",
  UPDATE_SHIPPING_REQUEST: "Api/update_shipping_request",
  SHIPPING_REQUEST_LIST: "Api/shipping_request",
  SHIPPING_REQUEST_DETAIL: "Api/shipping_request_detail",
  MAJOR_ADDRESS_LIST: "Api/get_address",
  ADD_MAJOR_ADDRESS: "Api/add_major_address",
  ITEM_LIST: "Api/get_item_list",
  ADD_ITEMS: "Api/add_items",
  ITEM_DETAIL: "Api/item_detail",
  WAREHOUSE_FOR_CUSTOMER_USER: "Api/warehouse_list_for_customer_user",
  GENERATE_SHIPPING_INSTRUCTION_REPORT: "Export/process_shipping_instruction_report",
  SEND_CONFIRMATION_EMAIL: "Api/send_confirmation_email",
  SAVE_SHIPMENT_PROCESSING: "Api/save_shipment_processing",
  SHIPMENT_PROCESSING: "Api/shipment_processing",
  SHIPMENT_PROCESSING_DETAIL: "Api/shipment_processing_detail",
  SAVE_LINE_ITEM: "Api/save_line_item",
  // //shipping request// //
  CURRENCY: "Api/currency",
  FETCH_CONFIRM_REC_PRDCT: "Api/fetch_confirm_rec_prdct",
  SEARCH_SKU: "Api/search_sku_serial",
  MARK_AS_DONE: "Api/mark_as_done",
  MOVE_TASK: "Api/move_task"
};
