import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import {
    Row,
    Col,
    Button,
    Form,
    Input,
    Select,
    Divider,
    notification,
} from "antd";
import { token } from "../../Helper/Session";
import { withRouter } from "react-router-dom";

class AddWarehouse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            warehouse: [],
            id: props.id,
        };
    }
    formRef = React.createRef();
    async componentDidMount() {
        await fetch(`${BASE_URL}${API.WAREHOUSE_DETAIL}`, {
            method: "POST",
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({ id: this.state.id }),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        warehouse: json.data,
                    });
                    this.formRef.current.setFieldsValue({
                        location_name: this.state.warehouse.location_name,
                        address: this.state.warehouse.address,
                        city: this.state.warehouse.city,
                        state: this.state.warehouse.state,
                        zip: this.state.warehouse.zip,
                        country: this.state.warehouse.country,
                        email: this.state.warehouse.email,
                        mobile: this.state.warehouse.mobile,
                        contact_name: this.state.warehouse.contact_name
                    });
                }
                this.setState({
                    isLoading: false,
                });
            });
    }

    render() {
        const onFinish = (values) => {
            // console.log(values);
            const sub_data = {
                location_name: values.location_name,
                address: values.address,
                city: values.city,
                state: values.state,
                zip: values.zip,
                country: values.country,
                contact_name: values.contact_name,
                mobile: values.mobile,
                email: values.email
            };
            if (this.state.id) {
                sub_data.id = this.state.id;
            }
            fetch(`${BASE_URL}${API.ADD_WAREHOUSE}`, {
                method: "POST",
                headers: {
                    Accept: "application/form-data",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: JSON.stringify(sub_data),
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.status === true) {
                        notification.success({ message: json.success_msg });
                        this.props.history.push('/warehouse');
                    } else {
                        notification.error({ message: json.error_msg });
                    }
                });
        };
        return (
            <Headers
                bodyContain={
                    <>
                        <div className="site-layout-background form-design">
                            <h4>Add warehouse</h4>
                            <Divider />
                            <Form
                                name="register"
                                layout="vertical"
                                onFinish={onFinish}
                                scrollToFirstError
                                ref={this.formRef}
                            >
                                <Row>
                                    <Col md={12}>
                                        <Form.Item
                                            name="location_name"
                                            label="Location Name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter warehouse location name!",
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row>
                                    <Col md={12}>
                                        <Form.Item
                                            name="contact_name"
                                            label="Contact name"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Please enter contact name!",
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Item
                                            name="mobile"
                                            label="Mobile"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Please enter mobile!",
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Item
                                            name="email"
                                            label="Email"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Please enter email!",
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Item
                                            name="address"
                                            label="Address"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter warehouse address!",
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Item
                                            name="city"
                                            label="City"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter city!",
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Item
                                            name="state"
                                            label="State/Province"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Please enter state/province!",
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Item
                                            name="zip"
                                            label="Zip/Postal code"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter Zip/Postal code!",
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Item
                                            name="country"
                                            label="Country"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter country!",
                                                    whitespace: true,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" size="meduim">
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </>
                }
            />
        );
    }
}
export default withRouter(AddWarehouse);
