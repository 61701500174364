import React from "react";

class NotFound extends React.Component {
    render() {

        return (
            <div style={{width:"300px", margin:"auto", textAlign:"center", marginTop:"100px"}}>
                <h1 style={{fontSize:"3em"}}>404</h1>
                <h2>Page Not Found</h2>
            </div>
        );
    }
}

export default NotFound;
