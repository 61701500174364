import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Divider,
  notification,
} from "antd";
import { token } from "../../Helper/Session";

class AddCustomerAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }
  render() {
    const onFinish = (values) => {
      const sub_data = {
        customer_account_name: values.customer_account_name,
        nickname: values.nickname,
      };
      fetch(`${BASE_URL}${API.ADD_CUSTOMER_ACCOUNT}`, {
        method: "POST",
        headers: {
          Accept: "application/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(sub_data),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.status === true) {
            notification.success({ message: json.success_msg });
            this.props.history.push("/customer-account");
          } else {
            notification.error({ message: json.error_msg });
          }
        });
    };
    return (
      <Headers
        bodyContain={
          <>
            <div className="site-layout-background form-design">
              <h4>Add Customer Account</h4>
              <Divider />
              <Form
                name="register"
                layout="vertical"
                onFinish={onFinish}
                scrollToFirstError
              >
                <Row>
                  <Col md={12}>
                    <Form.Item
                      name="customer_account_name"
                      label="Customer Account Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input customer account name!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item
                      name="nickname"
                      label="Customer Account Short Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input Customer Account Short Name!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" size="large">
                        Register
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </>
        }
      />
    );
  }
}
export default AddCustomerAccount;
