const session = () => {
  var getSession = localStorage.getItem("authenticated");
  if (getSession) {
    var parseSession = JSON.parse(getSession);
    // console.log(parseSession);
    return parseSession;
  } else {
    return false;
  }
};

export const token = (session() !== "null") ? session().token : false;

export const sessionData = session();
