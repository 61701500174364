import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import {
    Row,
    Col,
    Table,
    Button,
    Breadcrumb,
    notification,
    Modal,
    Select,
} from "antd";
import { withRouter } from "react-router-dom";
import { token } from "../../Helper/Session";
import { DeliveredProcedureOutlined } from "@ant-design/icons";
import Form from "../../Helper/Form";

class WareHouseClients extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            warehouse_id: props.id,
            customer_account: [],
            warehouse_detail: [],
            isLoading: false
        };
    }

    // goToAddUser = () => {
    //     this.props.history.push("/add-customer-account");
    // };

    formRef = React.createRef();
    componentDidMount() {
        this.setState({
            isLoading: true
        })
        fetch(`${BASE_URL}${API.CUSTOMER_ACCOUNT_LIST}/?warehouse_id=${this.state.warehouse_id}`, {
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        users: json.data,
                    });
                } else {
                    notification.error({ message: json.error_msg });
                }
                this.setState({
                    isLoading: false
                })
            });

        fetch(`${BASE_URL}${API.WAREHOUSE_DETAIL}`, {
            method: 'post',
            headers: {
                Accept: "application/form-data",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({ id: this.state.warehouse_id })
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.status === true) {
                    this.setState({
                        warehouse_detail: json.data,
                    });
                } else {
                    notification.error({ message: json.error_msg });
                    // this.props.history.push("/");
                }
            });
    }


    render() {
        const { users, warehouse_detail, isLoading } = this.state;
        // console.log(user);
        const { Option } = Select;
        var data = [];
        const columns = [
            {
                title: "SNo",
                dataIndex: "key",
            },
            {
                title: "Customer Account Name",
                dataIndex: "name",
            },
            {
                title: "Short Name",
                dataIndex: "nickname",
            },
            {
                title: "Add products",
                dataIndex: "action",
            },
        ];
        users.map((usr, i) =>
            data.push({
                key: i + 1,
                name: usr["customer_account_name"],
                nickname: usr["nickname"],
                action: (
                    <>
                        <Button
                            type="secondary"
                            title="Add customer products"
                            size="medium"
                            onClick={() => this.props.history.push("/warehouse/clients/products/" + warehouse_detail.id + "/" + usr.id)}
                        >
                            <DeliveredProcedureOutlined />
                        </Button>
                    </>
                ),
            })
        );

        return (
            <Headers
                bodyContain={
                    <>
                        <Row>
                            <Col md={19}>
                                <Breadcrumb>
                                    <Breadcrumb.Item>WareHouse</Breadcrumb.Item>
                                    <Breadcrumb.Item>{warehouse_detail.location_name}</Breadcrumb.Item>
                                    <Breadcrumb.Item>Customer Accounts</Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                            {/* <Col md={5}>
                                <Button
                                    onClick={this.goToAddUser}
                                    type="primary"
                                    style={{ marginBottom: "10px" }}
                                    shape="round"
                                >
                                    Add Customer Account
                                </Button>
                            </Col> */}
                        </Row>
                        <div className="site-layout-background">
                            <Row>
                                <Col md={24}>
                                    <Table
                                        columns={columns}
                                        dataSource={data}
                                        size="small"
                                        bordered
                                        loading={isLoading}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </>
                }
            />
        );
    }
}
export default withRouter(WareHouseClients);
