import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import { Row, Col, Table, Button, Switch, notification, Modal, Form, Select, Tag } from "antd";
import { withRouter } from "react-router-dom";
import { token } from "../../Helper/Session";
import { FolderAddOutlined } from "@ant-design/icons";

class WAREHOUSE_OPERATOR extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      user_role: this.props.user_role,
      warehouse: [],
      openAssignWareHouseModal: false,
      openAssignMemberModal: false,
      selectedUserId: "",
      location: [],
      isLoading: true,
      lead_id: 0,
      member_id: 0
    };
  }

  goToAddUser = () => {
    this.props.history.push("/add-user");
  };

  handleStatus = (user_id, v) => {
    if (v === true) {
      fetch(`${BASE_URL}${API.UPDATE_USER_STATUS}`, {
        method: "POST",
        headers: {
          Accept: "application/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ status: 1, user_id: user_id }),
      });
    } else if (v === false) {
      fetch(`${BASE_URL}${API.UPDATE_USER_STATUS}`, {
        method: "POST",
        headers: {
          Accept: "application/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ status: 0, user_id: user_id }),
      });
    }
  };

  handleAssignWareHouse = (value) => {
    var assign_data = {
      warehouse_id: value.warehouse_id,
      user_id: this.state.selectedUserId,
    };
    fetch(`${BASE_URL}${API.ASSIGN_WAREHOUSE}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(assign_data),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          notification.success({ message: json.success_msg, placement: "top" });
          this.setState({ openAssignWareHouseModal: false });
          this.formRef.current.setFieldsValue({
            warehouse_id: [],
          });
        } else {
          notification.error({ message: json.error_msg, placement: "top" });
        }
      });
  };

  handleAssignMember = (value) => {
    var assign_data = {
      member_id: value.member_id,
      lead_id: this.state.lead_id,
    };
    fetch(`${BASE_URL}${API.ASSIGN_MEMBER}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(assign_data),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          notification.success({ message: json.success_msg, placement: "top" });
          this.setState({ openAssignMemberModal: false });
          // this.formRef.current.setFieldsValue({
          //   warehouse_id: [],
          // });
        } else {
          notification.error({ message: json.error_msg, placement: "top" });
        }
      });
  };

  loadAssignWareHouse = (user_id) => {
    fetch(`${BASE_URL}${API.GET_ASSIGN_WAREHOUSE}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ user_id: user_id }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ openAssignUserModal: true });
        if (json.status == true) {
          this.formRef.current.setFieldsValue({
            warehouse_id: json.data,
          });
        } else {
          this.formRef.current.setFieldsValue({
            warehouse_id: []
          });
        }
      });
  };

  loadAssignMember = (user_id) => {
    fetch(`${BASE_URL}${API.GET_ASSIGN_MEMBER}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ lead_id: user_id }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ openAssignMemberModal: true });
        if (json.status == true) {
          this.formRefMember.current.setFieldsValue({
            member_id: json.data,
          });
        } else {
          this.formRefMember.current.setFieldsValue({
            member_id: []
          });
        }
      });
  };

  formRef = React.createRef();
  formRefMember = React.createRef();
  componentDidMount() {
    var user_role = this.state.user_role;
    if (typeof user_role === "undefined" || user_role === null) {
      user_role = 0;
    }
    fetch(`${BASE_URL}${API.USERLISTING}?user_role=${user_role}`, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            users: json.data,
          });
          notification.success({ message: json.success_msg, duration: 2 });
        } else {
          notification.error({ message: json.error_msg, duration: 2 });
        }
        this.setState({ isLoading: false })
      });

    fetch(`${BASE_URL}${API.WAREHOUSE}`, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            warehouse: json.data,
          });
        }
      });
  }

  render() {
    const { users, warehouse, openAssignWareHouseModal, openAssignMemberModal, isLoading, lead_id, member_id } = this.state;
    const { Option } = Select;
    // console.log(user);
    var data = [];
    const columns = [
      {
        title: "SNo",
        dataIndex: "key",
      },
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Contact",
        dataIndex: "mobile",
      },
      {
        title: "Status",
        dataIndex: "status",
      },
      {
        title: "Warehouse",
        dataIndex: "location",
        render: (_, { location }) => (
          <>
            {location.map((location) => {
              return (
                <Tag color="geekblue" key={location}>
                  {location.toUpperCase()}
                </Tag>
              );
            })}
          </>
        ),
      },
      {
        title: "Action",
        dataIndex: "action",
      },
    ];
    users.map((usr, i) =>
      data.push({
        key: i + 1,
        name: <>{usr["first_name"] + " " + usr["last_name"]}<br />

          {(usr.is_manager == 1) ? <Tag color="red">{"Team Lead"}</Tag> : ""}

        </>,
        email: usr["email"],
        mobile: usr["mobile"] !== "" ? usr["mobile"] : "",
        status: (
          <Switch
            size="default"
            checkedChildren="Active"
            unCheckedChildren="InActive"
            defaultChecked={usr["status"] == 1 ? true : false}
            onChange={this.handleStatus.bind(this, usr["id"])}
            style={{
              width: "80px",
            }}
          />
        ),
        location: (usr['warehouse']) ? usr['warehouse'].split(",") : [],
        action: (
          <div style={{ "width": "150px" }}>
            <Button
              type="primary"
              size="small"
              style={{ background: "#ffc107", border: "none" }}
              onClick={() => this.props.history.push("users/edit/" + usr["id"])}
            >
              Edit
            </Button>
            <Button
              type="primary"
              size="small"
              title="Assign WareHouse"
              icon={<FolderAddOutlined />}
              onClick={() => {
                this.setState({
                  openAssignWareHouseModal: true,
                  selectedUserId: usr["id"]
                });
                this.loadAssignWareHouse(usr['id'])
              }
              }
            >

            </Button>
            {usr.is_manager == 1 && (<Button
              type="danger"
              size="small"
              style={{ border: "none" }}
              onClick={() => {
                this.setState({
                  openAssignMemberModal: true,
                  lead_id: usr.id
                });
                this.loadAssignMember(usr.id)
              }}
            >
              Assign team member
            </Button>)
            }
          </div >
        ),
      })
    );

    return (
      <Headers
        bodyContain={
          <>
            <Row>
              <Col offset={21} md={2}>
                <Button
                  onClick={this.goToAddUser.bind(this)}
                  type="primary"
                  style={{ marginBottom: "10px" }}
                  shape="round"
                >
                  Add User
                </Button>
              </Col>
            </Row>
            <div className="site-layout-background">
              <Row>
                <Col md={24}>
                  <Table
                    columns={columns}
                    dataSource={data}
                    size="middle"
                    bordered
                    loading={isLoading}
                  />
                </Col>
              </Row>
              <Modal
                title="Assign WareHouse"
                style={{
                  top: 20,
                }}
                visible={openAssignWareHouseModal}
                width={600}
                onCancel={() => {
                  this.setState({
                    openAssignWareHouseModal: false,
                  });
                }}
                footer={[
                  <Button
                    key="cancel"
                    type="default"
                    loading=""
                    onClick={() => this.setState({ openAssignWareHouseModal: false })}
                  >
                    Cancel
                  </Button>,
                ]}
              >
                <Form
                  name="assign_customer_account"
                  layout="vertical"
                  onFinish={this.handleAssignWareHouse}
                  scrollToFirstError
                  ref={this.formRef}
                >
                  <Row>
                    <Col md={24}>
                      <Form.Item
                        name="warehouse_id"
                        label="Select Warehouse"
                        rules={[
                          {
                            required: true,
                            message: "Please select warehouse location!",
                          },
                        ]}
                      >
                        <Select
                          allowClear={true}
                          mode="multiple"
                          size="large"
                          // value={selectedValues}
                          autoClearSearchValue={true}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0 ||
                            option.props.value
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {warehouse.map((wh) => {
                            return (
                              <Option key={wh.id} value={wh.id}>
                                {wh.location_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col md={24}>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="medium"
                          loading={false}
                        >
                          Save Warehouse
                        </Button>
                      </Form.Item>
                    </Col>

                  </Row>
                </Form>
              </Modal>
              <Modal
                title="Assign Member"
                style={{
                  top: 20,
                }}
                visible={openAssignMemberModal}
                width={600}
                onCancel={() => {
                  this.setState({
                    openAssignMemberModal: false,
                  });
                }}
                footer={[
                  <Button
                    key="cancel"
                    type="default"
                    loading=""
                    onClick={() => this.setState({ openAssignMemberModal: false })}
                  >
                    Cancel
                  </Button>,
                ]}
              >
                <Form
                  name="assign_customer_account"
                  layout="vertical"
                  onFinish={this.handleAssignMember}
                  scrollToFirstError
                  ref={this.formRefMember}
                >
                  <Row>
                    <Col md={24}>
                      <Form.Item
                        name="member_id"
                        label="Select Member"
                        rules={[
                          {
                            required: true,
                            message: "Please select member!",
                          },
                        ]}
                      >
                        <Select
                          allowClear={true}
                          mode="multiple"
                          size="large"
                          // value={selectedValues}
                          autoClearSearchValue={true}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0 ||
                            option.props.value
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {users.map((wh) => {
                            return ((wh.is_manager != 1) && (
                              <Option key={wh.id} value={wh.id}>
                                {wh.first_name + " " + wh.last_name}
                              </Option>)
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col md={24}>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="medium"
                          loading={false}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>

                  </Row>
                </Form>
              </Modal>
            </div>
          </>
        }
      />
    );
  }
}
export default withRouter(WAREHOUSE_OPERATOR);
